import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMetricType } from '../../enums/enums';

@Injectable()
export class SdMetricSelectorService {
  private metricUpdated: BehaviorSubject<IMetricType> = new BehaviorSubject(null);
  metricUpdated$ = this.metricUpdated.asObservable();

  private metricOptionsUpdated: BehaviorSubject<IMetricType[]> = new BehaviorSubject(null);
  metricsOptionsUpdated$ = this.metricOptionsUpdated.asObservable();

  constructor() { }

  updateAvailableMetrics(metrics: IMetricType[]) {
    this.metricOptionsUpdated.next(metrics);
  }

  updateSelectedMetric(metric: IMetricType) {
    this.metricUpdated.next(metric);
  }
}
