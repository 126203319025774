import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as Models from '../../models/models-index';

import { GenericConfirmModalComponent, IConfirmModalParams } from '../../modals/genericConfirmModal.component';
import { UserDetailsEditComponent } from '../../../management/user/userDetails/userDetailsEdit.component';
import { UserSettingsComponent } from '../../../management/user/userDetails/userSettings.component';

export interface SDModalOptions {
    resolve?: any;
    content?: any;
    modalOptions?: NgbModalOptions;
}

export interface IModalService {
    openConfirmModal(modalParams: IConfirmModalParams): Promise<boolean>;
    openCreateEditUserModal(user?: Models.User): Promise<any>;
    openEditMySettings(user: Models.User): Promise<any>;

    openModal(modalOptions: SDModalOptions): Promise<any>;
}

@Injectable()
export class ModalService implements IModalService {

    constructor(private modalService: NgbModal) { }

    openConfirmModal(modalParams: IConfirmModalParams): Promise<boolean> {
        var cfg: SDModalOptions = {
            content: GenericConfirmModalComponent,
            modalOptions: {
                size: 'sm',
                keyboard: false
            },
            resolve: {
                modalParams: modalParams
            }
        };
        return this.openModal(cfg);
    }

    openCreateEditUserModal(user?: Models.User): Promise<any> {
        var cfg: SDModalOptions = {
            content: UserDetailsEditComponent,
            modalOptions: {
                size: 'sm',
                keyboard: false
            },
            resolve: {
                user: user
            }
        };
        return this.openModal(cfg);
    }

    openEditMySettings(user: Models.User): Promise<any> {
        var cfg: SDModalOptions = {
            content: UserSettingsComponent,
            modalOptions: {
                size: 'sm',
                keyboard: false
            },
            resolve: {
                user: user
            }
        };
        return this.openModal(cfg);
    }

    openModal(config: SDModalOptions): Promise<any> {
        let modalOptions = Object.assign({}, {
            size: 'lg',
            keyboard: false,
            backdrop: 'static'
        }, config.modalOptions);

        let modal = this.modalService.open(config.content, modalOptions);

        if (config.resolve) {
          config.resolve.forEach((value, key) => modal.componentInstance[key] = value);
        }

        return modal.result;
    }

}
