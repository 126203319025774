import { IRole, IRoleConfig } from '../../_shared/models/models-index';

export class RoleConfig implements IRoleConfig {

  systemAdministratorRoles: IRole[] = [
    { roleId: 1, name: 'System Administrator'}
  ];
  corporateRoles: IRole[] = [
    { roleId: 2, name: 'Corporate' }
  ];
  org4Roles: IRole[] = [];
  org5Roles: IRole[] = [];
  org3Roles: IRole[] = [];
  org2Roles: IRole[] = [
    { roleId: 4, name: 'Area Manager' }
  ];
  org1Roles: IRole[] = [
    { roleId: 5, name: 'District Manager' }
  ];
  dealerRoles: IRole[] = [
    { roleId: 6, name: 'Dealer' }
  ];
  dealerGroupRoles: IRole[] = [];

}
