import { IDefaultTableColumnConfig, BillboardConfigMap, Segment } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { IProcessPerformanceConfig } from './process-performance.config.interfaces';

export class ProcessPerformanceConfig implements IProcessPerformanceConfig {
  funnelSegments: Segment[];

  summaryTableTitle: 'Dealer Summary';

  public metricDisplayModes = [
      { name: 'MOM', value: 'MOM' },
      { name: 'YOY', value: 'YOY' },
      { name: 'Trend', value: 'Trend' }
  ];

  // TODO this isn't really be used and should, all the mapping is explicitly done in the process-report-helper.service
  public billboards: BillboardConfigMap[] = [
    {
      title: 'Lead Volume',
      iconClass: 'fa-users fa-primary',
      metricPropertyName: 'leads',
      metricTrendArrowPropertyName: 'leadsMOM',
      metricMOMPropertyName: 'leadsMOM',
      metricYOYPropertyName: 'leadsYOY',
      metricPreviousMonthPropertyName: 'leadsPreviousMonth',
      metricPreviousMTDPropertyName: 'leadsPreviousMTD',
      metricPreviousYearPropertyName: 'leadsPreviousYear',
      useFooterRowOne: true,
      useFooterRowTwo: true,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'ProcessPerformanceBillboardHelp',
      helpTextTitle: 'Process Performance'
    }
  ];

  public dealerTableColumns: IDefaultTableColumnConfig[] = [
      {
          show: true,
          header: '',
          columnDef: 'entity',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName,
          clickable: true
          // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
      },
      {
          show: false,
          print: true,
          header: 'Dealer Name',
          columnDef: 'displayName',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      // can use row to determine entity type if needed
      {
          show: true,
          header: 'Leads',
          columnDef: 'leads',
          isMom: false,
          isYoy: false,
           metricFormatKey: constants.formatKeys.localeString, clickable: false
      }, {
        show: true,
        header: 'Leads Prev. Month',
        columnDef: 'leadsPreviousMTD',
        isMom: true,
        isYoy: false,
         metricFormatKey: constants.formatKeys.localeString, clickable: false
      }, {
        show: true,
        header: 'Leads MOM',
        columnDef: 'leadsMOM',
        isMom: true,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Response Rate',
        columnDef: 'responseRate',
        isMom: false,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Response Rate Prev. Month',
        columnDef: 'responseRatePreviousMTD',
        isMom: true,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Response Rate MOM',
        columnDef: 'responseRateMOM',
        isMom: true,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Rate',
        columnDef: 'appointmentRate',
        isMom: false,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Rate Prev. Month',
        columnDef: 'appointmentRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Rate MOM',
        columnDef: 'appointmentRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appt. Shown/Test Drive Rate',
        columnDef: 'appointmentShowedOrTestDriveRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appt. Shown/Test Drive Rate Prev. Month',
        columnDef: 'appointmentShowedOrTestDriveRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appt. Shown/Test Drive Rate MOM',
        columnDef: 'appointmentShowedOrTestDriveRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Offer to Purchase Rate',
        columnDef: 'offersToPurchaseRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Offer to Purchase Rate Prev. Month',
        columnDef: 'offerToPurchaseRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Offer to Purchase Rate MOM',
        columnDef: 'offerToPurchaseRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'In-Store Conversion',
        columnDef: 'inStoreConversionRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'In-Store Conversion Prev. Month',
        columnDef: 'inStoreConversionRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'In-Store Conversion MOM',
        columnDef: 'inStoreConversionRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Close Rate',
        columnDef: 'closeRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Close Rate Prev. Month',
        columnDef: 'closeRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Close Rate MOM',
        columnDef: 'closeRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }
  ];
}
