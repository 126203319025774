import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../appInsights/appInsights.service';

@Injectable()
export class InventoryOverviewService {

    constructor(
      private http: HttpClient,
      private appInsights: AppInsightsService) { }

    getInventoryOverviewBillboardMetrics(reportRequest: any): Observable<Models.InventoryOverviewBillboardResponseModel> {
        return this.http.post<Models.InventoryOverviewBillboardResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getInventoryOverviewBillboardGraphMetrics(reportRequest: any): Observable<Models.InventoryOverviewBillboardGraphMetricResponseModel> {
        return this.http.post<Models.InventoryOverviewBillboardGraphMetricResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getInventoryOverviewClassSummaryMetrics(reportRequest: any): Observable<Models.InventoryOverviewClassSummaryMetricResponseModel> {
        return this.http.post<Models.InventoryOverviewClassSummaryMetricResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getInventoryOverviewClassModelDetailMetrics(reportRequest: any): Observable<Models.InventoryOverviewClassModelDetailMetricResponseModel> {
        return this.http.post<Models.InventoryOverviewClassModelDetailMetricResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getInventoryOverviewSummaryBenchmarkMetrics(reportRequest: any): Observable<Models.InventoryOverviewSummaryMetricResponseModel> {
        return this.http.post<Models.InventoryOverviewSummaryMetricResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getInventoryOverviewClassVehicleSummaryMetrics(reportRequest: any): Observable<Models.InventoryOverviewClassVehicleSummaryMetricResponseModel> {
        return this.http.post<Models.InventoryOverviewClassVehicleSummaryMetricResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getInventoryOverviewGraphMetrics(reportRequest: any): Observable<Models.InventoryOverviewGraphMetricResponseModel> {
        return this.http.post<Models.InventoryOverviewGraphMetricResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    private handleError(err: any) {
      let errorMessage: string;
      if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          errorMessage = `An error occurred: ${err.error.message}`;
      } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
      }
      this.appInsights.trackException(err);
      console.error(err);
      return throwError(() => errorMessage);
  }
}
