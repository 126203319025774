import {
  IChatProviderScorecardConfig,
  IChatProviderScorecardBillboardConfig
} from '../../../../_shared/models/models-index';


export class ChatProviderScorecardConfig implements IChatProviderScorecardConfig {
  public reportTitle: string;
  public scorecardMetrics: IChatProviderScorecardBillboardConfig[];
}
