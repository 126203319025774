import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'minutesToHours' })
export class MinutesToHoursPipe implements PipeTransform {
    transform(timeInMinutes: number): string {
        if (isNaN(timeInMinutes)) {
            return 'N/A';
        }

        var hours: number = 0;
        var minutes: number = 0;

        hours = Math.floor(timeInMinutes / 60);
        minutes = Math.round(timeInMinutes - (hours * 60));

        return (hours > 0 ? hours.toString() + 'h ' : '') + minutes.toString() + 'm';
    }
}
