<div class="mat-elevation-z1 horizontal-bar-by-dimension-element">
    <sd-card-header [title]="title" [iconClass]="titleIconClasses" [helpTextKey]="" [helpTextTitle]="" [locale]="">
    </sd-card-header>
    <div class="card-block">

        <div class="row labels">
            <span class="col-md-9 col-xl-10"><b>{{dimensionName}}:</b></span>
            <span *ngFor="let metric of columnMetrics"
                class="col-md-3 col-xl-2"><b>{{getMetricLabel(metric)}}:</b></span>
        </div>
        <div class="row" *ngFor="let cc of dataRows">
            <div class="col-12">{{ cc.displayName }}</div>
            <div class="col-md-9 col-xl-10">
                <div class="dimension-bar-table">
                    <table [ngStyle]="{ 'width': cc.barWidth }" [matTooltip]="cc.tooltipText"
                        [matTooltipPosition]="'above'">
                        <tr>
                            <td [ngStyle]="cc.barStyleOne"></td>
                            <td [ngStyle]="cc.barStyleTwo"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-md-3 col-xl-2" *ngFor="let metric of cc.columnMetricDisplayValues">
                {{ metric }}
            </div>
        </div>
    </div>
</div>
