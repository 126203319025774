import { ITranslationConfig } from "../translation.config";

export class DigitalRetailingTranslationConfig implements ITranslationConfig {
    public reportTitles = {
      'en': {
      },
      'fr-CA': {
        digitalretailingoverview: 'Sommaire de la Vente au détail Numerique',
      }
    };

    // column and chart metric labels
    public metrics = {
      'en': {
      },
      'fr-CA': {
        allCreditApps: 'Toute les applications de Credit',
        allCreditAppsPreviousMonth: 'Mois préc. Toute les applications de Credit',
        allCreditAppsMOM: 'Toute les applications de Credit MSM',
        allCreditAppsPreviousYear: 'Année préc. Toute les applications de Credit',
        allCreditAppsYOY: 'Toute les applications de Credit ASA',
        allCreditAppRate: 'Pourcentage d\'applications de crédit',
        allCreditAppRatePreviousMonth: 'Mois préc. Pourcentage d\'applications de crédit',
        allCreditAppRateMOM: 'Pourcentage d\'applications de crédit MSM',
        allCreditAppRatePreviousYear: 'Année préc. Pourcentage d\'applications de crédit',
        allCreditAppRateYOY: 'Pourcentage d\'applications de crédit ASA',
        allCreditAppsRate: 'Pourcentage d\'applications de crédit',
        allCreditAppsRatePreviousMonth: 'Mois préc. Pourcentage d\'applications de crédit',
        allCreditAppsRateMOM: 'Pourcentage d\'applications de crédit MSM',
        allCreditAppsRatePreviousYear: 'Année préc. Pourcentage d\'applications de crédit',
        allCreditAppsRateYOY: 'Pourcentage d\'applications de crédit ASA',
        creditApps: 'Applications de Credit',
        creditAppsPreviousMonth: 'Mois préc. Applications de Credit',
        creditAppsMOM: 'Applications de Credit MSM',
        creditAppsPreviousYear: 'Année préc. Applications de Credit',
        creditAppsYOY: 'Applications de Credit ASA',
        creditAppRate: 'Taux d\'application de crédit',
        creditAppRatePreviousMonth: 'Mois préc. Taux d\'application de crédit',
        creditAppRateMOM: 'Taux d\'application de crédit MSM',
        creditAppRatePreviousYear: 'Année préc. Taux d\'application de crédit',
        creditAppRateYOY: 'Taux d\'application de crédit ASA',
        eCommerceAppointments: 'Rendez-vous',
        eCommerceAppointmentsPreviousMonth: 'Mois préc. Rendez-vous',
        eCommerceAppointmentsMOM: 'Rendez-vous MSM',
        eCommerceAppointmentsPreviousYear: 'Année préc. Rendez-vous',
        eCommerceAppointmentsYOY: 'Rendez-vous ASA',
        eCommerceAppointmentRate: 'Taux de rendez-vous',
        eCommerceAppointmentRatePreviousMonth: 'Mois préc. Taux de rendez-vous',
        eCommerceAppointmentRateMOM: 'Taux de rendez-vous MSM',
        eCommerceAppointmentRatePreviousYear: 'Année préc. Taux de rendez-vous',
        eCommerceAppointmentRateYOY: 'Taux de rendez-vous ASA',
        provider: 'Fournisseur',
        fICompletes: 'Financement Finalises',
        fICompletesPreviousMonth: 'Mois préc. Financement Finalises',
        fICompletesMOM: 'Financement Finalises MSM',
        fICompletesPreviousYear: 'Année préc. Financement Finalises',
        fICompletesYOY: 'Financement Finalises ASA',
        fICompleteRate: 'Taux de Financement Finalises',
        fICompleteRatePreviousMonth: 'Mois préc. Taux de Financement Finalises',
        fICompleteRateMOM: 'Taux de Financement Finalises MSM',
        fICompleteRatePreviousYear: 'Année préc. Taux de Financement Finalises',
        fICompleteRateYOY: 'Taux de Financement Finalises ASA',
        formConversionRate: 'Taux de conversion des formulaires',
        formConversionRatePreviousMonth: 'Mois préc. Taux de conversion des formulaires',
        formConversionRateMOM: 'Taux de conversion des formulaires MSM',
        formConversionRatePreviousYear: 'Année préc. Taux de conversion des formulaires',
        formConversionRateYOY: 'Taux de conversion des formulaires ASA',
        formSubmissions: 'Capture de Prospects',
        formSubmissionsPreviousMonth: 'Mois préc. Capture de Prospects',
        formSubmissionsMOM: 'Capture de Prospects MSM',
        formSubmissionsPreviousYear: 'Année préc. Capture de Prospects',
        formSubmissionsYOY: 'Capture de Prospects ASA',
        formSubmissionRate: 'Taux de Capture de prospects',
        formSubmissionRatePreviousMonth: 'Mois préc. Taux de Capture de prospects',
        formSubmissionRateMOM: 'Taux de Capture de prospects MSM',
        formSubmissionRatePreviousYear: 'Année préc. Taux de Capture de prospects',
        formSubmissionRateYOY: 'Taux de Capture de prospects ASA',
        none: 'None',
        paymentCalculators: 'Calculateur de paiement ',
        paymentCalculatorsPreviousMonth: 'Mois préc. Calculateur de paiement ',
        paymentCalculatorsMOM: 'Calculateur de paiement  MSM',
        paymentCalculatorsPreviousYear: 'Année préc. Calculateur de paiement ',
        paymentCalculatorsYOY: 'Calculateur de paiement  ASA',
        paymentCalcRate: 'Taux du calculateur de Paiement',
        paymentCalcRatePreviousMonth: 'Mois préc. Taux du calculateur de Paiement',
        paymentCalcRateMOM: 'Taux du calculateur de Paiement MSM',
        paymentCalcRatePreviousYear: 'Année préc. Taux du calculateur de Paiement',
        paymentCalcRateYOY: 'Taux du calculateur de Paiement ASA',
        paymentCalculatorRate: 'Taux du calculateur de Paiement',
        paymentCalculatorRatePreviousMonth: 'Mois préc. Taux du calculateur de Paiement',
        paymentCalculatorRateMOM: 'Taux du calculateur de Paiement MSM',
        paymentCalculatorRatePreviousYear: 'Année préc. Taux du calculateur de Paiement',
        paymentCalculatorRateYOY: 'Taux du calculateur de Paiement ASA',
        preQualified: 'Pre Qualification',
        preQualifiedPreviousMonth: 'Mois préc. Pre Qualification',
        preQualifiedMOM: 'Pre Qualification MSM',
        preQualifiedPreviousYear: 'Année préc. Pre Qualification',
        preQualifiedYOY: 'Pre Qualification ASA',
        preQualifiedRate: 'Taux de Pre qualification',
        preQualifiedRatePreviousMonth: 'Mois préc. Taux de Pre qualification',
        preQualifiedRateMOM: 'Taux de Pre qualification MSM',
        preQualifiedRatePreviousYear: 'Année préc. Taux de Pre qualification',
        preQualifiedRateYOY: 'Taux de Pre qualification ASA',
        sessionStarts: 'Sessions Commences',
        sessionStartsPreviousMonth: 'Mois préc. Sessions Commences',
        sessionStartsMOM: 'Sessions Commences MSM',
        sessionStartsPreviousYear: 'Année préc. Sessions Commences',
        sessionStartsYOY: 'Sessions Commences ASA',
        sessionStartRate: 'Taux de Demarrage de Session',
        sessionStartRatePreviousMonth: 'Mois préc. Taux de Demarrage de Session',
        sessionStartRateMOM: 'Taux de Demarrage de Session MSM',
        sessionStartRatePreviousYear: 'Année préc. Taux de Demarrage de Session',
        sessionStartRateYOY: 'Taux de Demarrage de Session ASA',
        totals: 'TOTAUX',
        tradeIns: 'Reprise',
        tradeInsPreviousMonth: 'Mois préc. Reprise',
        tradeInsMOM: 'Reprise MSM',
        tradeInsPreviousYear: 'Année préc. Reprise',
        tradeInsYOY: 'Reprise ASA',
        tradeInRate: 'Taux de Reprise',
        tradeInRatePreviousMonth: 'Mois préc. Taux de Reprise',
        tradeInRateMOM: 'Taux de Reprise MSM',
        tradeInRatePreviousYear: 'Année préc. Taux de Reprise',
        tradeInRateYOY: 'Taux de Reprise ASA',
        uniqueShoppers: 'Acheteurs Uniques',
        uniqueShoppersPreviousMonth: 'Mois préc. Acheteurs Uniques',
        uniqueShoppersMOM: 'Acheteurs Uniques MSM',
        uniqueShoppersPreviousYear: 'Année préc. Acheteurs Uniques',
        uniqueShoppersYOY: 'Acheteurs Uniques ASA',
        vehiclemake: 'Marque du véhicule',
        websiteVisits: 'Visites du site Web',
        websiteVisitsPreviousMonth: 'Mois préc. Visites du site Web',
        websiteVisitsMOM: 'Visites du site Web MSM',
        websiteVisitsPreviousYear: 'Année préc. Visites du site Web',
        websiteVisitsYOY: 'Visites du site Web ASA',
        websiteVisitors: 'Visiteurs du Site web',
        websiteVisitorsPreviousMonth: 'Mois préc. Visiteurs du Site web',
        websiteVisitorsMOM: 'Visiteurs du Site web MSM',
        websiteVisitorsPreviousYear: 'Année préc. Visiteurs du Site web',
        websiteVisitorsYOY: 'Visiteurs du Site web ASA',
        leadCaptures: 'Capture de prospects',
        leadCapturesPreviousMonth: 'Mois préc. Capture de prospects',
        leadCapturesMOM: 'Capture de prospects MSM',
        leadCapturesPreviousYear: 'Année préc. Capture de prospects',
        leadCapturesYOY: 'Capture de prospects ASA',
        leadCaptureRate: 'Taux de Capture de prospects',
        leadCaptureRatePreviousMonth: 'Mois préc. Taux de Capture de prospects',
        leadCaptureRateMOM: 'Taux de Capture de prospects MSM',
        leadCaptureRatePreviousYear: 'Année préc. Taux de Capture de prospects',
        leadCaptureRateYOY: 'Taux de Capture de prospects ASA',
        appointmentRate: 'Taux de rendez-vous',
        appointmentRatePreviousMonth: 'Mois préc. Taux de rendez-vous',
        appointmentRateMOM: 'Taux de rendez-vous MSM',
        appointmentRatePreviousYear: 'Année préc. Taux de rendez-vous',
        appointmentRateYOY: 'Taux de rendez-vous ASA',

      }
    };
    public labels = {
      'en': {
      },
      'fr-CA': {
        allcreditapprate: 'Pourcentage d\'applications de crédit',
        allproviders: 'Tous Les Fourniesseurs',
        appointmentrate: 'Pourcentage d\'applications de crédit',
        cash: 'Comptant',
        central: 'Centrale',
        cpo: 'CPO',
        dealername: 'Concessionnaire',
        dealtype: 'Type de Transaction',
        eastern: 'Est',
        territory: 'Territoire',
        finance: 'Financement',
        kpis: 'KPIs',
        leadcapturerate: 'Taux de Capture de prospects',
        new: 'Nouveau',
        other: 'Autre',
        paymentcalcrate: 'Taux du calculateur de Paiement',
        provider: 'Fournisseur',
        providerkpiexcel: 'KPI.s  Fournisseur  sur Excel',
        sales: 'Ventes',
        sessionstartrate: 'Taux de Demarrage de Session',
        totals: 'Totals',
        total: 'Total',
        tradeinrate: 'Taux de Reprise',
        uniqueshoppers: 'Acheteurs Uniques',
        used: 'Ventes D\'occasion',
        vehicletype: 'Type de Vehicule',
        western: 'Ouest',
      }
    };

    public chartDimensions = {
      'en': {
      },
      'fr-CA': {
        allproviders: 'Tous Les Fourniesseurs',
        other: 'Autres',
      }
    };


}
