import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { ElementComponent } from '../../../models/models-index';
import { PanelElementFormattingService } from '../panel-element-formatting.service';
import { PanelElementResolverService } from '../panel-element-resolver.service';

@Component({
  selector: 'ghost-panel',
  templateUrl: './ghost-panel.component.html',
  styleUrls: ['./ghost-panel.component.scss']
})
export class GhostPanelComponent implements OnInit, Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panelConfig: Models.Panel;
  @Input() dataSets: any[];
  @Input() rowPanelId: string;

  panelTitle;

  constructor(private resolverService: PanelElementResolverService, 
    private panelFormattingService: PanelElementFormattingService, 
    private renderer2: Renderer2) { }

  ngOnInit(): void {
    this.generateElements(this.panelConfig, this.dataSets);
  }

  generateElements(panelConfig, dataSets) {
    const config = panelConfig;// || this.getDefaultPanelConfig();
    // const datasets = dataSets;// || this.getDefaultDataSets();
    this.panelTitle = config?.title;
    this.dataSets = dataSets;

    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();

    config.elements.forEach(element => {
      const dataset = this.dataSets.find(ds => ds.name === element.settings.dataSet);      
      const componentFactory = this.resolverService.resolveElementComponent(element.type)
      const componentRef = viewContainerRef.createComponent<ElementComponent>(componentFactory);
      componentRef.instance.dataSet = dataset;
      componentRef.instance.settings = element.settings;
      this.renderer2.addClass(componentRef.location.nativeElement, 'ghost-panel-element');
      this.renderer2.setAttribute(componentRef.location.nativeElement, 'style', this.panelFormattingService.getPanelStyles(element.settings))
    })
  }
}
