import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'percentage' })
export class PercentagePipe implements PipeTransform {
    transform(val: any, minDigits?: number): string {
        if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
            return 'N/A';
        } else if (minDigits === 1) {
            return (Math.round(val * 10000) / 100).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
            }) + '%';
        }  else if (minDigits === 2) {
            return (Math.round(val * 10000) / 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) + '%';
        } else {
            return (Math.round(val * 10000) / 100).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 2
            }) + '%';
        }
    }
}
