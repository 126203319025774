import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';


@Injectable()
export class SalesOverviewService {

    constructor(private http: HttpClient) { }

    getSalesOverviewBillboardData(reportRequest: any, reportType: string): Observable<Models.SalesOverviewBillboardResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.SalesOverviewBillboardResponseModel) => {
                    return <Models.SalesOverviewBillboardResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    getSalesOverviewSourceTypeData(reportRequest: any, reportType: string): Observable<Models.SalesOverviewSourceTypeResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.SalesOverviewSourceTypeResponseModel) => {
                    return <Models.SalesOverviewSourceTypeResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    getSalesOverviewVelocityDailyData(reportRequest: any, reportType: string): Observable<Models.SalesOverviewDailyVelocityResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.SalesOverviewDailyVelocityResponseModel) => {
                    return <Models.SalesOverviewDailyVelocityResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    getSalesOverviewVelocityMonthlyData(reportRequest: any, reportType: string): Observable<Models.SalesOverviewMonthlyVelocityResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.SalesOverviewMonthlyVelocityResponseModel) => {
                    return <Models.SalesOverviewMonthlyVelocityResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    getSalesOverviewDealerPerformanceData(reportRequest: any, reportType: string): Observable<Models.SalesOverviewSummaryTableResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.SalesOverviewSummaryTableResponseModel) => {
                    return <Models.SalesOverviewSummaryTableResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse): Observable<any> {
        return of([]);
    }

}
