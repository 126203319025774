import {ActionReducer, Action} from '@ngrx/store';
import {LocalStorageService} from '../_shared/services/services-index';
import { AppState } from './app-state.model';

export function storageMetaReducer<S, A extends Action = Action>(saveKeys: string[],
                                                                  localStorageKey: string,
                                                                  storageService: LocalStorageService
                                                                 ) {
  let onInit = true; // after load/refresh…
  return function(reducer: ActionReducer<S, A>) {
    return function(state: S, action: A): S {
      // get the next state.
      const nextState = reducer(state, action);
      // init the application state.
      if (onInit) {
        onInit           = false;
        const savedState = storageService.getSavedState(localStorageKey);
        return {...nextState, ...savedState};
      }

      // save the next state to the application storage.
      const stateToSave = saveKeys.reduce((current: any, pick: string) => {
        current[pick] = nextState[pick];
        return current;
      }, {});

      storageService.setSavedState(stateToSave, localStorageKey);

      return nextState;
    };
  };
}
