import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function passwordMatch(password: AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const newPassword = password.value;
    const confirmPassword = control.value;
    if (newPassword !== confirmPassword)
      return { 'unmatchedpassword': true };
    else
      return null;
  };
}
