import { Injectable } from '@angular/core';
import localeEnglish from '@angular/common/locales/en';
import localeFrenchCa from '@angular/common/locales/fr-CA';
import { environment } from '../../../environments/environment';
import * as Models from '../models/models-index';
import { AppActions } from '../../_store/action-types';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, LocaleState } from '../../_store/app-state.model';
import { registerLocaleData } from '@angular/common';
import { AppSelectors } from '../../_store/selector-types';
import { filter, map, take, tap } from 'rxjs/operators';


@Injectable()
export class LocaleService {
    locale$: Observable<string>;
    localeState$: Observable<Models.ILocaleInfo>;
    defaultLocaleInfo: Models.ILocaleInfo = {
        locale: environment.defaultLocale,
        userDefaultLocale: null
    };

    constructor(
        private store$: Store<AppState>
    )   {
            this.locale$ = this.store$.select(AppSelectors.selectLocale).pipe(tap(localeState => localeState));
        }

    getInitialLocale(cachedLocale, userDefaultLocale) {

        const initialLocaleInfo: Models.ILocaleInfo =  {
            locale: cachedLocale ?? userDefaultLocale,
            userDefaultLocale: userDefaultLocale
        }

        const initialLocale = initialLocaleInfo.locale ??  initialLocaleInfo.userDefaultLocale;

        // if a cached locale does not exist, or your are developing between clients with available locales not same
        if (!initialLocale || !environment.availableLocales.includes(initialLocale)) {
            return this.defaultLocaleInfo;
        } else {
            this.registerCulture(initialLocaleInfo)
            return initialLocaleInfo;
        }
    }

    registerCulture(localeInfo: Models.ILocaleInfo) {


        switch (localeInfo.locale) {
            case 'en':  {
                registerLocaleData(localeEnglish);
                break;
            }
            case 'fr-CA':  {
                registerLocaleData(localeFrenchCa);
                break;
            }
        }

        this.store$.dispatch(AppActions.updateLocale(localeInfo));
    }

    getDefaultLocale() {
        return this.defaultLocaleInfo;
    }
}
