<div class="trend-chart">
    <div
      style="display: flex; height: 100%"
      fxLayout="column"
      fxLayoutAlign="space-around center"
    >
      <div style="width: 100%">
        <div [chart]="currentChart"></div>
      </div>
    </div>
  </div>
  