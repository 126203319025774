import {
  ILeadsOverviewBillboardConfig,
  ILeadsOverviewConfig,
  ILeadsOverviewDealerKpiConfig,
  IDefaultTableColumnConfig,
  ILeadsOverviewKpiConfig,
  ILeadsOverviewSourceTypeCardConfig
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class LeadsOverviewConfig implements ILeadsOverviewConfig {

  public sourceTypeCardConfig: ILeadsOverviewSourceTypeCardConfig = {
    showComparisonMetrics: true,
    leadMetric: {
      name: 'Unique Leads',
      value: 'uniqueLeads'
    },
    closeRateMetric: {
      name: 'Close Rate',
      value: 'closeRate'
    }
  }

  metricDisplayModes = [
    {name: 'MOM', value: 'MOM'},
    {name: 'YOY', value: 'YOY'},
    // { name: '60-Day', value: 'Lookback' },
  ];
  lookbackMonths = 3;
  public reportTitle = 'Leads Overview';
  useDefaultComparisonColumns: boolean = true;
  public billboards: ILeadsOverviewBillboardConfig[] = [
    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-user fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: constants.helpTextKeys.leadsOverviewLeadVolumeBillboardHelp,
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Responded < 30 Min',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate30',
      metricMOMPropertyName: 'responseRate30MOM',
      metricYOYPropertyName: 'responseRate30YOY',
      metricPreviousMonthPropertyName: 'responseRate30PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate30PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate30PreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate30MOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: constants.helpTextKeys.leadsOverviewRespondseRate30BillboardHelp,
      helpTextTitle: 'Responded < 30 Min'
    },
    {
      title: 'Average Response Time',
      subtitle: '',
      iconClass: 'fa-clock-o fa-primary',
      reverseMetric: true,
      metricCurrentPropertyName: 'averageResponseTime',
      metricMOMPropertyName: 'averageResponseTimeMOM',
      metricYOYPropertyName: 'averageResponseTimeYOY',
      metricPreviousMonthPropertyName: 'averageResponseTimePreviousMonth',
      metricPreviousMTDPropertyName: 'averageResponseTimePreviousMTD',
      metricPreviousYearPropertyName: 'averageResponseTimePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'averageResponseTimeMOM',
      metricFormatKey: constants.formatKeys.minutesTimeString,
      helpTextKey: constants.helpTextKeys.leadsOverviewAverageResponseTimeBillboardHelp,
      helpTextTitle: 'Average Response Time'
    },
    {
      title: 'Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'bestMatchCloseRate',
      metricMOMPropertyName: 'bestMatchCloseRateMOM',
      metricYOYPropertyName: 'bestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'bestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'bestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'bestMatchCloseRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'bestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: constants.helpTextKeys.leadsOverviewCloseRateBillboardHelp,
      helpTextTitle: 'Close Rate'
    }
  ];
  public dealerKpiCardConfig: ILeadsOverviewDealerKpiConfig = {
    metrics: {
      'CloseRate': {
        yAxisTitle: 'Close Rate %',
        displayName: 'Close Rate %',
        pointLabelFormat: '{value}%',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: null
      },
      'LeadVolume': {
        yAxisTitle: 'Lead Volume',
        displayName: 'Lead Volume',
        pointLabelFormat: null,
        propertyName: 'leadVolume',
        metricFormatKey: constants.formatKeys.localeString,
        formatter: null
      },
      'ResponseRate': {
        yAxisTitle: '% Responded < 30 Min',
        displayName: '% Responded < 30 Min',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: null // (val) => Math.round(val * 10000) / 100
      }
    }
  };

  public kpiCardConfig: ILeadsOverviewKpiConfig = {
    metrics: {
      'ResponseRate': {
        yAxisTitle: 'Response Rate',
        displayName: 'Response Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 100)
      },
      'CloseRate': {
        yAxisTitle: 'Close Rate',
        displayName: 'Close Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 10000) / 100
      }
    }
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },

    // Lead Volume
    {
      show: true, header: 'Total Leads',
      columnDef: 'leadVolume',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Unique Lead Volume
    {
      show: true, header: 'Total Unique Leads',
      columnDef: 'uniqueLeads',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Response Rate
    {
      show: true,
      header: '% Responded To',
      columnDef: 'responseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Response Rate 30 minute
    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Average Response Time 48hr
    {
      show: true,
      header: 'Avg. Response Time (48 hrs)',
      columnDef: 'averageResponseTime48Hour',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Overall average response time
    {
      show: true,
      header: 'Overall Average Response Time',
      columnDef: 'averageResponseTime',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // 30 day
    {
      show: true,
      header: '30-Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      clickable: false,
      hasComparisonMetrics: true
    },
    // 60 Day Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      clickable: false,
      hasComparisonMetrics: true
    },
    // 90 Day Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      clickable: false,
      hasComparisonMetrics: true
    }
  ];

  public dealerBenchmarkLookbackColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },

    // Lead Volume
    {
      show: true, header: 'Total Leads',
      columnDef: 'leadVolume',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Unique Lead Volume
    {
      show: true, header: 'Total Unique Leads',
      columnDef: 'uniqueLeads',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Response Rate
    {
      show: true,
      header: '% Responded To',
      columnDef: 'responseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Response Rate 30 minute
    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Average Response Time 48hr
    {
      show: true,
      header: 'Avg Response Time (48 hrs',
      columnDef: 'averageResponseTime48Hour',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Overall average response time
    {
      show: true,
      header: 'Overall Average Response Time',
      columnDef: 'averageResponseTime',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // 30 day
    {
      show: true,
      header: '30-Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      clickable: false,
      hasComparisonMetrics: true
    },
    // 60 Day Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      clickable: false,
      hasComparisonMetrics: true
    },
    // 90 Day Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      clickable: false,
      hasComparisonMetrics: true
    },
  ];
}
