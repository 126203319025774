import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import * as Models from '../../../models/models-index';
import { Chart } from 'angular-highcharts';
import { Color, SeriesOptionsType } from 'highcharts';
import { getEnabledCategories } from 'trace_events';

@Component({
  selector: 'kpi-spark-area-fill',
  templateUrl: './kpi-spark-area-fill.component.html',
  styleUrls: ['../panel-elements.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiSparkAreaFillComponent implements Models.ElementComponent, OnInit {
  @Input() dataSet: any;
  @Input() settings: any;

  currentChart: any;
  elementLabel: any;

  constructor() { }

  ngOnInit() {
    let metricIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    let dimensionIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.dimensionName);
    let seriesData = this.dataSet.rows.map(row => row[metricIndex].value);
    let categories = this.dataSet.rows.map(row => row[dimensionIndex].label);
    let chartColor = this.settings.color || '#000000';
    let metricDisplayName = this.dataSet.columns[metricIndex].displayName ?? this.dataSet.columns[metricIndex].name;
    this.elementLabel = metricDisplayName;

    let seriesDataV2 = [];

    this.dataSet.rows.forEach(row => {
      let metricItem = row[metricIndex];
      let dimensionItem = row[dimensionIndex];
      seriesDataV2.push({
        name: metricDisplayName,
        displayValue: metricItem.label ?? metricItem.value,
        displayLabel: dimensionItem.label ?? metricItem.name,
        y: metricItem.value
      })
    })

    this.currentChart = this.generateChart(seriesDataV2, chartColor);
  }

  private generateChart(seriesData: any[], lineColor: string) {
    const categories = seriesData.map(sd => sd.name);
    const chartSeries: SeriesOptionsType[] = [{
      type: 'area',
      data: seriesData,
      color: lineColor,
      lineWidth: 2,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 3,
        color: lineColor
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      chart: {
        showAxes: false,
        height: 120
      },
      tooltip: {
        outside: true,
        pointFormat: '{point.displayLabel}: <b>{point.displayValue}</b>',
        style: {
          zIndex: 100
        }
      },
      // AXES.....
      xAxis: {
        title: { text: null },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              x2: 1,
              y1: 0,
              y2: 0
            },
            stops: [
              [0, this.hexToRGB(lineColor, 0.2)],
              [1, this.hexToRGB(lineColor, 0.55)]
            ]
          }
        }
      },
      series: chartSeries
    });

    return chart;
  }

  hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }
}
