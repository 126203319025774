export class SharedTranslationConfig {
    public labels = {
      'fr-CA': {
        // orgs - maybe split out
        entity: 'Entité',
        sales: 'Ventes',
        service: 'Service',
        dealer: 'Concessionnaire',
        central: 'Centrale',
        eastern: 'Est',
        territorya: 'Territoire A',
        territoryc: 'Territoire C',
        territorye: 'Territoire E',
        territoryf: 'Territoire F',
        territory8: 'Territoire 8',
        western: 'Ouest',
        national: 'National',
        region: 'Région',
        district: 'District',
        // filter translations
        filter: 'Filtre',
        filters: 'Filtres',
        leadtype: 'Type de client potentiel',
        model: 'Modèle',
        provider: 'Fournisseur',
        sourcetype: 'Type de source',
        sourcetypes: 'Types de source',
        newsales: 'Ventes Nouveau', // in the legacy nci app - new leads called New Sales
        new: 'Nouveau',
        usedsales: 'Ventes Nouveau',
        used: 'Ventes D\'occasion', // in the legacy nci app - used leads called Used Sales
        cposales: 'CPO',
        cpo: 'CPO',
        loyalty: 'Loyauté',
        loyaltyleads: 'Ventes Loyauté',
        providerkpi: 'KPI- Fournisseur',
        // it's for Provider KPI's
        providerkpis: 'KPI- Fournisseur',
        dealersummary: 'Sommaire du Concessionnaire',
        devicetype: 'Type d\'appareil',
        channel: 'Chaîne',
        desktop: 'Bureau',
        tablet: 'Tablette',
        mobile: 'Mobile',
        phone: 'Mobile',
        brand: 'Marque',
        dealerwebsite: 'Site Web du concessionnaire',
        dealergenerated: 'Créé par le concessionnaire',        
        // common labels
        benchmark: 'Analyse Comparative',
        leadcontribuiton: 'Contribution des clients potentiels',
        general: 'Générale',
        default: 'Défaut',
        mom: 'MSM',
        yoy: 'ASA',
        trend: 'Tendance',
        thismonth: 'Mois courant',
        prevmonth: 'Mois préc.',
        prevperiod: 'Mois préc.',
        prevyear: 'Année préc.',
        prevmtd: 'Màj préc.',
        help: 'Aide',
        //help text titles
        sessionstartrate: 'Taux de Demarrage de Session',
        websitevisits: 'Visites du site Web',
        leadcapturerate: 'Taux de Capture de prospects',
        appointmentrate: 'Taux de rendez-vous',
        digitalretailingproviderkpis: 'Vente au détail en ligne KPI- Fournisseur',
        exportall: 'Exporter tout',
        summarydata: 'Résumé Des Données',
        dealerperformance: 'Rendement du concessionnaire',
        dealeravg: 'Concessionnaire moy',
        // filter panel labels
        digitalretail: 'Vente au détail en ligne',
        allmodels: 'Tous les modèles',
        selected: 'sélectionné',
        search: 'Chercher',
        clearselected: 'EFFACER' + '\n' + 'SÉLECTIONNÉ',
        noneselected: 'Aucune sélection',
        apply: 'Appliquer',
        more: 'plus',
        cancel: 'Annuler',
        // dates
        start: 'Début',
        end: 'Fin',
        days: 'journées',
        custom: 'Personnalisé',
        currentmonth: 'Mois courant',
        salesmonth: 'Mois de vente',
        dayofsalesmonth: 'Jour du mois de vente',
        "12monthtrend": 'Tendance sur 12 mois',
        lastmonth: 'Mois précédent',
        '60day': '60 jours',
        last30days: '30 derniers Jours',
        last3months: '3 Mois precédent',
        last12months: '12 Derniers mois',
        last13months: '13 Derniers mois',
        quarters: 'Quarts',
        salesmonthsenabled: 'Mois de vente activés',
        january: 'Janvier',
        december: 'Décembre',
        november: 'Novembre',
        february: 'Février',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Aout',
        september: 'Septembre',
        october: 'Octobre',
        kpitrend: 'Tendance',
        responserate: '% de réponse',
        closerate: 'Taux de ventes conclues',
      },

  };
}
