import { Component, ViewChild, Input, OnChanges, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataTableService, FilterService } from '../../_shared/services/services-index';
import { Subscription } from 'rxjs';
import { ExportCsv } from '../../_shared/utilities/generate-csv';
import { Enums } from '../enums/enums';


@Component({
  selector: 'paged-sorted-data-table',
  templateUrl: 'pagedSortedDataTable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagedSortedDataTableComponent implements OnDestroy, OnChanges, OnInit {
  @Input() columns: any[] = [];
  @Input() pagination = true;
  @Output() getRowData = new EventEmitter<any>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[];
  receivedData: any;
  showMom = false;
  showYoy = false;
  dataSource: MatTableDataSource<any>;
  filterBreadcrumbsForExport: string[];
  subscriptions: Subscription[] = [];

  pageIndex = 0;
  pageSize = 25;
  length;

  constructor(
    private dataTableService: DataTableService,
    private changeDetector: ChangeDetectorRef,
    private filterService: FilterService) {
    this.columns = [];
    this.receivedData = [];
    this.displayedColumns = this.columns.filter(col => col.show === true).map(x => x.columnDef);
    this.dataSource = new MatTableDataSource(this.receivedData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.dataTableService.dataUpdated$.pipe(
        // filter(updatedData => !!updatedData.data)
      ).subscribe(updatedData => {
        this.columns = updatedData.columns || [];
        this.receivedData = updatedData.data || [];
        this.displayedColumns = this.columns.filter(col => col.show === true).map(x => x.columnDef);
        this.dataSource = new MatTableDataSource(this.receivedData);
        this.dataSource.paginator = this.paginator;
        if (!!this.pagination && !!this.dataSource.paginator) {
          this.dataSource.paginator.disabled = !this.pagination;
        }
        this.dataSource.sort = this.sort;
        this.changeDetector.detectChanges();
        this.filterService.requestBreadcrumbString();
      }),
      this.dataTableService.printButtonClicked$.subscribe(data => {
        if (data.printingOption === Enums.printingOptions.pdf) {
          console.log('PDF exporting is not supported in hierarchyDataTable');
        } else {
          this.excel(data.title);
        }
      }),
      this.filterService.filterBreadcrumbsReturned$.subscribe(breadcrumbs => {
        this.filterBreadcrumbsForExport = breadcrumbs;
      })
    );
  }

  ngOnChanges() {

  }

  ngOnDestroy(): void {
    this.displayedColumns = [];
    this.receivedData = [];
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  emitRowData(rowData: any, columnData: any) {
    this.getRowData.emit({ row: rowData, column: columnData });
  }

  private excel(title: string): void {
    let data: string[][] = [[title]];

    //data.push(['Filters:']);

    (this.filterBreadcrumbsForExport || []).forEach(bc => {
      data.push([bc]);
    });

    const cols: string[] = this.columns.filter(col => (!!col.show && col.print != false) || col.print === true).map(col => col.header);

    data.push(['']);
    data.push(cols);
    data = data.concat(this.getRowsForExport());

    const printTitle = title.replace(/-| /g, ''); // Regular expression /-| /g = all instances of ' ' or '-'

    new ExportCsv(data, printTitle);
  }

  private getRowsForExport(): string[][] {
    const results: string[][] = [];

    const columns = this.columns.filter(col => (!!col.show && col.print != false) || col.print === true);

    const sortBy = (key, dir) => {
      if (dir === 'desc') {
        return (a, b) => (a[key] > b[key]) ? -1 : ((b[key] > a[key]) ? 1 : 0);
      }
      else {
        return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
      }
    };

    const orderedData = this.dataSource.filteredData.concat().sort(sortBy(this.sort.active, this.sort.direction))

    orderedData.forEach(dataRow => {
      const exportRow: string[] = [];
      columns.forEach(col => {
        col.printFormatter
          ? exportRow.push(col.printFormatter(dataRow[col.columnDef]))
          : col.formatter
            ? exportRow.push(col.formatter(dataRow[col.columnDef]))
            : exportRow.push(dataRow[col.columnDef] || '');
      });
      results.push(exportRow);
    });

    return results;
  }

  getColumnWidthClass(columnDef: string) {
    if (columnDef === 'city' || columnDef === 'vehicleModel' || columnDef === 'saleModel' || columnDef === 'dealerCode' || columnDef === 'saleDealerCode' || columnDef === 'saleDealerCode' || columnDef === 'saleDate') {
      return 'small';
    } else if (columnDef === 'timeToRespond') {
      return 'smaller';
    } else if (columnDef === 'state') {
      return 'smallest';
    }else if (columnDef === 'leadKey') {
      return 'big';
    }
  }

}
