import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './genericModal.component.html'
})

export class GenericModalComponent {

  constructor(public dialogRef: MatDialogRef<GenericModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  confirm() {
    this.dialogRef.close({ confirm: true });
  }
  closeModal() {
    this.dialogRef.close();
  }
}
