import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IMetricType } from '../../_shared/enums/enums';
import { Observable } from 'rxjs';
import { SdMetricSelectorService } from '../../_shared/services/sdMetricSelector/sdMetricSelector.service';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'sd-metric-selector',
    templateUrl: './sdMetricSelector.component.html',
    styleUrls: ['./sdMetricSelector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SdMetricSelectorComponent {
    selectedMetric$: Observable<IMetricType>;
    metricOptions$: Observable<IMetricType[]>;
    constructor(
        private metricSelectorService: SdMetricSelectorService
    ) {
        this.selectedMetric$ = this.metricSelectorService.metricUpdated$.pipe(delay(0));
        this.metricOptions$ = this.metricSelectorService.metricsOptionsUpdated$.pipe(delay(0));
     }

    updatePrimaryMetric(metric: IMetricType) {
        this.metricSelectorService.updateSelectedMetric(metric);
    }
}
