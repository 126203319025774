import { Injectable } from '@angular/core';
import * as Models from '../../models/models-index';

@Injectable()
export class PanelElementFormattingService {

    constructor() { }

    // Note: this is exactly the same as the getPanelStyles for starters but we're keeping them separate to avoid unintended consequences down the road
    getElementStyles(settings: Models.ElementSettings) {
        let style = '';

        if (!!settings.widthUnits && !!settings.widthBasis) {
            style += `flex: 0 0 ${settings.widthUnits * 100 / settings.widthBasis}%; max-width: ${settings.widthUnits * 100 / settings.widthBasis}%; display: block;`;
        }
        else {
            style += 'flex: 0 0 100%; max-width: 100%; display: block;';
        }

        return style;
    }

    getPanelStyles(panel: Models.Panel) {
        let style = '';

        if (!!panel.widthUnits && !!panel.widthBasis) {
            style += `flex: 0 0 ${panel.widthUnits * 100 / panel.widthBasis}%; max-width: ${panel.widthUnits * 100 / panel.widthBasis}%; display: block;`;
        }
        else {
            style += 'flex: 0 0 100%; max-width: 100%; display: block;';
        }

        return style;
    }

}
