import {
    IInventoryOverviewBillboardConfig,
    IInventoryOverviewConfig,
    IDefaultTableColumnConfig,
    IInventoryOverviewKpiCardConfig,
    ILeadsOverviewKpiConfig } from '../../../../_shared/models/models-index';

export class InventoryOverviewConfig implements IInventoryOverviewConfig {

    public billboards: IInventoryOverviewBillboardConfig[] = [];
    public inventorySummaryColumns: IDefaultTableColumnConfig[];
    public classSummaryColumns: IDefaultTableColumnConfig[];
    public modelSummaryColumns: IDefaultTableColumnConfig[];
    public inventoryKpiCardConfig: IInventoryOverviewKpiCardConfig;
}