import { Injectable } from '@angular/core';
import * as Modals from '../../modals/modals-index';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LocaleService } from '../services-index';
import { map, take } from 'rxjs/operators';

@Injectable()
export class HelpTextService {

  constructor(private dialog: MatDialog, private localeService: LocaleService) {}

  openHelp(name: string, title: string) {
    this.localeService.locale$.pipe(
      take(1),
      map(loc => {
        this.dialog.open(Modals.SharedHelpTextModalComponent, {
          width: '50vw',
          data: {
            title: title,
            name: name,
            locale: loc
          }
        });
      })
    ).subscribe()
  }
}
