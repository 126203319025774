import { Injectable } from '@angular/core';
import { RoutingConfig } from '../../../../_config/routing/routing.config';
import { IRoutingConfig } from '../../../models/models-index';
import { AuthenticationService, RoleConfigService } from '../../services-index';

@Injectable()
export class RoutingConfigService {

    routingConfig: IRoutingConfig = new RoutingConfig;

    get defaultRoute(): string {
        return this.getDefaultRoute();
    }

    constructor(private readonly roleConfigService: RoleConfigService,
        private readonly authenticationService: AuthenticationService) { }

    private getDefaultRoute(): string {
        const role = this.authenticationService.getUser().role;
        const roleDefaultRoute = this.roleConfigService.getDefaultRoute(role);
        if(roleDefaultRoute) {
            return roleDefaultRoute;
        }

        const defaultRoute = this.routingConfig.routes.find(r => r.default === true);

        if (!!defaultRoute) {
            return defaultRoute.defaultRoute;
        }

        // System default
        return '/leads/overview';
    }
}
