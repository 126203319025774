import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';


@Injectable()
export class DealerPerformanceReportService {

	constructor(private http: HttpClient) { }

	getDealerPerformanceSummaryMetrics(reportRequest: any): Promise<Models.DealerPerformanceSummaryResponseModel> {
		return new Promise<Models.DealerPerformanceSummaryResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DealerPerformanceSummaryResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDealerUniqueLeadsMetrics(reportRequest: any): Promise<Models.DealerUniqueLeadsMetricResponseModel> {
		return new Promise<Models.DealerUniqueLeadsMetricResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DealerUniqueLeadsMetricResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getNationalDealerPerformanceSummaryMetrics(reportRequest: any): Promise<Models.NationalDealerPerformanceSummaryResponseModel> {
		return new Promise<Models.NationalDealerPerformanceSummaryResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.NationalDealerPerformanceSummaryResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDealerHierarchyMetrics(reportRequest: any): Promise<Models.DealerHierarchyMetricResponseModel> {
		return new Promise<Models.DealerHierarchyMetricResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DealerHierarchyMetricResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getUniqueLeadsMetrics(reportRequest: any): Promise<Models.UniqueLeadsResponseModel> {
		return new Promise<Models.UniqueLeadsResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.UniqueLeadsResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

}
