import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'numberFormat' })
export class DecimalNumberFormatPipe implements PipeTransform {
    transform(val: any, minDigits?: number): string {
        if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
            return 'N/A';
        } else if (!!minDigits) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: minDigits,
                maximumFractionDigits: minDigits
            });
        } else {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        }
    }
}
