import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums, IEnums } from '../../../_shared/enums/enums';
import { AuthenticationService, LocaleService } from '../../../_shared/services/services-index';
import * as Models from '../../../_shared/models/models-index';
import { passwordMatch } from '../../../_shared/directives/passwordCompare.directive';
import { Subscription } from 'rxjs';
import { ManagementTranslationService } from '../../../_shared/locale/translation/management-translation.service';

@Component({
  selector: 'user-settings-modal',
  templateUrl: './userSettings.component.html'
})

export class UserSettingsComponent implements OnDestroy, OnInit {
  @Input() user?: Models.User;
  enums: IEnums = Enums;
  subscriptions: Subscription[] = [];
  labels: any;
  confirmPassword = '';
  currentPassword = '';
  errorMessage: string;
  password = '';
  isNew: boolean;

  userSettingsForm: UntypedFormGroup;
  userName: UntypedFormControl;
  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  userCurrentPassword: UntypedFormControl;
  userNewPassword: UntypedFormControl;
  userConfirmNewPassword: UntypedFormControl;

  constructor(
    private authService: AuthenticationService,
    public activeModal: NgbActiveModal,
    private localeService: LocaleService,
    private translationConfig: ManagementTranslationService
    ) {
  }

  ngOnInit() {
    this.userName = new UntypedFormControl('');
    this.firstName = new UntypedFormControl('');
    this.lastName = new UntypedFormControl('');
    this.userCurrentPassword = new UntypedFormControl('');
    this.userNewPassword = new UntypedFormControl('', Validators.minLength(8));
    this.userConfirmNewPassword = new UntypedFormControl('', [Validators.minLength(8), passwordMatch(this.userNewPassword)]);

    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => {
        this.labels = {...this.translationConfig.config.userManagement[loc]};
      })
    );

    this.userSettingsForm = new UntypedFormGroup({
      userName: this.userName,
      firstName: this.firstName,
      lastName: this.lastName,
      userCurrentPassword: this.userCurrentPassword,
      userNewPassword: this.userNewPassword,
      userConfirmNewPassword: this.userConfirmNewPassword
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public cancel(): void {
    this.activeModal.close(false);
  }

  public save(): void {
    const userInfo: Models.IUser = <Models.IUser>{
      userName: this.user.userName,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      active: this.user.active,
      password: this.password,
      confirmPassword: this.confirmPassword
    };

    // if (this.password !== this.currentPassword) {

    //   this.authService.changePassword(this.currentPassword, this.password, this.confirmPassword)
    //     .then((result) => this.activeModal.close(this.user));


    // } else {
    //   if (this.userSettingsForm.dirty) {

    //     this.authService.updateUser(userInfo).then((result) => {
    //       this.activeModal.close(this.user);
    //     });


      // } else
        // if (this.password === '' && this.currentPassword === '' && this.confirmPassword === '')
        this.activeModal.close(this.user);
    // }
  }
}
