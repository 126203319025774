
import {
  ISalesForecastingConfig,
  ISalesForecastingBillboardConfig,
  IDefaultTableColumnConfig,
  ISalesForecastingModelPerformanceConfig,
} from '../../../../_shared/models/models-index';

export class SalesForecastingConfig implements ISalesForecastingConfig {
  public reportTitle: string;
  public useDefaultComparisonColumns: boolean;
  public chartSeriesColors: string[];
  public billboards: ISalesForecastingBillboardConfig[] = [];
  public billboardsDealerLevel: ISalesForecastingBillboardConfig[] = [];
  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [];
  public modelPerformanceBarMetricName: string = '';
  public modelPerformanceMetrics: ISalesForecastingModelPerformanceConfig[] = [];
}
