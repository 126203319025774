<mat-card [@transformMenu]="panelState" class="mat-card filter-panel-card mat-elevation-z8">
    <div class="filter-panel-header">

        <div class="mat-card-header-content">

            <span class="mat-dialog-title" style="margin: 0">
                {{title}}
            </span>

            <span class="dialog-close-icon" style="font-size: 24px; cursor: pointer;" (click)="closePanel()">
                <mat-icon>close</mat-icon>
            </span>

        </div>

    </div>
    <mat-card-content class="filter-panel-content-container">

        <ng-container [ngSwitch]="renderMethod">

            <div *ngSwitchCase="renderMethods.text" [innerHtml]="content"></div>

            <ng-container *ngSwitchCase="renderMethods.template">
                <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="renderMethods.component">
                <ng-container *ngComponentOutlet="content"></ng-container>
            </ng-container>

        </ng-container>

    </mat-card-content>
    <mat-card-footer class="filter-panel-footer">

        <button [disabled]="applyButtonDisabled" mat-button class="apply-button" (click)="applySelected()">
            <span>{{applyButtonText}}</span>
        </button>

    </mat-card-footer>

</mat-card>
