import { Enums, IPrintingOption } from '../../enums/enums';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
const clone = require('rfdc/default');

export interface IDataTablePrintingOptions {
  title: string;
  expandAll?: boolean;
  printingOption: IPrintingOption;
}

@Injectable()
export class DataTableService {
  // Default states
  public heightExpanded: boolean = false;

  private dataUpdated = new BehaviorSubject<any>([]);
  private selectedTrendUpdated = new Subject<any>();
  private printButtonClicked = new Subject<IDataTablePrintingOptions>();
  private kpiPrintButtonClicked = new Subject<IDataTablePrintingOptions>();
  private pathToPurchasePrintButtonClicked = new Subject<IDataTablePrintingOptions>();
  private toggleHierarchyTableExpando = new Subject<any>();
  private toggleHierarchyV5TableExpando = new Subject<any>();
  private togglePagedSortedTableExpando = new Subject<any>();
  private toggleTrendTableExpando = new Subject<any>();
  dataUpdated$ = this.dataUpdated.asObservable();
  selectedTrendUpdated$ = this.selectedTrendUpdated.asObservable();
  printButtonClicked$ = this.printButtonClicked.asObservable();
  kpiPrintButtonClicked$ = this.kpiPrintButtonClicked.asObservable();
  pathToPurchasePrintButtonClicked$ = this.pathToPurchasePrintButtonClicked.asObservable();
  toggleHierarchyTableExpando$ = this.toggleHierarchyTableExpando.asObservable();
  toggleHierarchyV5TableExpando$ = this.toggleHierarchyV5TableExpando.asObservable();
  togglePagedSortedTableExpando$ = this.togglePagedSortedTableExpando.asObservable();
  toggleTrendTableExpando$ = this.toggleTrendTableExpando.asObservable();

  constructor() {

  }

  toggleExpando(dataTableType: string, expandType: string): void {
    switch (dataTableType) {
      case Enums.dataTableTypes.hierarchy:
        this.heightExpanded = !this.heightExpanded;
        this.toggleHierarchyTableExpando.next({ expandType: expandType, expanded: this.heightExpanded });
        break;
      case Enums.dataTableTypes.pagedSorted:
        this.heightExpanded = !this.heightExpanded;
        this.togglePagedSortedTableExpando.next({ expandType: expandType, expanded: this.heightExpanded });
        break;
      case Enums.dataTableTypes.trend:
        this.heightExpanded = !this.heightExpanded;
        this.toggleTrendTableExpando.next({ expandType: expandType, expanded: this.heightExpanded });
        break;
      case Enums.dataTableTypes.hierarchyV5:
        this.heightExpanded = !this.heightExpanded;
        this.toggleHierarchyV5TableExpando.next({ expandType: expandType, expanded: this.heightExpanded });
        break;
      default:
        break;
    }
  }

  resetExpansionState() {
    this.heightExpanded = false;
  }

  updateData(data: any): void {
    this.dataUpdated.next(data);
  }

  updateSelectedTrend(activeTrendMetrics: string[]): void {
    this.selectedTrendUpdated.next(activeTrendMetrics);
  }

  printCurrentData(printingOptions: IDataTablePrintingOptions): void {
    this.printButtonClicked.next(printingOptions);
  }

  printKpiTable(printOptions: IDataTablePrintingOptions): void {
    this.kpiPrintButtonClicked.next(printOptions);
  }

  printPathToPurchaseTable(printOptions: IDataTablePrintingOptions): void {
    this.pathToPurchasePrintButtonClicked.next(printOptions);
  }

  toggleChildRows(clickedRow: any, tableData: any[], columns: string[]) {

    const newData = clone(tableData);

    let hideChildren = (row: any) => {
      if (row.hasChildren) {
        row.showChildren = false;
        newData.filter(child => child.parentEntity === row.entity).forEach(child => { child.show = false; hideChildren(child); });
      }
    }

    if (!clickedRow.entityKey) {
      // toggle all rows for whom the parent entity was clicked and there is no entity key defined
      newData.filter(row => row.parentEntity === clickedRow.entity)
        .forEach(row => {
          if (clickedRow.entity === 'Other') {
            if (clickedRow.parentEntity === 'Total') {
              if (clickedRow.vehicleMakeName !== null && clickedRow.vehicleMakeName === row.vehicleMakeName && row.entityType === 'year') {
                row.show = !row.show;
              } else if (clickedRow.vehicleModelName !== null && clickedRow?.vehicleMakeName === row.vehicleMakeName && clickedRow.vehicleModelName === row.vehicleModelName && row.entityType === 'year') {
                row.show = !row.show;
              } else if (clickedRow.vehicleClassName !== null && clickedRow?.vehicleMakeName === row.vehicleMakeName && clickedRow?.vehicleModelName === row.vehicleModelName && clickedRow.vehicleClassName === row.vehicleClassName && row.entityType === 'year') {
                row.show = !row.show;
              }
            } else if (row?.vehicleMakeName === clickedRow.parentEntity) {
              row.show = !row.show;
            } else if (row?.vehicleModelName === clickedRow.parentEntity) {
              row.show = !row.show;
            } else if (row?.vehicleClassName === clickedRow.parentEntity) {
              row.show = !row.show;
            }
          } else {
            row.show = !row.show;
          }
          // if hiding a row, call a function to recursively hide children
          if (!row.show) {
            hideChildren(row);
          }
        });

      const currentShowChildrenState = newData.filter(row => row.entity === clickedRow.entity)[0].showChildren;
      newData.filter(row => row.entity === clickedRow.entity)[0].showChildren = !currentShowChildrenState;

    } else {
      // toggle all rows for whom the parent entity was clicked and we have an entity key defined
      newData.filter(row => row.parentEntityKey === clickedRow.entityKey)
        .forEach(row => {
          row.show = !row.show;
          // if hiding a row, call a function to recursively hide children
          if (!row.show) {
            hideChildren(row);
          }
        });

      const currentShowChildrenState = newData.filter(row => row.entityKey === clickedRow.entityKey)[0].showChildren;
      newData.filter(row => row.entityKey === clickedRow.entityKey)[0].showChildren = !currentShowChildrenState;

    }

    var updatedData = {
      columns: columns,
      data: newData
    }

    this.dataUpdated.next(updatedData);

  }
}
