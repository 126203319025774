import {
  IDefaultTableColumnConfig,
  IMultiLevelDualAxisLineGraphConfig,
  IDualAxisLineGraphConfig
} from '../../../../_shared/models/models-index';
import {
  IServiceSchedulerOverviewBillboardConfig,
  IServiceSchedulerOverviewKpiConfig,
  IServiceSchedulerOverviewConfig
} from '../../../../_shared/models/provider-tools-config.models';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class ServiceSchedulerOverviewConfig implements IServiceSchedulerOverviewConfig {
  public reportTitle = 'Service Scheduler Overview';
  public chartSeriesColors = ['#000', '#ed1c24', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' }
  ];
  public billboards: IServiceSchedulerOverviewBillboardConfig[] = [{
    title: 'Visits With Impressions',
    subtitle: null,
    iconClass: 'fa-users fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'visitsWithImpressions',
    metricMOMPropertyName: 'visitsWithImpressionsMOM',
    metricYOYPropertyName: 'visitsWithImpressionsYOY',
    metricPreviousMonthPropertyName: 'visitsWithImpressionsPreviousMonth',
    metricPreviousMTDPropertyName: 'visitsWithImpressionsPreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'visitsWithImpressionsMOM',
    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
    helpTextKey: 'ServiceSchedulerOverviewVisitsWithImpressionsBillboardHelp',
    helpTextTitle: 'Visits With Impressions',
  }, {
    title: 'Scheduler Start Rate',
    subtitle: null,
    iconClass: 'fa-calculator fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'startRate',
    metricMOMPropertyName: 'startRateMOM',
    metricYOYPropertyName: 'startRateYOY',
    metricPreviousMonthPropertyName: 'startRatePreviousMonth',
    metricPreviousMTDPropertyName: 'startRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'startRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'ServiceSchedulerOverviewServiceSchedulerStartRateBillboardHelp',
    helpTextTitle: 'Service Scheduler Start Rate',
  }, {
    title: 'Appointments',
    subtitle: null,
    iconClass: 'fa-users fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'appointments',
    metricMOMPropertyName: 'appointmentsMOM',
    metricYOYPropertyName: 'appointmentsYOY',
    metricPreviousMonthPropertyName: 'appointmentsPreviousMonth',
    metricPreviousMTDPropertyName: 'appointmentsPreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'appointmentsMOM',
    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
    helpTextKey: 'ServiceSchedulerOverviewAppointmentsBillboardHelp',
    helpTextTitle: 'Appointments',
  }, {
    title: 'Appointment Rate',
    subtitle: null,
    iconClass: 'fa-check-square-o fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'appointmentRate',
    metricMOMPropertyName: 'appointmentRateMOM',
    metricYOYPropertyName: 'appointmentRateYOY',
    metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
    metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'appointmentRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'ServiceSchedulerOverviewAppointmentRateBillboardHelp',
    helpTextTitle: 'Appointment Rate',
  }];
  public kpiSheetOptions = {
    overview: [{
      name: 'providerId',
      label: 'Provider',
      isHeader: true
    }, {
      name: 'visitsWithImpressions',
      label: 'Visits With Impressions',
    }, {
      name: 'startRate',
      label: 'Scheduler Start Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'loginRate',
      label: 'Login Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'accountCreationRate',
      label: 'Account Creation Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'appointmentRate',
      label: 'Appointment Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }]
  };
  public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: 0,
        displayName: 'None',
        propertyName: null,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: 1,
        displayName: 'Visits With Impressions',
        propertyName: 'visitsWithImpressions',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: 2,
        displayName: 'Scheduler Starts',
        propertyName: 'starts',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: 3,
        displayName: 'Scheduler Start Rate',
        propertyName: 'startRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }, {
        id: 4,
        displayName: 'Login',
        propertyName: 'logins',
        metricFormatKey: constants.formatKeys.localeString
      }, {
        id: 5,
        displayName: 'Login Rate',
        propertyName: 'loginRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }, {
        id: 6,
        displayName: 'Account Creations',
        propertyName: 'accountCreations',
        metricFormatKey: constants.formatKeys.localeString
      }, {
        id: 7,
        displayName: 'Account Creation Rate',
        propertyName: 'accountCreationRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }, {
        id: 8,
        displayName: 'Appointments',
        propertyName: 'appointments',
        metricFormatKey: constants.formatKeys.localeString
      }, {
        id: 9,
        displayName: 'Appointment Rate',
        propertyName: 'appointmentRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ],
    childMetrics: [
      {
        id: Enums.chatProviders.allProviders.providerId,
        displayName: Enums.chatProviders.allProviders.displayName,
        propertyName: Enums.chatProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },

    ],
    defaultChildrenIds: [Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId],
    defaultParentIds: [1,2],
    metricOneColorHexCode: '#000',
    metricTwoColorHexCode: '#ed1c24'
  };
  public dealerTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
      // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
    }, {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    }, {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Impressions Prev. Month',
      columnDef: 'impressionsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Impressions MOM',
      columnDef: 'impressionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: true,
      header: 'Visits With Impressions',
      columnDef: 'visitsWithImpressions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Impressions Prev. Month',
      columnDef: 'visitsWithImpressionsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Impressions MOM',
      columnDef: 'visitsWithImpressionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: true,
      header: 'Starts',
      columnDef: 'starts',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Starts Prev. Month',
      columnDef: 'startsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Starts MOM',
      columnDef: 'startsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: true,
      header: 'Start Rate',
      columnDef: 'startRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    }, {
      show: false,
      header: 'Start Rate Prev. Month',
      columnDef: 'startRatePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    }, {
      show: false,
      header: 'Start Rate MOM',
      columnDef: 'startRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // logins
    {
      show: true,
      header: 'Logins',
      columnDef: 'logins',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Logins Prev. Month',
      columnDef: 'loginsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Logins MOM',
      columnDef: 'loginsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: true,
      header: 'Login Rate',
      columnDef: 'loginRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Login Rate Prev. Month',
      columnDef: 'loginRatePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Login Rate MOM',
      columnDef: 'loginRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // account creation
    {
      show: true,
      header: 'Account Creations',
      columnDef: 'accountCreations',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Account Creations Prev. Month',
      columnDef: 'accountCreationsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Account Creations MOM',
      columnDef: 'accountCreationsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: true,
      header: 'Account Creation Rate',
      columnDef: 'accountCreationRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Account Creation Rate Prev. Month',
      columnDef: 'accountCreationRatePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'AccountCreation Rate MOM',
      columnDef: 'accountCreationRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // appointments
    {
      show: true,
      header: 'Appointments',
      columnDef: 'appointments',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Appointments Prev. Month',
      columnDef: 'appointmentsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Appointments MOM',
      columnDef: 'appointmentsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointment Rate Prev. Month',
      columnDef: 'appointmentRatePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointment Rate MOM',
      columnDef: 'appointmentRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }];
}
