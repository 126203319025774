import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Subscription, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { SdFilterPanelValueService } from '../services/sd-filter-panel-value.service';
import { MatLegacySelectionList as MatSelectionList, MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { SdFilterPanelRef } from '../types/sd-filter-panel-ref';
import * as FilterPanelTypes from './../types/types';
import { SharedTranslationService, LocaleService } from '../../services/services-index';


@Component({
    selector: 'filter-panel-list',
    templateUrl: './sd-filter-panel-list.component.html',
    styleUrls: ['./sd-filter-panel-list.component.scss']
})
export class SdFilterPanelListComponent implements OnInit, OnDestroy, AfterViewInit {
    private _subscription = new Subscription();

    panelData: FilterPanelTypes.SimpleValue[] = [];
    filteredPanelData: FilterPanelTypes.SimpleValue[] = [];
    filteredPanelDataSubject: BehaviorSubject<FilterPanelTypes.SimpleValue[]> = new BehaviorSubject([]);
    filteredPanelData$ = this.filteredPanelDataSubject.asObservable();

    selected: FilterPanelTypes.SimpleValue[] = [];
    indeterminate = false;
    checkBoxHeaderLabel: string;
    checkBoxHeaderLabelSubject: BehaviorSubject<string> = new BehaviorSubject('');
    checkBoxHeaderLabel$ = this.checkBoxHeaderLabelSubject.asObservable();
    checkBoxHeaderLabelPrefix: string;
    checkBoxHeaderLabelSuffix: string;
    singleSelectionMode: boolean = false;

    searchTerm = '';
    searchHasFocus = false;
    showProgress = false;
    checkBoxHeaderDisabled = false;

    locale: string;
    searchPlaceholderText = 'Search';
    clearSelectedText = 'CLEAR SELECTED';
    noneSelectedText = 'None selected';
    selectedText = 'selected';
    rippleColor = 'rgba(0, 0, 0, .10)';

    @ViewChild('panelDataSelectList', { static: true }) panelDataSelectList: MatSelectionList;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    constructor(
        private filterPanelRef: SdFilterPanelRef,
        private localeService: LocaleService,
        private translationService: SharedTranslationService,
        private valueService: SdFilterPanelValueService) { }

    ngOnInit() {

        const searchInput$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            filter(this.isValidKey),
            map((event: any) => event.target.value),
            debounceTime(400),
            distinctUntilChanged()
        );

        this._subscription.add(
            combineLatest([
                this.filterPanelRef.config.inputData,
                searchInput$.pipe(startWith('')),
                this.valueService.selectedItems$
            ])
                .pipe(
                    tap(([inputData, searchTerm, selectedItems]) => {

                        this.filteredPanelData = <FilterPanelTypes.SimpleValue[]>inputData.inputData;
                        this.filteredPanelDataSubject.next(<FilterPanelTypes.SimpleValue[]>inputData.inputData);
                        this.panelData = <FilterPanelTypes.SimpleValue[]>inputData.inputData;
                        this.singleSelectionMode = !!this.filterPanelRef.config.singleSelectionMode;
                        this.checkBoxHeaderDisabled = this.singleSelectionMode
                        this.checkBoxHeaderLabelPrefix = this.singleSelectionMode ? '' : `${this.filteredPanelData.length} `;
                        this.checkBoxHeaderLabelSuffix = this.filteredPanelData.length > 1 ? 's' : '';
                        this.checkBoxHeaderLabel =
                            `${this.checkBoxHeaderLabelPrefix}${this.filterPanelRef.config.panelTitle}${this.checkBoxHeaderLabelSuffix}`;

                        this.checkBoxHeaderLabelSubject.next(this.checkBoxHeaderLabel);

                        this.searchTerm = searchTerm;
                        this.filterSelectList();

                        this.selected = [];
                        this.selected.push(...selectedItems);
                        if (this.selected.length <= 0) {
                            this.clearSelectList();
                        }

                    })).subscribe());

        // this._subscription.add(
        //     this.valueService.selectedItems$
        //         .subscribe((selectedItems) => {
        //             this.selected = [];
        //             this.selected.push(...selectedItems);
        //             if (this.selected.length <= 0) {
        //                 this.clearSelectList();
        //             }
        //         })
        // );

        //   this._subscription.add(
        //     fromEvent(this.searchInput.nativeElement, 'keyup')
        //         .pipe(
        //             map((event: any) => {
        //                 this.showProgress = true;
        //                 return event;
        //             }),
        //             filter(event => {
        //                 const isValidKey = this.isValidKey(event);
        //                 if (!isValidKey) {
        //                     this.showProgress = false;
        //                     return false;
        //                 }
        //                 return true;
        //             }),
        //             debounceTime(400),
        //             distinctUntilChanged()
        //         ).subscribe((event: any) => {
        //             this.searchTerm = event.target.value;
        //             this.filterSelectList();
        //             this.showProgress = false;
        //         })
        //   );

        this._subscription.add(
            this.localeService.locale$.subscribe(loc => {
                this.locale = loc;
                this.clearSelectedText = this.translationService.getLabelTranslation(this.clearSelectedText, loc);
                this.noneSelectedText = this.translationService.getLabelTranslation(this.noneSelectedText, loc);
                this.selectedText = this.translationService.getLabelTranslation(this.selectedText, loc);
                this.searchPlaceholderText = this.translationService.getLabelTranslation(this.searchPlaceholderText, loc);
            }));
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        const currentFilterValues = this.filterPanelRef.config.currentFilterValues;
        if (currentFilterValues && currentFilterValues.inputData) {
            this.setSelectionState(<FilterPanelTypes.SimpleValue[]>this.filterPanelRef.config.currentFilterValues.inputData);
        }
    }

    compareOptions = (val1: FilterPanelTypes.SimpleValue, val2: FilterPanelTypes.SimpleValue) => val1.value === val2.value;

    isIndeterminate(): boolean {
        return this.panelDataSelectList.selectedOptions.selected.length !== this.filteredPanelData.length
            && this.panelDataSelectList.selectedOptions.selected.length >= 1;
    }

    isChecked(): boolean {
        return this.panelDataSelectList.selectedOptions.selected.length === this.filteredPanelData.length;
    }

    onSelectListChanged(options: MatListOption[]): void {
        this.valueService.setSelectedItems(options);
    }

    clearAllSelected(): void {
        this.deselectAll();
    }

    clearSearchTerm($event): void {
        this.searchTerm = '';
        this.filterSelectList();
    }

    filterDataOptionChanged(value) {
        const existingFilterItem = this.selected.find(s => s.value === value.value);
        const existingSelectedFilterItems = this.selected.filter(s => s.value !== value.value);
        if (!!this.filterPanelRef.config.singleSelectionMode) {
            if (existingFilterItem)
                this.removeSelectedFilter(existingFilterItem)
            else
                this.handleSingleSelectList(value, existingSelectedFilterItems)
        } else if (existingFilterItem) {
            this.removeSelectedFilter(existingFilterItem);
        } else {
            this.valueService.addSelectedItem(value);
        }
    }

    filterSelectList() {
        this.filteredPanelData = [];
        this.panelData.forEach((valueModel) => {
            if (valueModel.display.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) {
                this.filteredPanelData.push(valueModel);
                //this.filteredPanelDataSubject.next(valueModel);
            }
        });
        setTimeout(() => {
            this.selected.forEach((value) => {
                const scopedOption = this.panelDataSelectList.options.filter(o => o.value.value === value.value)[0];
                if (scopedOption) {
                    scopedOption.selected = true;
                }
            });
        }, 1);

        this.checkBoxHeaderLabelSuffix = this.filteredPanelData.length > 1 ? '\s' : '';
        this.checkBoxHeaderLabel = `${this.filteredPanelData.length} ${this.filterPanelRef.config.panelTitle}${this.checkBoxHeaderLabelSuffix}`;

        this.filteredPanelDataSubject.next(this.filteredPanelData);
    }

    removeSelectedFilter(filterValue: FilterPanelTypes.SimpleValue) {
        this.valueService.removeSelectedItem(filterValue);
    }

    handleSingleSelectList(newFilterValue: FilterPanelTypes.SimpleValue, existingFilterValues: FilterPanelTypes.SimpleValue[]): void {
        this.valueService.addSelectedItem(newFilterValue);

        existingFilterValues.forEach(
            existingFilter => this.handleRemoveSelectedFilter(existingFilter)
        );
    }

    handleRemoveSelectedFilter(filterValue: FilterPanelTypes.SimpleValue): void {
        const scopedOption = this.panelDataSelectList.selectedOptions.selected.filter(o => o.value.value === filterValue.value)[0];
        //scopedOption.selected = false;
        this.panelDataSelectList.selectedOptions.deselect(scopedOption);
        this.removeSelectedFilter(filterValue);
    }

    headerCheckboxChanged($event: any): void { // MatCheckboxChange
        if ($event.checked) {
            this.addUnselectedItemsToSelectedList();
            this.panelDataSelectList.selectAll();
        } else {
            if (this.panelData.length === this.filteredPanelData.length) {
                // we are not filtered so de-select all
                this.deselectAll();
            } else {
                this.filteredPanelData.forEach((value) => {
                    this.removeSelectedFilter(value);
                });
            }

        }

    }

    private deselectAll() {
        this.valueService.deselectAllSelectedItems();
    }

    private clearSelectList() {
        if (this.panelDataSelectList && this.panelDataSelectList.selectedOptions.selected.length) {
            this.panelDataSelectList.deselectAll();
        }
    }

    private addUnselectedItemsToSelectedList(): void {
        this.filteredPanelData.forEach((item) => {
            const selectedItem = this.selected.filter(s => s.value === item.value)[0];
            if (!selectedItem) {
                this.valueService.addSelectedItem(item);
            }
        });
    }

    private isValidKey(event: KeyboardEvent): boolean {
        return event.keyCode !== 13
            && event.keyCode !== 27
            && event.code !== 'Enter'
            && event.code !== 'Escape'
            && event.key !== 'Enter'
            && event.key !== 'Escape';
    }

    private setSelectionState(currentFilterValues: FilterPanelTypes.SimpleValue[]) {
        this.valueService.setSelectedItems(currentFilterValues);
        currentFilterValues.forEach((value) => {
            const option = this.panelDataSelectList.options.filter(o => o.value.value === value.value)[0];
            if (option) {
                option.selected = true;
            }
        });
    }
}
