import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilterState, ReportViewFilterSelection, buildReportViewFilterSelection } from './filter-state.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { iif, of } from 'rxjs';
import { switchMap, mergeMap, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { ConfigurationService } from '../../services/services-index';
import { FilterBarService } from '../filter-bar.service';
import { FilterActions } from './action-types';
import * as FilterModels from '../filter.model';
import { FilterSelectors } from './selector-types';
import { FilterDateService } from '../filter-date.service';
import { FacetedFilterResultBuilder } from '../../hy-filter-panel-module/Utils';
import { ReportRequestModel } from '../../models/models-index';
import { ReportsService } from '../../services/api/api-index';
import { AppSelectors } from '../../../_store/selector-types';

@Injectable()
export class FilterEffects {

  constructor(
    private store$: Store<FilterState>,
    private actions$: Actions,
    private filterBarService: FilterBarService,
    private filterDateService: FilterDateService,
    private configService: ConfigurationService,
    private reportService: ReportsService,
    private router: Router) { }

  initializeReportFilters$ = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.initializeFilters),
    exhaustMap(action => of(action).pipe(
      switchMap(() => this.filterBarService.getInitialReportFilters(action.reportName).pipe(
        map(({ filters, lockedFilters }) => FilterActions.initializeFiltersSuccess({ reportName: action.reportName, filters, lockedFilters }))
      ))
    ))
  ));

  removeFilter = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.removeFilter),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectCurrentReportFilters),
        this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action, reportFilters, filterState]) => {
      const reportName = action.reportName;
      const newFilters = reportFilters.filters.filter(f => f.type !== action.filterType);
      const lockedFilters = filterState.lockedFilters.filter(f => f.type !== action.filterType);

      return FilterActions.removeFilterSuccess({ reportName, filters: newFilters, lockedFilters });
    })
  ));

  toggleFilterLock = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.toggleFilterLock, FilterActions.toggleDateFilterLock),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action, filterState]) => {
      const newFilter = { ...action.filter };

      newFilter.locked = !newFilter.locked;

      const newState = { ...filterState };

      // set locked filters state
      if (newFilter.locked) {
        // take out of the explicitUnlockedFilters
        newState.explicitUnlockedFilters = [...newState.explicitUnlockedFilters.filter(f => f !== newFilter.type)];
        newState.lockedFilters = [...newState.lockedFilters, newFilter];
      } else {
        newState.explicitUnlockedFilters = [...newState.explicitUnlockedFilters, newFilter.type];
        newState.lockedFilters = newState.lockedFilters.filter(lf => lf.type !== newFilter.type);
      }

      // set report filters state
      newState.filters.forEach(reportFilters => {
        const filters = reportFilters.filters;
        const currentFilter = filters.find(f => f.type === newFilter.type);

        const newFilters = [...filters];

        if (currentFilter) {
          // the filter already exists on the report and we're just replacing it
          const indexOfCurrentFilter = filters.indexOf(currentFilter);
          newFilters[indexOfCurrentFilter] = newFilter;
        } else if (newFilter.locked) {
          // the filter doesn't already exist on the report and we need to see if it can, if so, we need to add it
          if (this.configService.filter.filterConfig.filterReportConfigs[reportFilters.reportName].filters.includes(newFilter.type)) {
            // we need to arbitrarily add the filter
            newFilters.push(newFilter);
          }
        }

        const newReportFilters = [...newState.filters];

        newReportFilters.splice(
          newReportFilters.findIndex(rf => rf.reportName === reportFilters.reportName),
          1,
          { reportName: reportFilters.reportName, filters: newFilters }
        );

        newState.filters = newReportFilters;
      });

      return FilterActions.toggleFilterLockSuccess({ filterState: newState });
    })
  ));

  updateCurrentReportFilterSelected = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.updateCurrentReportFilterSelected),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState), this.filterDateService.dateRanges$)
    )),
    map(([action, state, dateRanges]) => {
      const newFilterState = { ...state };

      // we need to generate a fresh filter based on the new selected values
      // we first want to see if there's a locked filter and if so, update its selected
      // this also makes the assumption that since it's a locked filter, it's already present on all supported reports
      const existingLocked = newFilterState.lockedFilters.find(f => f.type === action.filterType);

      if (existingLocked) {
        const newExistingLockedFilter = {...existingLocked, selected: action.selected};

        newFilterState.lockedFilters = [...newFilterState.lockedFilters.filter(f => f.type !== action.filterType), newExistingLockedFilter];
      } else {
        // we first want to see if the report has the filter in question already
        // if not, we'll get it from defaults and add it
        const reportFilters = newFilterState.filters.find(f => f.reportName === action.reportName);
        const existingReportFilter = reportFilters.filters.find(f => f.type === action.filterType);
        const filterIndex = existingReportFilter ? reportFilters.filters.indexOf(existingReportFilter) : -1;

        let newOrUpdatedFilter: FilterModels.Filter;

        // TODO Cleanup
        if (existingReportFilter) {
          newOrUpdatedFilter = { ...existingReportFilter, selected: action.selected };
        } else {
          // get default filter state, set selected, and add it to the report
          const defaultFilter = { ...this.configService.filter.filterConfig.FILTER_CONFIG[action.filterType] };

          if (!defaultFilter) {
            throw new Error(`Unable to get default filter for ('${action.filterType}'). Did you setup FILTER_CONFIG correctly?`);
          }

          newOrUpdatedFilter = { ...defaultFilter, selected: action.selected };
        }

        let updatedFilters: FilterModels.Filter[] = [];

        if (filterIndex >= 0) {
          updatedFilters = [...reportFilters.filters];
          updatedFilters[filterIndex] = newOrUpdatedFilter;
        } else {
          updatedFilters = [...reportFilters.filters, newOrUpdatedFilter];
        }

        // update report filters
        const newReportFilters = [...newFilterState.filters];

        newReportFilters.splice(
          newFilterState.filters.findIndex(f => f.reportName === reportFilters.reportName),
          1,
          { reportName: reportFilters.reportName, filters: updatedFilters }
        );
        newFilterState.filters = newReportFilters;
      }

      return FilterActions.updateCurrentReportFilterSelectedSuccess({ filterState: newFilterState });
    })
  ));

/*
  *** v5.1 Effects ***
  Report Lifecycle: ReportViewComponent -> updateReportViewFilterConfiguration -> initializeReportViewFilters
  Ultimately, when we want to load a repot, we retrieve the report config from the api, then initialize the filters we need
  Our goal is to not load things we don't need to or persist things that haven't changed
*/

  updateReportViewFilterConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.updateReportViewFilterConfiguration),
    mergeMap(action => of(action).pipe(
      withLatestFrom(
        this.store$.select(FilterSelectors.selectFilterState),
        this.store$.select(AppSelectors.selectLocale))
    )),
    map(([action, state, locale]) => {
      //load all report view filters to ensure we handle translations for all filters;
      return FilterActions.loadReportViewFilters({ config: action.config, filtersToLoad: action.config.filterNames, locale });
    })));

  loadReportViewFilters$ = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.loadReportViewFilters),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectReportViewFilters)),
    switchMap(([action,filters]) => this.reportService.getReportFilters(action.config.reportName, action.filtersToLoad, action.locale).pipe(
        map(newFilters => {
          // we need to merge the new filters with the existing filters but we need to make sure we don't duplicate any and replace any that are already there
          const allFilters = [...filters];
          newFilters.forEach(newFilter => {
            const existingFilterIndex = allFilters.findIndex(f => f.name === newFilter.name);
            if (existingFilterIndex >= 0) {
              allFilters[existingFilterIndex] = newFilter;
            } else {
              allFilters.push(newFilter);
            }
          });

          // const allFilters = [...filters, ...newFilters];
          return FilterActions.initializeReportViewFilters({ config: action.config, filters: allFilters })
        })
      )
    )))));


  initializeReportViewFilters$ = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.initializeReportViewFilters),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action,state]) => {
      const {config,filters} = action;
      const lockedReportViewFilters = [...state.lockedReportViewFilters ?? []];
      const reportViewFilterSelections = [...state.reportViewFilterSelections ?? []];

      const existingReportFilterSelection = reportViewFilterSelections.find(f => f.reportName === config.reportName);

      // if the report view filters are already initialized, we don't need to do anything
      if (existingReportFilterSelection && existingReportFilterSelection.selections.length > 0) {
        return FilterActions.initializeReportViewFiltersSuccess({
          config,
          filters: filters,
          selections: state.reportViewFilterSelections.find(f => f.reportName === config.reportName).selections,
          lockedFilters: lockedReportViewFilters
        });
      }

      // we need to determine if there are filters incoming that should be locked and already in the set of locked report view filters
      // if so, we need to update the selected values
      // note, we don't care about explicit unlocked anymore or need to consider it here
      const selectedReportViewFilters = [...(reportViewFilterSelections.find(f => f.reportName === config.reportName)?.selections ?? [])];

      action.filters.forEach(filter => {
        // first check to see if we even care about the filter being evaluated for this report
        if (!config.filterNames.includes(filter.name)) {
          return;
        }

        if (filter.locked && lockedReportViewFilters.findIndex(f => f.name === filter.name) < 0) {
          // assert that if it's a locked filter, it needs a default selected value
          if (!filter.defaults) {
            throw new Error(`Unable to initialize report view filter ('${filter.name}') as it's locked but has no default selected value.`);
          }

          const selected = FacetedFilterResultBuilder.buildSelectionResult(filter.defaults);

          const newLockedSelected = buildReportViewFilterSelection(filter.name, filter.label, selected, true, true, filter.removeable, filter.visible );

          lockedReportViewFilters.push(newLockedSelected);
          selectedReportViewFilters.push(newLockedSelected);
        } else if (lockedReportViewFilters.findIndex(f => f.name === filter.name) >= 0) {
          // if the filter is in a locked state because it's in the locked report filters and the report doesn't have a selection for it, we need to add it based on the locked filter
          // otherwise, if the report already has a selection for it, we don't need to do anything
          const existingLockedFilter = lockedReportViewFilters.find(f => f.name === filter.name);

          if (existingLockedFilter) {
            selectedReportViewFilters.push(existingLockedFilter);
          }
        } else {
          // if the filter isn't locked, we need to see if it's already in the selected report view filters and if it isn't and has a default, we need to add it
          const existingSelectedFilter = selectedReportViewFilters.find(f => f.name === filter.name);

          if (!existingSelectedFilter && filter.defaults) {
            const selected = FacetedFilterResultBuilder.buildSelectionResult(filter.defaults);

            selectedReportViewFilters.push(buildReportViewFilterSelection(filter.name, filter.label, selected, filter.locked, filter.lockable, filter.removeable, filter.visible ));
          }
        }
      });


      // we need to example the incoming filters and see if they have defaults and if so, add them to the report view selected filters
      return FilterActions.initializeReportViewFiltersSuccess({
        config,
        filters: filters,
        selections: selectedReportViewFilters,
        lockedFilters: lockedReportViewFilters
       });
    }))
  );

  updateReportViewFilterSelections$ = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.updateReportViewFilterSelections),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action, state]) => {
      const reportName = action.reportName;
      const lockedReportViewFilters = [...state.lockedReportViewFilters];
      const newSelections = [];

      // we need to evaluate how to handle locked filters here

      action.selections.forEach(selection => {
        const existingLockedFilterIndex = lockedReportViewFilters.findIndex(f => f.name === selection.name);
        const existingLockedFilter = lockedReportViewFilters[existingLockedFilterIndex];
        if (selection.locked && existingLockedFilter) {
          lockedReportViewFilters[existingLockedFilterIndex] = {...existingLockedFilter, item: selection.item};
        } else if (!selection.locked && existingLockedFilter) {
          // if the selection isn't locked but exists in the locked report view filters, we need to remove it
          lockedReportViewFilters.splice(existingLockedFilterIndex, 1);
        } else if (selection.locked && !existingLockedFilter) {
          // if the selection is locked and doesn't exist in the locked report view filters, we need to add it
          lockedReportViewFilters.push({ name: selection.name, label: selection.label, item: selection.item, locked: selection.locked, lockable: selection.lockable, removeable: selection.removeable, visible: selection.visible });
        }

        newSelections.push(selection);
      });

      // based on the updated locked filters and the selections specific to this report, we need to update all reports
      const updatedReportViewFilterSelections = [];

      state.reportViewFilterSelections.forEach(f => {
        // check the selections on the report to see if there are selected that are in locked filters and if so, update them based on the lock
        // we also check to see if the report we're evaluating is the one being requested of and if so, use the selections that came through the action
        const currentReportViewFilters = f.reportName === reportName ? action.selections : f.selections;

        const updatedReportViewFilters = [];

        currentReportViewFilters.forEach(filter => {
          const lockedFilter = lockedReportViewFilters.find(lf => lf.name === filter.name);

          if (lockedFilter) {
            updatedReportViewFilters.push(lockedFilter);
          } else {
            updatedReportViewFilters.push({...filter, locked: false});
          }
        });

        updatedReportViewFilterSelections.push({ reportName: f.reportName, selections: updatedReportViewFilters });
      });

      const newFilterState = { ...state, reportViewFilterSelections: updatedReportViewFilterSelections, lockedReportViewFilters };

      return FilterActions.updateReportViewFilterSelectionsSuccess({
        newFilterState
       });
    }))
  );

  updateReportViewFilterSelection$ = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.updateReportViewFilterSelection),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action, state]) => {
      // get existing selections for the report and replace the one that's being updated
      // then just dispatch the updateReportViewFilterSelections action...easy peasy
      const reportName = action.reportName;
      const reportViewFilters = state.reportViewFilterSelections.find(f => f.reportName === reportName);
      const newSelections = [];

      reportViewFilters.selections.forEach(selection => {
        if (selection.name === action.selection.name) {
          newSelections.push(action.selection);
        } else {
          newSelections.push(selection);
        }
      });

      return FilterActions.updateReportViewFilterSelections({ reportName, selections: newSelections });
    }))
  );

  resetReportViewFilters$ = createEffect(() => this.actions$.pipe(
    ofType(FilterActions.resetReportViewFilters),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action, state]) => {
      const reportName = action.reportName;
      const reportViewFilterConfiguration = state.reportViewFilterConfigurations.find(rc => rc.reportName === reportName);
      // based on the filterNames in the config, get the reportViewFilters from state because they should exist already if we're reseting
      const reportViewFilters = state.reportViewFilters.filter(f => reportViewFilterConfiguration.filterNames.includes(f.name));
      const lockedReportViewFilters: ReportViewFilterSelection[] = [];
      const newSelections: ReportViewFilterSelection[] = [];

      reportViewFilters.forEach(filter => {

        if (filter.locked && !filter.defaults) {
          throw new Error(`Unable to reset report view filter ('${filter.name}') as it's locked but has no default selected value.`);
        }
        if (!filter.defaults)
          return;

        const selected = FacetedFilterResultBuilder.buildSelectionResult(filter.defaults);
        if (filter.locked) {
          lockedReportViewFilters.push({ name: filter.name, label: filter.label, item: selected, locked: filter.locked, lockable: filter.lockable, removeable: filter.removeable, visible: filter.visible });
        }

        newSelections.push({ name: filter.name, label: filter.label, item: selected, locked: filter.locked, lockable: filter.lockable, removeable: filter.removeable, visible: filter.visible });
      });

      return FilterActions.resetReportViewFiltersSuccess({
        reportName,
        selections: newSelections,
        lockedSelections: lockedReportViewFilters
       });
    })));

    getInitialRequestModel(reportName: string): ReportRequestModel {
      return {
        orderBy: '',
        orderAscending: false,
        reportType: reportName,
        filters: {
          startDate: new Date(),
          endDate: new Date()
        }
      };
    }
}
