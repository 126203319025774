import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
/*
The sidebar in this application essentially has 3 modes, 2 for desktop, and one for mobile.
1. By default the sidebar will be compact, showing only the icons, and sliding out on hover.
2. Can be toggled to remain open.
3. For mobile, the sidebar will be completely hidden, but can be toggled open and closed.
If toggled, the sidebar should remain expanded; ignore mouse-over effects.
*/
let stickySidebar = false;

/**
* Allows the sidebar to be toggled via click.
*/
@Directive({
  selector: '[appSidebarToggler]'
})
export class SidebarToggleDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    stickySidebar = !stickySidebar;
    if (stickySidebar) {
      // why even use these here it is in the template just fine why are we slapping it on the body...JMSNOTE
      //document.querySelector('body').classList.add('sidebar-toggled');
      //document.querySelector('body').classList.remove('sidebar-compact');
    } else {
      //document.querySelector('body').classList.remove('sidebar-toggled');
      //document.querySelector('body').classList.add('sidebar-compact');
    }
  }
}

@Directive({
  selector: '[sideBarClicker]'
})
export class SidebarHoverDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    if (!stickySidebar) {
      document.querySelector('body').classList.remove('sidebar-compact');
    }
  }

  @HostListener('click', ['$event'])
  toggleClose($event: any) {
    $event.preventDefault();
    if (!stickySidebar) {
      document.querySelector('body').classList.add('sidebar-compact');
    }
  }

  // We want to un-hover/collapse the menu if they click on a navigation item
  @HostListener('click', ['$event'])
  clicked($event: any) {
    $event.preventDefault();
    if (!stickySidebar) {
      document.querySelector('body').classList.add('sidebar-compact');
    }
  }
}
@Directive({
  selector: '[appSidebarItemHover]'
})
export class SidebarItemHoverDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  @HostListener('mouseenter', ['$event'])
  // Toggle element class
  private toggleClose() {
    var elementClassName = 'sidebar-compact';
    this.renderer.removeClass(this.el.nativeElement, elementClassName);
  }

  @HostListener('mouseleave', ['$event'])
  toggleOpen() {
    var elementClassName = 'sidebar-compact';
    this.renderer.addClass(this.el.nativeElement, elementClassName);
  }

  // We want to un-hover/collapse the menu if they click on a navigation item
  @HostListener('click', ['$event'])
  clicked($event: any) {
    $event.preventDefault();
    var elementClassName = 'sidebar-compact';
    this.renderer.addClass(this.el.nativeElement, elementClassName);
  }
}

@Directive({
  selector: '[appMobileSidebarToggler]'
})
export class MobileSidebarToggleDirective {
  constructor() { }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-mobile-show');

    // ensure conflicting compact (desktop) view isn't enabled
    document.querySelector('body').classList.remove('sidebar-compact');
  }
}

@Directive({
  selector: '[appSidebarMinimizer]'
})
export class SidebarMinimizeDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-minimized');
  }
}

@Directive({
  selector: '[appSidebarCompactizer]'
})
export class SidebarCompactizerDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-compact');

    // ensure conflicting mobile view isn't enabled
    document.querySelector('body').classList.remove('sidebar-mobile-show');
  }
}

/**
* Allows the off-canvas sidebar to be closed via click.
*/
@Directive({
  selector: '[appSidebarClose]'
})
export class SidebarOffCanvasCloseDirective {
  constructor() { }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  // Toggle element class
  private toggleClass(elem: any, elementClassName: string) {
    let newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
    if (this.hasClass(elem, elementClassName)) {
      while (newClass.indexOf(' ' + elementClassName + ' ') >= 0) {
        newClass = newClass.replace(' ' + elementClassName + ' ', ' ');
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, '');
    } else {
      elem.className += ' ' + elementClassName;
    }
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();

    if (this.hasClass(document.querySelector('body'), 'sidebar-off-canvas')) {
      this.toggleClass(document.querySelector('body'), 'sidebar-opened');
    }
  }
}

export const SIDEBAR_TOGGLE_DIRECTIVES = [
  SidebarToggleDirective,
  SidebarMinimizeDirective,
  SidebarOffCanvasCloseDirective,
  MobileSidebarToggleDirective,
  SidebarCompactizerDirective,
  SidebarHoverDirective,
  SidebarItemHoverDirective
];
