export const formatKeys = {
    abbreviatedLocaleString: 'abbreviatedLocaleString',
    currency: 'currency',
    currencyNoCents: 'currencyNoCents',
    date: 'date',
    dateTime: 'dateTime',
    dateTimeToMinute: 'dateTimeToMinute',
    decimalToPercentTwoDecimals: 'decimalToPercentTwoDecimals',
    entityDisplayName: 'entityDisplayName',
    entityDisplayNameNormalCase: 'entityDisplayNameNormalCase',
    integerDaysOrNa: 'integerDaysOrNa',
    positiveIntegerDaysOrNa: 'positiveIntegerDaysOrNa',
    oneDecimalDaysOrNa: 'onecimalDaysOrNa',
    hoursTimeStringFromMinutes: 'hoursTimeStringFromMinutes',
    hourMinuteString : 'hourminutestring',
    hourMinuteStringOrNa : 'hourminutestringOrNa',
    integer: 'integer',
    localeString: 'localeString',
    localeStringOrNa: 'localeStringOrNa',
    minutesTimeString: 'minutesTimeString',
    minutesTimeStringOrNa: 'minutesTimeStringOrNa',
    minutesTimeStringFromSeconds: 'minutesTimeStringFromSeconds',
    minutesTimeStringFromSecondsOrNa: 'minutesTimeStringFromSecondsOrNa',
    percent: 'percent',
    percentage: 'percentage',
    percentageNoDecimals: 'percentageNoDecimals',
    percentageOneDecimal: 'percentageOneDecimal',
    percentageTwoDecimals: 'percentageTwoDecimals',
    percentageTwoDecimalsOrNa: 'percentageTwoDecimalsOrNa',
    roundNumber: 'roundNumber',
    roundToHundreth: 'roundToHundredth',
    roundToThousandths: 'roundToThousandths',
    roundToInteger: 'roundToInteger',
    roundToIntegerLocaleString: 'roundToIntegerLocaleString',
    roundToTenth: 'roundToTenth',
    stringOrNa: 'stringOrNa'
};

export const helpTextKeys = {
    // Lead Overview
    leadsOverviewLeadVolumeBillboardHelp: 'LeadsOverviewLeadVolumeBillboardHelp',
    leadsOverviewResponded60BillboardHelp: 'LeadsOverviewResponded60BillboardHelp',
    leadsOverviewAverageResponseTimeBillboardHelp: 'LeadsOverviewAverageResponseTimeBillboardHelp',
    leadsOverviewCloseRateBillboardHelp: 'LeadsOverviewCloseRateBillboardHelp',
    leadsOverviewRespondseRate30BillboardHelp: 'LeadsOverviewAverageResponseRateRateBillboardHelp',
    // Provider Tools
    // Chat
    chatSummaryHelp: 'ChatSummaryHelp',
    // Reputation Management
    reputationManagementOverviewSummaryHelp: 'ReputationManagementDealerSummaryHelp',
    reputationManagementOverviewAverageRatingBillboardHelp: 'ReputationManagementOverviewAverageRatingBillboardHelp',
    reputationManagementOverviewTotalPositiveReviewsBillboardHelp: 'ReputationManagementOverviewTotalPositiveReviewsBillboardHelp',
    reputationManagementOverviewTotalNegativeReviewsBillboardHelp: 'ReputationManagementOverviewTotalNegativeReviewsBillboardHelp',
    reputationManagementOverviewTotalReviewsBillboardHelp: 'ReputationManagementOverviewTotalReviewsBillboardHelp',
    reputationManagementOverviewTotalDealerResponsesBillboardHelp: 'ReputationManagementOverviewTotalDealerResponsesBillboardHelp',
    // Social Media
    socialMediaOverviewSummaryHelp: 'SocialMediaDealerSummaryHelp',
    socialMediaOverviewTotalFansFollowersBillboardHelp: 'SocialMediaOverviewTotalFansFollowersBillboardHelp',
    socialMediaOverviewTotalPostsBillboardHelp: 'SocialMediaOverviewTotalPostsBillboardHelp',
    socialMediaOverviewTotalReachBillboardHelp: 'SocialMediaOverviewTotalReachBillboardHelp',
    socialMediaOverviewTotalEngagementBillboardHelp: 'SocialMediaOverviewTotalEngagementBillboardHelp',
    socialMediaOverviewTotalAdvocacyBillboardHelp: 'SocialMediaOverviewTotalAdvocacyBillboardHelp',


    // website overview billboard
    websiteOverviewActionsBillboardHelp: 'WebsiteOverviewActionsBillboardHelp',
    websiteOverviewEngagementsBillboardHelp: 'WebsiteOverviewEngagementsBillboardHelp',
    websiteOverviewUniqueVisitsBillboardHelp: 'WebsiteOverviewUniqueVisitsBillboardHelp',
    websiteOverviewSalesSessionsConversionRateBillboardHelp:  'WebsiteOverviewSalesSessionsConversionRateBillboardHelp',
    websiteOverviewServiceSessionsConversionRateBillboardHelp: 'WebsiteOverviewServiceSessionsConversionRateBillboardHelp',
    websiteOverviewClickToCallsBillboardHelp: 'WebsiteOverviewClickToCallsBillboardHelp',
    websiteOverviewNewVDPViewsBillboardHelp: 'WebsiteOverviewNewVDPViewsBillboardHelp',
    websiteOverviewCPOVDPViewsBillboardHelp: 'WebsiteOverviewCPOVDPViewsBillboardHelp',
    websiteOverviewVDPViewsBillboardHelp: 'WebsiteOverviewVDPViewsBillboardHelp',
    websiteOverviewVisitsBillboardHelp: 'WebsiteOverviewVisitsBillboardHelp',
    websiteOverviewBookingsCompletedBillboardHelp: 'WebsiteOverviewBookingsBillboardHelp',
    websiteOverviewBookedRoomRevenueBillboardHelp: 'WebsiteOverviewRevenueBillboardHelp',
    websiteOverviewSalesSessionsBillboardHelp: 'WebsiteOverviewSalesSessionsBillboardHelp',
    websiteOverviewServiceSessionsBillboardHelp: 'WebsiteOverviewServiceSessionsBillboardHelp',
    // website overview cards
    websiteOverviewBookingWindowHelp: 'websiteOverviewBookingWindowHelp',
    websiteOverviewBenchmarkHelp: 'WebsiteOverviewBenchmarkHelp',
    websiteOverviewReferrerQualityHelp: 'WebsiteOverviewReferrerQualityHelp',
    websiteOverviewReferrerTypeHelp: 'WebsiteOverviewReferrerTypeHelp',
    websiteOverviewTrafficByDeviceTypeHelp: 'WebsiteOverviewTrafficByDeviceHelp',
    websiteOverviewVisitorTrendHelp: 'WebsiteOverviewVisitorTrendHelp',
    // website reports
    WebsiteProviderSummaryHelp: 'WebsiteProviderSummaryHelp',
    WebsiteProviderScorecardHelp: 'WebsiteProviderScorecardHelp',
    websiteReferrerSummaryHelp: 'WebsiteReferrerSummaryHelp',
    websiteSummaryHelp: 'WebsiteSummaryHelp',
    websiteVdpSummaryHelp: 'WebsiteVdpSummaryHelp',
    // digad reports
    digAdSummaryHelp: 'DigAdSummaryHelp',
    digAdProviderSummaryHelp: 'DigAdProviderSummaryHelp',
    digAdChannelSummaryHelp: 'DigAdChannelSummaryHelp',
    // digad Overview Cards
    digAdOverviewProviderPerformanceHelp: 'DigAdOverviewProviderPerformanceHelp',
    digAdOverviewDealerPerformanceHelp: 'DigAdOverviewBenchmarkHelp',
    digAdOverviewTrafficTrendHelp: 'DigAdOverviewTrafficTrendHelp',
    digAdOverviewChannelPerformanceHelp: 'DigAdOverviewChannelHelp',
    digAdOverviewTrafficTypeHelp: 'DigAdOverviewOrganicVsPaidHelp',
};

export const reportNames = {
  leadsOverview: 'LeadsOverview',
  leadSummary: 'LeadSummary',
  leadSourcePerformance: 'LeadSourcePerformance',
  leadVehiclePerformance: 'LeadVehiclePerformance',
  leadEventPerformance: 'LeadEventPerformance',
  facebookPerformance: 'FacebookPerformance',
  processPerformance: 'ProcessPerformance',
  leadTransactions: 'LeadTransactions',
  salesOverview: 'SalesOverview',
  salesSummary: 'SalesSummary',
  salesVehicleSummary: 'SalesVehicleSummary',
  salesSourceSummary: 'SalesSourceSummary',
  salesForecastingSummary: 'SalesForecastingSummary',
  hospitalityWebsiteOverview: 'HospitalityWebsiteOverview',
  hospitalityWebsiteSummary: 'WebsiteSummary',
  digitalAdvertisingOverview: 'DigitalAdvertisingOverview',
  digitalAdvertisingDashboard: 'DigitalAdvertisingDashboard',
  digitalAdvertisingTier2Dashboard: 'DigitalAdvertisingTier2Dashboard',
  digitalAdvertisingScorecard: 'DigitalAdvertisingScorecard',
  digitalAdvertisingSummary: 'DigitalAdvertisingSummary',
  digitalAdvertisingChannel: 'DigitalAdvertisingChannel',
  digitalAdvertisingProvider: 'DigitalAdvertisingProvider',
  digitalAdvertisingProviderScorecard: 'DigitalAdvertisingProviderScorecard',
  digitalRetailingOverview: 'DigitalRetailingOverview',
  digitalRetailingDashboard: 'DigitalRetailingDashboard',
  websiteOverview: 'WebsiteOverview',
  websiteSummary: 'WebsiteSummary',
  websiteScorecard: 'WebsiteScorecard',
  websiteReferrerSummary: 'WebsiteReferrerSummary',
  websiteVehicleSummary: 'WebsiteVehicleSummary',
  websiteLandingPage: 'WebsiteLandingPage',
  websiteAfterSales: 'WebsiteAfterSales',
  websiteCertifiedChat: 'WebsiteCertifiedChat',
  websiteProviderSummary: 'WebsiteProviderSummary',
  websiteProviderScorecard: 'WebsiteProviderScorecard',
  inventoryOverview: 'InventoryOverview',
  tradeInOverview: 'TradeInOverview',
  tradeInProviderScorecard: 'TradeInProviderScorecard',
  serviceSchedulerOverview: 'ServiceSchedulerOverview',
  chatOverview: 'ChatOverview',
  chatSummary: 'ChatSummary',
  chatProviderScorecard: 'ChatProviderScorecard',
  socialMediaOverview: 'SocialMediaOverview',
  reputationManagementOverview: 'ReputationManagementOverview',
  programOverview: 'ProgramOverview',
};

export const orgDateCodes = {
  calendar: 1,
  sales: 2
};

export const orgHierarchyDepth = {
  national: 6,
  org5: 5,
  org4: 4,
  org3: 3,
  org2: 2,
  org1: 1,
  dealer: 0
}

export const processPerformanceDataSetKeys = {
  billboardMetric: 'billboardmetric',
  sourceTypeMetrics: 'sourcetypemetrics'
}

export const externalApps = {
  MysteryShop: 'mysteryshop',
  RetailerTools: 'retailertools',
  Harmony: 'harmony',
  MazdaForceMarketingSite: 'mazdaforcemarketingsite'
}
