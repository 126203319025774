import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Enums, IEnums } from '../../enums/enums';
import { FilterOptions, FilterActions } from '../../filter/store';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { Observable } from 'rxjs';

import { SharedHelpTextModalComponent } from '../../modals/modals-index';
import { DataTableService } from '../../services/services-index';

@Component({
    selector: 'sd-card-header',
    templateUrl: './card-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardHeaderComponent {
    @ViewChild('tableOptions', { static: true }) public ngSelect: MatSelect;
    @Input() title: string;
    @Input() iconClass: string;
    @Input() helpTextKey: string;
    @Input() helpTextTitle: string;
    @Input() exportName: string;
    @Input() metricDisplayModes: string[];
    @Input() locale: string;
    tableOptions$: Observable<FilterOptions>;
    selectedTableOptions: string[];
    enums: IEnums = Enums;
    isTrend = false;
    isComp = false;

    constructor(
        private dataTableService: DataTableService,
        private dialog: MatDialog,
        private store$: Store<AppState>
    ) {

    }
        // jmstodo: after talking with james:
        // break into separate components - an actual header that takes in templates - ng template --
        // source be several card header components
        // card template - takes in a header, takes in the card
        // don't change direction right now - can extract this to get it where we want -- BUT pass in the config properties one at a time
    // ngOnInit() {

    //     // this.tableOptions$ = this.store$.select(FilterSelectors.selectOptions).pipe(
    //     //     map(options => {
    //     //         const selectedOptions: string[] = [];

    //     //         if (options.includeMOM) {
    //     //             selectedOptions.push('MOM');
    //     //         }
    //     //         if (options.includeYOY) {
    //     //             selectedOptions.push('YOY');
    //     //         }
    //     //         if (options.includeTrend) {
    //     //             selectedOptions.push('Trend');
    //     //         }
    //     //         if (options.includeLookback) {
    //     //             selectedOptions.push('Lookback');
    //     //         }
    //     //         if (options.includeAdvanced) {
    //     //             selectedOptions.push('Advanced');
    //     //         }

    //     //         this.selectedTableOptions = selectedOptions;
    //     //         this.isComp = this.isCompMode(this.selectedTableOptions);
    //     //         this.isTrend = this.isTrendMode(this.selectedTableOptions);
    //     //         return options;
    //     //     }
    //     // )), takeUntilDestroyed(this.destroyRef);
    // }

    updateTableScenarios(change: MatSelectChange) {

        const isTrend = this.isTrendMode(change.value);

        const options: FilterOptions = {
            includeAdvanced: !isTrend ? change.value.includes('Advanced') : false,
            includeLookback: !isTrend ? change.value.includes('Lookback') : false,
            includeMOM: !isTrend ? change.value.includes('MOM') : false,
            includeYOY: !isTrend ? change.value.includes('YOY') : false,
            includeTrend: isTrend ? change.value.includes('Trend') : false
        };

        this.store$.dispatch(FilterActions.updateOptions({options}));
    }

    openHelpText(key: string, title: string): void {
        this.dialog.open(SharedHelpTextModalComponent, {
            width: '50vw',
            data: {
                title: title,
                name: key,
                locale: this.locale
            }
        });
    }

    isTrendMode(value: any) {
        return this.metricDisplayModes.includes('Trend') && value.includes('Trend');
    }

    isCompMode(value: any) {
        return value.includes('Advanced')
            || value.includes('Lookback')
            || value.includes('MOM')
            || value.includes('YOY');
    }

    printExcel(title: string, expandAll: boolean): void {
        this.dataTableService.printCurrentData({ title: title, expandAll: expandAll, printingOption: this.enums.printingOptions.excel });
    }

}
