import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'americandollars' })
export class SdCurrencyPipe implements PipeTransform {
  transform(val: any, numberOfFractionDigitals = 2): string {
    if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
      return 'N/A';
    } else {
      return '$'+(Math.round(val * 100) / 100).toLocaleString(undefined, {
        minimumFractionDigits: numberOfFractionDigitals,
        maximumFractionDigits: numberOfFractionDigitals
      });
    }
  }
}
