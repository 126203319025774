import { IDefaultTableColumnConfig, ISourceSummaryConfig, IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SourceSummaryConfig implements ISourceSummaryConfig {
    
    public reportTitle = 'Lead Source Performance';

    metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];
    
    useDefaultComparisonColumns = true;

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            // Leads
            {
                id: 1,
                displayName: 'Leads',
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Sales
            {
                id: 2,
                displayName: Enums.leadMetrics.leadSales.name,
                propertyName: 'bestMatchSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            // Close Rate
            {
                id: 3,
                displayName: Enums.leadMetrics.closeRate.name,
                propertyName: 'bestMatchCloseRate',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Close Rate
            {
                id: 4,
                displayName: 'Household Conversion',
                propertyName: 'householdCloseRate',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },            
            // Response Rate
            {
                id: 5,
                displayName: Enums.leadMetrics.responseRate.name,
                propertyName: 'responseRate',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Response Rate 30
            {
                id: 6,
                displayName: Enums.leadMetrics.responseRate30.name,
                propertyName: 'responseRate30',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Appointment Rate
            {
                id: 7,
                displayName: Enums.leadMetrics.appointmentRate.name,
                propertyName:  Enums.leadMetrics.appointmentRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },            
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: 2, //   Lead Sales (bestMatchSales)
        metricOneColorHexCode: '#414141',
        metricTwoColorHexCode: '#ababab'
    }

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, 
            clickable: true
        },
        // Leads
        {
            show: true,
            header: 'Leads',
            columnDef: 'leadVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
        },
        // Sales
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'bestMatchSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
        },
        // Close Rate
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'bestMatchCloseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        },
        // Close Rate
        {
            show: true,
            header: 'Household Conversion',
            columnDef: 'householdCloseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        },
        // Response Rate
        {
            show: true,
            header: 'Response Rate',
            columnDef: 'responseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Response Rate
        {
            show: true,
            header: 'Response Rate < 30 Min',
            columnDef: 'responseRate30',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Appointment Rate
        {
            show: true,
            header: 'Appointment Rate',
            columnDef: 'appointmentRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },        
    ];
}
