import { ConnectionPositionPair } from '@angular/cdk/overlay';


export const TopPosition: ConnectionPositionPair = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom'
}

export const RightPosition: ConnectionPositionPair = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top'
}

export const BottomPosition: ConnectionPositionPair = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
}

export const LeftPosition: ConnectionPositionPair = {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top'
}

export enum FilterPanelRenderMethods {
    template = 'template',
    component = 'component',
    text = 'text'
}

export enum FilterPanelCloseType {
    backdropClick = 'backdropClick',
    cancel = 'cancel',
    applied = 'applied'
}

export enum FilterPanelStates {
    open = 'open',
    closed = 'closed'
}

export enum SdFilterPanelInputType {
    list = 'list',
    collection = 'collection'
} 