import {
  IDefaultTableColumnConfig,
  IDigitalRetailingOverviewBillboardConfig,
  IUserActivityOverviewConfig,
  IDualAxisLineGraphConfig,
  IMultiLevelDualAxisLineGraphConfig,
  IAnalysisVisualizationHeaderConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';
import * as Models from '../../../../_shared/models/models-index';
export class UserActivityOverviewConfig implements IUserActivityOverviewConfig {
  summaryTableColumns: IDefaultTableColumnConfig[];
  reportTitle: string;
  useDefaultComparisonColumns: boolean;
  metricOneColorHexCode: string;
  metricTwoColorHexCode: string;
  public billboards: Models.BillboardConfigMap[] =[]
  summaryTableConfig: IAnalysisVisualizationHeaderConfig;

}
