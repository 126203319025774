import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as Models from '../../models/models-index';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  cardSelectSubject = new Subject<Models.IVisualizationPerspective>();
  cardSelect$ = this.cardSelectSubject.asObservable();

  cardDataSubject = new Subject<any>();
  cardData$ = this.cardDataSubject.asObservable();

  constructor() {

  }

  updatePerspective(perspective: Models.IVisualizationPerspective) {
    this.cardSelectSubject.next(perspective);
  }

  updateCardData(data: any, perspective: Models.IVisualizationPerspective) {
    this.cardDataSubject.next({
      data: data,
      perspective: perspective
    })
  }

}
