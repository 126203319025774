import {
    IDefaultTableColumnConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    IHospitalityWebsiteSummaryConfig,
    INameValuePair} from '../../../../_shared/models/models-index';

export class HospitalityWebsiteSummaryConfig implements IHospitalityWebsiteSummaryConfig {
    public reportTitle: string;
    public useDefaultComparisonColumns: boolean = true;
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public graphConfig: IDualAxisLineGraphConfig = null;
    public trendTableMetricKeys: string[] = [];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
    public metricDisplayModes: INameValuePair[] = [];
}
