import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ExpandableTable } from '../dataTable/expandableTable';

@Directive({
  selector: '[appToggleExpandoCard]'
})
export class ToggleExpandoCardDirective {
  @Input() card: any;
  @Input() scrollToElement: HTMLElement;
  @Input() expandtype: string;
  @Output() onExpandoClick = new EventEmitter<any>();

  constructor() { }

  @HostListener('click', ['$event'])
  clickEvent(event) {

    event.preventDefault();
    event.stopPropagation();

    if (this.expandtype === 'height') {
      if (this.card instanceof ExpandableTable) {
        this.card.toggleExpanded();
        this.scrollToElement.scrollIntoView();
      }
      else {
        
      }
    }
    else if (this.expandtype === 'overlay') {

    }
    else {
      // Let the component implement its own expando logic
      this.onExpandoClick.emit();
      window.setTimeout(() => {
        this.scrollToElement.scrollIntoView();
      }, 200)
    }
  }
}
