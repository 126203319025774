import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, exhaustMap, withLatestFrom, filter, tap } from 'rxjs/operators';
import * as Api from '../_shared/services/api/api-index';
import { AppActions } from './action-types';
import { ConfigurationService } from '../_shared/services/config/config.service';
import { LocaleService } from '../_shared/locale/locale.service';
import { of } from 'rxjs';
import { AppState } from './app-state.model';
import { Store } from '@ngrx/store';
import { AppSelectors } from './selector-types';
import { ModuleResolutionKind } from 'typescript';

@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private reportsService: Api.ReportsService,
    private configService: ConfigurationService,
    private localeService: LocaleService) {}

    loadConfig$ = createEffect(() => this.actions$.pipe(
      ofType(AppActions.loadConfig),
      switchMap(() => this.configService.getConfig().pipe(
        map(config => AppActions.loadConfigSuccess(config))
      ))
    ));

    
    login$ = createEffect(() => this.actions$.pipe(
      ofType(AppActions.login),
      switchMap(({ auth }) => this.configService.getConfig().pipe(
        map(config => AppActions.loginSuccess({auth, config}))
      ))
    ));
    
    locale$ = createEffect(() => this.actions$.pipe(
      ofType(AppActions.loginSuccess),
      mergeMap(action => of(action).pipe(
        withLatestFrom(this.store$.select(AppSelectors.selectLocaleState)),
        map(([auth, localeState]) => {
          return this.localeService.getInitialLocale(localeState.locale, auth.auth.locale)
        }),
      )),
      map(initialLocale => AppActions.loadLocaleSuccess(initialLocale))
    ));
    
    localeRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(AppActions.loadConfig),
      mergeMap(action => of(action).pipe(
        withLatestFrom(this.store$.select(AppSelectors.selectLocaleState))
      )),
      //filter(l => !!l),
      map(([action, localeState]) => {
        const existing = {
          locale: localeState.locale,
          userDefaultLocale: localeState.userDefaultLocale
        };
        // we need to refresh the registered locale when the app is opened in new window
        this.localeService.getInitialLocale(existing.locale, existing.userDefaultLocale);
        return AppActions.initializeLocale(existing);
      })
    ));

    
    updateLocale$ = createEffect(() => this.actions$.pipe(
      ofType(AppActions.updateLocale),
      mergeMap(action => of(action).pipe(
        withLatestFrom(this.store$.select(AppSelectors.selectLocaleState))
      )),
      map(([action, localeState]) => {
        const newState = {
          locale: action.locale,
          userDefaultLocale: localeState.userDefaultLocale
        };
        return AppActions.updateLocaleSuccess(newState);
      })
    ));
}
