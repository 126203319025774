import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class WebsiteLandingPageService {

    constructor(private http: HttpClient) { }

    getWebsiteLandingPageGraphMetrics(reportRequest: any, reportType: string): Observable<Models.WebsiteLandingPageGraphResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.WebsiteLandingPageGraphResponseModel) => {
                    return <Models.WebsiteLandingPageGraphResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    getWebsiteLandingPageTableMetrics(reportRequest: any, reportType: string): Observable<Models.WebsiteLandingPageTableResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.WebsiteLandingPageTableResponseModel) => {
                    return <Models.WebsiteLandingPageTableResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    getWebsiteLandingPageLmaTableMetrics(reportRequest: any, reportType: string): Observable<Models.WebsiteLandingPageTableResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.WebsiteLandingPageTableResponseModel) => {
                    return <Models.WebsiteLandingPageTableResponseModel>response;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse): Observable<any> {
        return of([]);
    }
}
