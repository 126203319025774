
import {
    IDefaultTableColumnConfig,
    IWebsiteCertifiedChatConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';

export class WebsiteCertifiedChatConfig implements IWebsiteCertifiedChatConfig {

    public graphConfig: IDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
