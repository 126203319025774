export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'NCI',
  hostName: 'insight.nissanleads.ca',  
  environmentName: 'production',
  appName: 'NCI Insight',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '5fa3e663-53ba-4bb6-a3e6-8da57726e065'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#414141', '#ababab', '#000', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://nci-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://nci-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://nci-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/nci/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['DigitalRetailingOverview']
};
