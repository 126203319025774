import { IDefaultTableColumnConfig, IDigitalRetailingInStoreConfig, IMultiLevelDualAxisLineGraphConfig,IDualAxisLineGraphConfig, INameValuePair } from '../../../../../_shared/models/models-index';

export class DigitalRetailingInStoreConfig implements IDigitalRetailingInStoreConfig {
  summaryTableColumns: IDefaultTableColumnConfig[];
  trendTableMetrics: INameValuePair[];
  reportTitle: string;
  useDefaultComparisonColumns: boolean;
  trendGraphConfig: IDualAxisLineGraphConfig;
  metricDisplayModes: INameValuePair[];
}
