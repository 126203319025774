<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{labels.mySettings}} - {{user.name}}</h4>
        </div>
        <div class="modal-body">
            <form #userSettings="ngForm" [formGroup]="userSettingsForm" autocomplete="off" novalidate>
                <div class="container-fluid details-edit">
                    <div class="form-group row">
                        <div class="col-md-3 col-labels">
                            <div class="row"><div class="col-md-12"><label class="field-label">{{labels.email}}</label></div></div>
                            <div class="row"><div class="col-md-12"><label class="field-label">{{labels.firstName}}</label></div></div>
                            <div class="row"><div class="col-md-12"><label class="field-label">{{labels.lastName}}</label></div></div>
                            <div class="row"><div class="col-md-12"><label class="field-label">&nbsp;</label></div></div>
                            <div class="row"><div class="col-md-12"><label class="field-label">{{labels.currentPassword}}</label></div></div>
                            <div class="row"><div class="col-md-12"><label class="field-label">{{labels.newPassword}}</label></div></div>
                            <div class="row"><div class="col-md-12"><label class="field-label">{{labels.confirmPassword}}</label></div></div>
                        </div>
                        <div class="col-md-6 col-values">
                            <div class="row">
                                <div class="col-md-12">
                                    <input class="form-control form-control-sm" formControlName="userName" [ngModel]="user?.userName"
                                           placeholder={{labels.email}}
                                           readonly />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <input type="text" class="form-control form-control-sm" formControlName="firstName"
                                           [(ngModel)]="user.firstName" placeholder={{labels.firstName}}
                                           data-z-validate />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <input type="text" class="form-control form-control-sm" formControlName="lastName"
                                           [(ngModel)]="user.lastName" placeholder={{labels.lastName}}
                                           data-z-validate />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <input formControlName="userCurrentPassword" class="form-control form-control-sm"
                                           name="currentPass" id="currentPass"
                                           type="password"
                                           [(ngModel)]="currentPassword" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" [ngClass]="{ 'error': userNewPassword.invalid && userNewPassword.dirty }">
                                    <input type="password" class="form-control form-control-sm" formControlName="userNewPassword"
                                           [(ngModel)]="password"
                                           [required]="isNew" />
                                    <em *ngIf="userNewPassword.invalid && (userNewPassword.dirty ||userNewPassword.touched) && userNewPassword?.errors.minlength">{{labels.passwordLengthWarning}}</em>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" [ngClass]="{ 'error': userConfirmNewPassword.invalid && userConfirmNewPassword.dirty }">
                                    <input type="password" class="form-control form-control-sm" formControlName="userConfirmNewPassword"
                                           [(ngModel)]="confirmPassword"
                                           [required]="userNewPassword.dirty" />
                                    <em *ngIf="userConfirmNewPassword.invalid && userConfirmNewPassword.touched && userConfirmNewPassword?.errors.minlength">{{labels.passwordLengthWarning}}</em>
                                    <em *ngIf="userConfirmNewPassword.invalid && (userConfirmNewPassword.dirty || userConfirmNewPassword.touched) && userConfirmNewPassword?.errors.unmatchedpassword">{{labels.passwordMatchWarning}}</em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-w-md btn-gap btn-primary btn-sm" (click)="save()" [disabled]="!userSettings.form.valid">{{labels.save}}</button>
            <button type="button" class="btn btn-w-md btn-gap btn-primary btn-sm" (click)="cancel()">{{labels.cancel}}</button>
        </div>
    </div>
</div>
