import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {

    public metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' },
        { name: 'Trend', value: 'Trend' },
    ];
    useDefaultComparisonColumns: boolean = true;

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: 'Total Sales Leads',
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.responseRate.metricId,
                displayName: 'Response Rate',
                propertyName: Enums.leadMetrics.responseRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate30.metricId,
                displayName: 'Response Rate < 30 Min',
                propertyName: Enums.leadMetrics.responseRate30.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.averageResponseTime48Hour.metricId,
                displayName: 'Avg. Response Time (48 Hrs)',
                propertyName: Enums.leadMetrics.averageResponseTime48Hour.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.leadMetrics.appointmentRate.metricId,
                displayName: Enums.leadMetrics.appointmentRate.name,
                propertyName: Enums.leadMetrics.appointmentRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate.metricId,
                displayName: Enums.leadMetrics.closeRate.name,
                propertyName: 'bestMatchCloseRate',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate30.metricId,
                displayName: Enums.leadMetrics.closeRate30.name,
                propertyName: 'bestMatchCloseRate30',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate60.metricId,
                displayName: Enums.leadMetrics.closeRate60.name,
                propertyName: 'bestMatchCloseRate60',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate90.metricId,
                displayName: Enums.leadMetrics.closeRate90.name,
                propertyName: 'bestMatchCloseRate90',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate120.metricId,
                displayName: Enums.leadMetrics.closeRate120.name,
                propertyName: 'bestMatchCloseRate120',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate150.metricId,
                displayName: Enums.leadMetrics.closeRate150.name,
                propertyName: 'bestMatchCloseRate150',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate180.metricId,
                displayName: Enums.leadMetrics.closeRate180.name,
                propertyName: 'bestMatchCloseRate180',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            }
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.closeRate.metricId,
        metricOneColorHexCode: '#414141',
        metricTwoColorHexCode: '#ababab'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true,
            hasComparisonMetrics: false
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false,
            hasComparisonMetrics: false
        },
        {
            show: true,
            header: 'Total Sales Leads',
            columnDef: 'leadVolume',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: 'Response Rate',
            columnDef: 'responseRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: 'Response Rate < 30 Min',
            columnDef: 'responseRate30',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: 'Average Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48Hour',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: 'Overall Average Response Time',
            columnDef: 'averageResponseTime',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: 'Appointment Rate',
            columnDef: 'appointmentRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'bestMatchCloseRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: '30 Day Close Rate',
            columnDef: 'bestMatchCloseRate30',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: '60 Day Close Rate',
            columnDef: 'bestMatchCloseRate60',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: '90 Day Close Rate',
            columnDef: 'bestMatchCloseRate90',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: '120 Day Close Rate',
            columnDef: 'bestMatchCloseRate120',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: '150 Day Close Rate',
            columnDef: 'bestMatchCloseRate150',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
        {
            show: true,
            header: '180 Day Close Rate',
            columnDef: 'bestMatchCloseRate180',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false,
            hasComparisonMetrics: true
        },
    ];
    public trendTableMetrics: INameValuePair[] = [
      {name: 'Total Sales Leads', value: 'leadVolume'},
      {name: 'Response Rate', value: 'responseRate'},
      {name: 'Response Rate < 30 Min', value: 'responseRate30'},
      {name: 'Avg. Response Time (48 Hr)', value: 'averageResponseTime48Hour'},
      {name: 'Overall Avg. Response Time', value: 'averageResponseTime'},
      {name: 'Appointment Rate', value: 'appointmentRate'},
      {name: 'Close Rate', value: 'bestMatchCloseRate'},
      {name: '30-Day Close Rate', value: 'bestMatchCloseRate30'},
      {name: '60-Day Close Rate', value: 'bestMatchCloseRate60'},
      {name: '90-Day Close Rate', value: 'bestMatchCloseRate90'},
      {name: '120-Day Close Rate', value: 'bestMatchCloseRate120'},
      {name: '150-Day Close Rate', value: 'bestMatchCloseRate150'},
      {name: '180-Day Close Rate', value: 'bestMatchCloseRate180'},
    ];
}
