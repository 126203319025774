import { Component } from '@angular/core';
import { SpinnerService } from '../_shared/services/spinner/spinner.service';

@Component({
    selector: 'spinner-overlay',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent {
    showSpinner: boolean = false;

    constructor(private spinnerService: SpinnerService) {
        spinnerService.spinnerActivated.subscribe((status: boolean) => setTimeout(() => this.onSpinnerActivated(status), 0));
    }

    onSpinnerActivated(spinnerState: boolean) {
        this.showSpinner = spinnerState;
    }
}
