<div class="sd-infinite-scroll-xy transaction-report">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="first-data-column data-table-header" [ngClass]="getColumnWidthClass(column.columnDef)"> {{column.header}} </th>
      <td td mat-cell *matCellDef="let row" class="first-data-column overflow-column-200">
        <ng-container *ngIf="!column.clickable">
          <span title="{{ row[column.columnDef]}}">{{ row[column.columnDef] | formatter : column.formatter}}</span>
        </ng-container>
        <a *ngIf="column.clickable" (click)="emitRowData(row, column)" style="text-decoration: underline;">
          {{ row[column.columnDef] | formatter : column.formatter }}
        </a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="data-table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.highlight]="row.highlight" [class.total-row]="row.isTotalsRow"></tr>
  </table>
  <mat-paginator *ngIf="pagination" [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons class="mat-paginator-sticky"></mat-paginator>
</div>
