import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class WebsiteAfterSalesService {

    constructor(private http: HttpClient) { }

    getWebsiteAfterSalesGraphMetrics(reportRequest: any, reportType: string): Observable<Models.WebsiteAfterSalesGraphResponseModel> {
        reportRequest.reportType = reportType;

        return this.http
        .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
        .pipe(
            map((response: Models.WebsiteAfterSalesGraphResponseModel) => {
                return <Models.WebsiteAfterSalesGraphResponseModel>response;
            }),
            catchError(this.handleError)
        );
    }

    getWebsiteAfterSalesTableMetrics(reportRequest: any, reportType: string): Observable<Models.WebsiteAfterSalesTableResponseModel> {
        reportRequest.reportType = reportType;

        return this.http
        .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
        .pipe(
            map((response: Models.WebsiteAfterSalesTableResponseModel) => {
                return <Models.WebsiteAfterSalesTableResponseModel>response;
            }),
            catchError(this.handleError)
        );
    }

    getWebsiteAfterSalesLmaTableMetrics(reportRequest: any, reportType: string): Observable<Models.WebsiteAfterSalesTableResponseModel> {
        reportRequest.reportType = reportType;

        return this.http
        .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
        .pipe(
            map((response: Models.WebsiteAfterSalesTableResponseModel) => {
                return <Models.WebsiteAfterSalesTableResponseModel>response;
            }),
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse): Observable<any> {
        return of([]);
    }
}
