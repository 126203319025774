import { Injectable } from '@angular/core';
import { ReportConfigService } from '../../services/config/reports/report-config.service';
import * as Highcharts from 'highcharts';
import { environment as env } from '../../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  private reflowChart = new Subject<void>();
  reflowChart$ = this.reflowChart.asObservable();

  constructor(
    private reportConfig: ReportConfigService
  ) { }

  reflowCharts() {
    this.reflowChart.next();
  }

  private getHighchartsColumnChart(
    title: string,
    series: Highcharts.SeriesBarOptions[],
    xAxisOptions: Highcharts.XAxisOptions | Highcharts.XAxisOptions[],
    yAxisOptions: Highcharts.YAxisOptions | Highcharts.YAxisOptions[],
    tooltipsOptions?: Highcharts.TooltipOptions,
    legend?: Highcharts.LegendOptions,
    plotOptions?: Highcharts.PlotOptions,
    options?: Highcharts.Options): Highcharts.Options {
      return this.getHighcartsDefaultChart('column', {...{
        title: {
          text: title
        },
        legend: legend,
        xAxis: xAxisOptions,
        yAxis: yAxisOptions,
        tooltip: tooltipsOptions,
        plotOptions: plotOptions,
        series: series
      }, ...options});
  }

  getStackedPercentageBarChart(
    title: string,
    series: any[],
    categories: string[],
    yAxisTitle?: string,
    tooltipPointFormat?: string,
    colors?: string[]) {
      return this.getHighchartsStackedPercentageBarChart(
        title,
        series, {
          categories: categories
        }, {
          min: 0,
          title: {
              text: yAxisTitle
          }
        },
        !!tooltipPointFormat ? {
          pointFormat: tooltipPointFormat,
          shared: true
        } : null,
        {
          verticalAlign: 'top'
        },
        {
          colors: colors || env.defaultChartColors
        });
  }

  private getHighchartsStackedPercentageBarChart(
    title: string,
    series: Highcharts.SeriesBarOptions[],
    xAxisOptions: Highcharts.XAxisOptions | Highcharts.XAxisOptions[],
    yAxisOptions: Highcharts.YAxisOptions | Highcharts.YAxisOptions[],
    tooltipsOptions?: Highcharts.TooltipOptions,
    legend?: Highcharts.LegendOptions,
    options?: Highcharts.Options): Highcharts.Options {
      return this.getHighcartsDefaultChart('column', {...{
        title: {
          text: title
        },
        legend: legend,
        xAxis: xAxisOptions,
        yAxis: yAxisOptions,
        tooltip: tooltipsOptions,
        plotOptions: {
            column: {
                stacking: 'percent'
            }
        },
        series: series
      }, ...options});
  }

  private getHighcartsDefaultChart(type: string, options?: Highcharts.Options): Highcharts.Options {
    return {...{
      credits: {
        enabled: false
      },
      chart: {
        type: type,
        width: null
      }
    }, ...options};
  }
}
