import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
  NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
  NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;

  defaultMonthDateMode: DateMonthModes = 'calendar';
  showEntireOrgHierarchyForAllUsers = false;
  includeInactiveDealersInMetrics = true;
  filterTypeLookup: { [id: string]: FilterType } = {
    'org': { displayName: 'Hierarchy', name: 'org' },
    'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
    'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
    'device-type': { displayName: 'Device Type', name: 'device-type' },
    'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
    'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
    'source-type': { displayName: 'Source Type', name: 'source-type' },
    'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
    'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
    'website-provider': { displayName: 'Provider', name: 'website-provider' },
    'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' }
  };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
    // Leads
    'LeadsOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.leadsOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'leads',
      orgTypes: [1, 2]
    },
    'LeadSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadSummary,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 2]
    },
    'LeadSourcePerformance': {
      reportName: constants.reportNames.leadSourcePerformance,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-model'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 2]
    },
    'LeadVehiclePerformance': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadVehiclePerformance,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-model'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 2]
    },
    'LeadTransactions': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadTransactions,
      filters: [
        'org', 'lead-type', 'source-type', 'sale-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 2]
    },
    // Sales
    'SalesOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'sales',
      orgTypes: [1, 2]
    },
    'SalesSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: false,
      reportName: constants.reportNames.salesSummary,
      filters: [
        'org', 'sale-type', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'sale-type', 'lead-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1, 2]
    },
    'SalesSourceSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: false,
      reportName: 'SalesSourceSummary',
      filters: [
        'org', 'sale-type', 'lead-type'
      ],
      defaults: [
        'org', 'sale-type', 'lead-type'
      ],
      section: 'sales',
      orgTypes: [1, 2]
    },
    'SalesVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesVehicleSummary,
      filters: [
        'org', 'sale-type', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'sale-type', 'lead-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1, 2]
    },
    // website
    'WebsiteOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1, 2]
    },
    'WebsiteScorecard': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteScorecard,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1, 2]
    },
    'WebsiteSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteSummary,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1, 2]
    },
    'WebsiteReferrerSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteReferrerSummary,
      filters: [
        'org', 'device-type'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1, 2]
    },
    'WebsiteVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteVehicleSummary,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1, 2]
    },
    'WebsiteProviderSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteProviderSummary, // org only
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1, 2]
    },
    // Digital Retailing
    'DigitalRetailingOverview': {
      reportName: 'Digital Retailing Overview',
      dateMode: Enums.dateModes.currentMonth,
      filters: ['org', 'device-type', 'digital-retailing-provider'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1, 2],
      //disableCalendarMode: false
    },
  };

  FILTER_CONFIG: { [id: string]: Filter } = {
    'org': {
      type: 'org',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'vehicle': {
      type: 'vehicle',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'device-type': {
      type: 'device-type',
      selected: [
        { value: 1, display: 'Desktop' },
        { value: 2, display: 'Tablet' },
        { value: 3, display: 'Phone' }
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'lead-type': {
      type: 'lead-type',
      selected: [{ value: 1, display: 'New Sales' }],
      removable: false,
      lockable: true,
      locked: false
    },
    'sale-type': {
      type: 'sale-type',
      selected: [{ value: 1, display: 'Retail' }],
      removable: true,
      lockable: true,
      locked: false
    },
    'source-type': {
      type: 'source-type',
      selected: [
        { value: 1, display: 'Brand' },
        { value: 2, display: '3rd Party' },
        { value: 3, display: 'Dealer Website' },
        { value: 5, display: 'Event' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'vehicle-make': {
      type: 'vehicle-make',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-class': {
      type: 'vehicle-class',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-model': {
      type: 'vehicle-model',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'website-provider': {
      type: 'website-provider',
      available: [26, 25, 23, 33],
      selected: [
        { value: 26, display: 'SM360' },
        { value: 25, display: 'Auto Trader' },
        { value: 23, display: 'eDealer' },
        { value: 33, display: 'D2C Media'}
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'digital-retailing-provider': {
      type: 'digital-retailing-provider',
      available: [23, 32],
      selected: [
        { value: 23, display: 'SM360' },
        { value: 32, display: 'Motoinsight' }
      ],
      removable: true,
      lockable: true,
      locked: false
    },
  };
}
