import {
  IDefaultTableColumnConfig,
  IWebsiteProviderConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteProviderConfig implements IWebsiteProviderConfig {
  public reportTitle = 'Website Provider Performance';
  public useDefaultComparisonColumns: boolean = true;
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.WebsiteProviderSummaryHelp,
    helpTextTitle: 'Provider Summary',
    exportName: 'Website Provider Performance - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      // Visits
      {
        id: Enums.websiteMetrics.totalVisits.metricId,
        displayName: 'Website Visits',
        propertyName: Enums.websiteMetrics.totalVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.uniqueVisits.metricId,
        displayName: 'Unique Visitors',
        propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.pageViews.metricId,
        displayName: 'Total Page Views',
        propertyName: Enums.websiteMetrics.pageViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.pageViewsPerVisit.metricId,
        displayName: Enums.websiteMetrics.pageViewsPerVisit.name,
        propertyName: Enums.websiteMetrics.pageViewsPerVisit.nameKey,
        metricFormatKey: constants.formatKeys.roundToTenth
      },
      // Avg. Time on Site
      {
        id: Enums.websiteMetrics.avgTimeOnSite.metricId,
        displayName: 'Time on Site',
        propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Forms
      {
        id: Enums.websiteMetrics.formSubmissions.metricId,
        displayName: Enums.websiteMetrics.formSubmissions.name,
        propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.formConversionRate.metricId,
        displayName: Enums.websiteMetrics.formConversionRate.name,
        propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Phone
      {
        id: Enums.websiteMetrics.phoneCalls.metricId,
        displayName: Enums.websiteMetrics.phoneCalls.name,
        propertyName: Enums.websiteMetrics.phoneCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.phoneConversionRate.metricId,
        displayName: Enums.websiteMetrics.phoneConversionRate.name,
        propertyName: Enums.websiteMetrics.phoneConversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Total Contact
      {
        id: Enums.websiteMetrics.totalContacts.metricId,
        displayName: Enums.websiteMetrics.totalContacts.name,
        propertyName: Enums.websiteMetrics.totalContacts.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.totalContactRate.metricId,
        displayName: Enums.websiteMetrics.totalContactRate.name,
        propertyName: Enums.websiteMetrics.totalContactRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Click to Call
      {
        id: Enums.websiteMetrics.clickToCalls.metricId,
        displayName: Enums.websiteMetrics.clickToCalls.name,
        propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Bounce Rate
      {
        id: Enums.websiteMetrics.bounceRate.metricId,
        displayName: Enums.websiteMetrics.bounceRate.name,
        propertyName: Enums.websiteMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // VDPs
      {
        id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.newInventoryDetailViews.name,
        propertyName: 'vdpNewViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.usedInventoryDetailViews.name,
        propertyName: 'vdpUsedViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.cpoInventoryDetailViews.name,
        propertyName: 'vdpCPOViews',
        metricFormatKey: constants.formatKeys.localeString
      }
    ],
    childMetrics: [
      {
        id: 0,
        displayName: Enums.websiteProviders.allProviders.name,
        propertyName: Enums.websiteProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 23,
        displayName: 'SM360',
        propertyName: 'SM360',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 25,
        displayName: 'eDealer',
        propertyName: 'eDealer',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 26,
        displayName:  'Auto Trader',
        propertyName: 'Auto Trader',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 33,
        displayName:  'D2C Media',
        propertyName: 'D2C Media',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],

    defaultChildrenIds: [Enums.websiteProviders.allProviders.providerId, Enums.websiteProviders.allProviders.providerId],
    defaultParentIds: [Enums.websiteMetrics.totalVisits.metricId, Enums.websiteMetrics.uniqueVisits.metricId],
    metricOneColorHexCode: '#05141F',
    metricTwoColorHexCode: '#9EA1A2'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Provider',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // Visits
    {
      show: true,
      header: 'Website Visits',
      columnDef: 'totalVisits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Unique Visits
    {
      show: true,
      header: 'Unique Visitors',
      columnDef: 'uniqueVisits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Total Page Views
    {
      show: true,
      header: 'Total Page Views',
      columnDef: 'pageViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Page views per visit
    {
      show: true,
      header: 'Page Views Per Visit',
      columnDef: 'pageViewsPerVisit',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.roundToHundreth
    },

    // Avg Time on Site
    {
      show: true,
      header: 'Time On Site',
      columnDef: 'averageTimeOnSite',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
    },

    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Form Conversion Rate
    {
      show: true,
      header: 'Form Conversion Rate',
      columnDef: 'formConversionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Phone Calls
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneCalls',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Phone Conversion Rate
    {
      show: true,
      header: 'Phone Conversion Rate',
      columnDef: 'phoneConversionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Total Contacts
    {
      show: true,
      header: 'Total Contacts',
      columnDef: 'totalContacts',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Total Contact Rate
    {
      show: true,
      header: 'Total Contact Rate',
      columnDef: 'totalContactRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Click to Call
    {
      show: true,
      header: 'Click to Call',
      columnDef: 'clickToCalls',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // New VDP
    {
      show: true,
      header: 'New Inventory Detail Views',
      columnDef: 'vdpNewViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Used VDPs
    {
      show: true,
      header: 'Used Inventory Detail Views',
      columnDef: 'vdpUsedViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // CPO VDPs
    {
      show: true,
      header: 'CPO Inventory Detail Views',
      columnDef: 'vdpCPOViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
  ];

}
