import { Injectable } from '@angular/core';
import { ToasterConfig, ToasterModule, ToasterService } from '@dkachko/angular2-toaster';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { ConfigurationService } from '../config/config.service';
import { AppInjector } from '../../../app.injector';

export interface ILogFn {
    (msg: string, data?: any, showToast?: boolean): void;
};

export interface ILoggerService {
    getLogFn(moduleId: string, fnName?: string): ILogFn;
    log(message: string, data?: any, source?: string, showToast?: boolean): void;
    logError(message: string, data?: any, source?: string, showToast?: boolean): void;
    logWarning(message: string, data?: any, source?: string, showToast?: boolean): void;
    logSuccess(message: string, data?: any, source?: string, showToast?: boolean): void;
};

@Injectable()
export class LoggerService {
    private appInsightsService: AppInsightsService;

    constructor(private toasterService: ToasterService, private configService: ConfigurationService) {

    }

    getLogFn(moduleId: string, fnName?: string): ILogFn {
        fnName = fnName || 'log';
        switch (fnName.toLowerCase()) { // convert aliases
            case 'success':
                fnName = 'logSuccess';
                break;
            case 'error':
                fnName = 'logError';
                break;
            case 'warn':
                fnName = 'logWarning';
                break;
            case 'warning':
                fnName = 'logWarning';
                break;
        }

        var logFn = this[fnName] || this.log;
        var fn = (msg: string, data?: string, showToast?: boolean) => {
            logFn(msg, data, moduleId, (showToast === undefined) ? true : showToast);
        };
        return fn;
    }

    log = (message: string, data?: any, source?: string, showToast?: boolean): void => {
        this.logIt(message, data, source, showToast, 'info');
    }

    logWarning = (message: string, data?: any, source?: string, showToast?: boolean): void => {
        this.logIt(message, data, source, showToast, 'warning');
    }

    logSuccess = (message: string, data?: any, source?: string, showToast?: boolean): void => {
        this.logIt(message, data, source, showToast, 'success');
    }

    logError = (message: string, data?: any, source?: string, showToast?: boolean): void => {
        this.logIt(message, data, source, showToast, 'error');
    }


    logIt(message: string, data: any, source: string, showToast: boolean, toastType: string): void {
        if (!this.appInsightsService)
            this.appInsightsService = AppInjector.get(AppInsightsService);

        if (toastType === 'error' || toastType === 'warning') {
            if (this.appInsightsService) {
                this.appInsightsService.trackEvent(message, {
                    props: {
                        level: toastType,
                        data: data,
                        source: source
                    }
                });
            }
        }

        data = data || '';
        source = source || '';
        source = source ? '[' + source + '] ' : '';
        if (toastType === 'error')
            console.error(source, message, data);
        if (showToast) {
            if (toastType === 'error') {
                this.toasterService.pop('error', null, message);
            } else if (toastType === 'warning') {
                this.toasterService.pop('warning', null, message);
            } else if (toastType === 'success') {
                this.toasterService.pop('success', null, message);
            } else {
                this.toasterService.pop('info', null, message);
            }
        }
    }
}


