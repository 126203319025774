import { Injectable } from '@angular/core';
import { IOrgLookupType } from '../../../enums/enums';
import { IOrgConfig } from '../../../models/models-index';
import { OrgConfig } from '../../../../_config/org/org.config';

// @Injectable({ providedIn: 'root' })
@Injectable()
export class OrgConfigService {

  orgConfig: IOrgConfig = new OrgConfig;

  constructor() { }

  getOrgLookupTypeOrDefault(orgLookupTypeId: number): IOrgLookupType {
    const orgs: IOrgLookupType[] = [];

    Object.keys(this.orgConfig.orgLookupTypes).forEach(olt => orgs.push(this.orgConfig.orgLookupTypes[olt]));

    return orgs.find(o => o.orgLookupTypeId === orgLookupTypeId) || this.defaultOrgLookupType();
  }

  defaultOrgLookupType(): IOrgLookupType {
    const orgs: IOrgLookupType[] = [];

    Object.keys(this.orgConfig.orgLookupTypes).forEach(olt => orgs.push(this.orgConfig.orgLookupTypes[olt]));

    return orgs.find(o => o.default === true);
  }

  getOrgLookupTypeName(orgLookupTypeId: number) {
    let display: string;
    Object.keys(this.orgConfig.orgLookupTypes).forEach(key => {
      let orgLookupType = this.orgConfig.orgLookupTypes[key];
      if (orgLookupType.orgLookupTypeId === orgLookupTypeId) {
        display = orgLookupType.name;
      }      
    });
    return display;
  }

  get nationalOrgEntityName(): string {
    return this.orgConfig.nationalOrgEntityName;
  }

  get nationalOrgLookups(): IOrgLookupType[] {
    let nationalOlts: IOrgLookupType[] = [];
    Object.keys(this.orgConfig.orgLookupTypes).forEach(olt => {
      if (this.orgConfig.orgLookupTypes[olt].orgEntityType === 'national') {
          nationalOlts.push(this.orgConfig.orgLookupTypes[olt]);
      }
    })
    return nationalOlts;
  }

  get dealerUserOrgPriorityIds(): number[] {
    let nationalOlts: number[] = [];
    Object.keys(this.orgConfig.nationalOrgLookupTypeIds).forEach(olt => {
      nationalOlts.push(this.orgConfig.nationalOrgLookupTypeIds[olt]);
    })
    return nationalOlts;
  }

  IsNationalOrgLookupType(orgLookupTypeId: number): boolean {
    return this.orgConfig.nationalOrgLookupTypeIds.indexOf(orgLookupTypeId) != -1;
  }

  IsLmaOrgLookupType(orgLookupTypeId: number): boolean {
    return this.orgConfig.lmaOrgLookupTypeIds.indexOf(orgLookupTypeId) != -1;
  }

  IsVirtual20OrgLookupType(orgLookupTypeId: number): boolean {
    return this.orgConfig.virtual20OrgLookupTypeIds.indexOf(orgLookupTypeId) != -1;
  }

  IsDealerGroupOrgLookupType(orgLookupTypeId: number): boolean {
    return this.orgConfig.dealerGroupOrgLookupTypeIds.indexOf(orgLookupTypeId) != -1;
  }

}
