import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Event, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import * as Models from '../../../_shared/models/models-index';
import { IDateMode } from '../../enums/enums';

export class Breadcrumb {
	displayName: string;
	masterName: string;
	terminal: boolean;
	displayAltNav: string;
	url: string;
}

export interface IBreadcrumbResetOptions {
	dateMode: IDateMode;
	restrictDates: boolean;
}

@Injectable()
export class BreadcrumbService {
	private setDateMode = new Subject<IBreadcrumbResetOptions>();
	setDateModeLabel$ = this.setDateMode.asObservable();
	breadcrumbChanged = new EventEmitter<Breadcrumb[]>(false);
	private breadcrumbs = new Array<Breadcrumb>();

	constructor(private router: Router) {
		this.router.events.subscribe((routeEvent) => { this.onRouteEvent(routeEvent); });
	}

	public changeBreadcrumb(route: ActivatedRouteSnapshot, name: string) {
		const rootUrl = this.createRootUrl(route);
		const breadcrumb = this.breadcrumbs.find(function (bc) { return bc.url === rootUrl; });

		if (!breadcrumb) { return; }

		breadcrumb.displayName = name;

		this.breadcrumbChanged.emit(this.breadcrumbs);
	}

	updateBreadcrumb(keyName: string, updateName: string) {
		const breadcrumb = this.breadcrumbs.find(function (bc) { return bc.masterName === keyName; });
		if (!breadcrumb) { return; }

		breadcrumb.displayName = updateName;

		this.breadcrumbChanged.emit(this.breadcrumbs);
	}

	updateDateMode(dateMode: IDateMode, restrict: boolean = false) {
		const bcOptions: IBreadcrumbResetOptions = {
			dateMode: dateMode,
			restrictDates: restrict
		};

		this.setDateMode.next(bcOptions);
	}

	private onRouteEvent(routeEvent: Event) {
		if (!(routeEvent instanceof NavigationEnd)) { return; }

		let route = this.router.routerState.root.snapshot;
		let url = '';

		this.breadcrumbs = [];

		while (route.firstChild != null) {
			route = route.firstChild;

			const breadcrumb = this.breadcrumbs.find(function (bc) { return bc.displayName === route.data['breadcrumb']; });
			if (breadcrumb) { continue; }

			if (route.data && route.url.length === 0) {
				const crumb: Breadcrumb = {
					displayName: route.data.breadcrumb,
					masterName: route.data.breadcrumb,
					terminal: false,
					displayAltNav: '',
					url: ''
				};

				this.breadcrumbs.push(crumb);
			}
			if (route.routeConfig === null) { continue; }
			if (!route.routeConfig.path) { continue; }

			url += `/${this.createUrl(route)}`;

			if (!route.data['breadcrumb']) { continue; }

			this.breadcrumbs.push(this.createBreadcrumb(route, url));
		}

		this.breadcrumbChanged.emit(this.breadcrumbs);
	}

	private createBreadcrumb(route: ActivatedRouteSnapshot, url: string): Breadcrumb {
		return {
			displayName: route.data['breadcrumb'],
			masterName: route.data['breadcrumb'],
			terminal: this.isTerminal(route),
			displayAltNav: route.data['displayAltNav'],
			url: url
		}
	}

	private isTerminal(route: ActivatedRouteSnapshot) {
		return route.firstChild === null
			|| route.firstChild.routeConfig === null
			|| !route.firstChild.routeConfig.path;
	}

	private createUrl(route: ActivatedRouteSnapshot) {
		return route.url.map(function (s) { return s.toString(); }).join('/');
	}

	private createRootUrl(route: ActivatedRouteSnapshot) {
		let url = '';
		let next = route.root;

		while (next.firstChild !== route && next.firstChild !== null) {
			next = next.firstChild;

			if (next.routeConfig === null) { continue; }
			if (!next.routeConfig.path) { continue; }

			url += `/${this.createUrl(next)}`;
		}

		url += `/${this.createUrl(route)}`;

		return url;
	}
}
