import { Injectable } from '@angular/core';
import { IOrgLookupType } from '../../../enums/enums';
import { IRole, IRoleConfig } from '../../../models/models-index';
import { RoleConfig } from '../../../../_config/role/role.config';

// @Injectable({ providedIn: 'root' })
@Injectable()
export class RoleConfigService {

  roleConfig: IRoleConfig = new RoleConfig;

  constructor() { }

  isSysAdminRoleId(roleId: number) {
    return this.roleConfig.systemAdministratorRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isSysAdminRole(role: string) {
    return this.roleConfig.systemAdministratorRoles.map(r => r.name).indexOf(role) != -1;
  }

  isCorporateRoleId(roleId: number) {
    return this.roleConfig.corporateRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isCorporateRole(role: string) {
    return this.roleConfig.corporateRoles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg5RoleId(roleId: number) {
    return this.roleConfig.org5Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg5Role(role: string) {
    return this.roleConfig.org5Roles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg4RoleId(roleId: number) {
    return this.roleConfig.org4Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg4Role(role: string) {
    return this.roleConfig.org4Roles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg3RoleId(roleId: number) {
    return this.roleConfig.org3Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg3Role(role: string) {
    return this.roleConfig.org3Roles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg2RoleId(roleId: number) {
    return this.roleConfig.org2Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg2Role(role: string) {
    return this.roleConfig.org2Roles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg1RoleId(roleId: number) {
    return this.roleConfig.org1Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg1Role(role: string) {
    return this.roleConfig.org1Roles.map(r => r.name).indexOf(role) != -1;
  }

  isDealerRoleId(roleId: number) {
    return this.roleConfig.dealerRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isDealerRole(role: string) {
    return this.roleConfig.dealerRoles.map(r => r.name).indexOf(role) != -1;
  }

  isDealerGroupRoleId(roleId: number) {
    return this.roleConfig.dealerGroupRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isDealerGroupRole(role: string) {
    return this.roleConfig.dealerGroupRoles.map(r => r.name).indexOf(role) != -1;
  }

  roleHasSysAdminAccess(role: string) {
    return this.isSysAdminRole(role);
  }

  roleHasCorporateAccess(role: string) {
    return this.roleHasSysAdminAccess(role)
      || this.isCorporateRole(role);
  }

  roleHasOrg5Access(role: string) {
    return this.roleHasCorporateAccess(role)
      || this.isOrg5Role(role);
  }

  roleHasOrg4Access(role: string) {
    return this.roleHasOrg3Access(role)
      || this.isOrg4Role(role);
  }

  roleHasOrg3Access(role: string) {
    return this.roleHasCorporateAccess(role)
      || this.isOrg3Role(role);
  }

  roleHasOrg2Access(role: string) {
    return this.roleHasOrg3Access(role)
    || this.isOrg2Role(role);
  }

  roleHasOrg1Access(role: string) {
    return this.roleHasOrg2Access(role)
    || this.isOrg1Role(role);
  }

  roleHasDealerAccess(role: string) {
    return this.roleHasOrg1Access(role)
    || this.isDealerRole(role);
  }

  getRoleById(roleId: number): IRole | null {
    const allRoles = [
      ...this.roleConfig.systemAdministratorRoles,
      ...this.roleConfig.corporateRoles,
      ...this.roleConfig.org4Roles,
      ...this.roleConfig.org5Roles,
      ...this.roleConfig.org3Roles,
      ...this.roleConfig.org2Roles,
      ...this.roleConfig.org1Roles,
      ...this.roleConfig.dealerRoles,
      ...this.roleConfig.dealerGroupRoles,
      ...(this.roleConfig.agencyRoles ?? [])
    ];

    for (const role of allRoles) {
      if (role.roleId === roleId) {
        return role;
      }
    }

    return null;
  }

  getDefaultRoute(role: string) {
    // as for now only agency role has no access to '/' route
    const agencyRoleConfig = this.roleConfig.agencyRoles?.[0];
    const agencyRoleConfigured = agencyRoleConfig?.defaultRoute;
    const agencyRoleName = agencyRoleConfig?.name;

    return agencyRoleConfigured && role === agencyRoleName
      ? agencyRoleConfig.defaultRoute
      : undefined;
  }
}
