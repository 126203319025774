<div class="modal-dialog generic-modal">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title pull-left">
                {{modalParams.headerText | uppercase}}
            </h4>
            <i class="fa {{modalParams.headerIconClass}} fa-2x pull-left">
            </i>
            
        </div>
        <div class="modal-body">
            <div class="container-fluid details-edit">
                <div class="row">
                    <div *ngIf="!modalParams.allowHtml" class="col-md-12 text-center">
                        {{modalParams.bodyText}}
                    </div>
                    <div *ngIf="modalParams.allowHtml" class="col-md-12 text-center" [innerHtml]="modalParams.bodyText">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-w-sm btn-gap btn-primary btn-sm" (click)="ok()">{{modalParams.okText}}</button>
            <button type="button" *ngIf="!modalParams.infoOnly" class="btn btn-w-sm btn-gap btn-gray btn-sm" (click)="cancel()">{{modalParams.cancelText}}</button>
        </div>
    </div>
</div>