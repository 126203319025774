import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';

@Injectable()
export class DigitalAdvertisingChannelSummaryService {

constructor(private http: HttpClient) { }

	getDigitalAdvertisingChannelSummaryGraphMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingChannelSummaryGraphResponseModel> {
		return new Promise<Models.DigitalAdvertisingChannelSummaryGraphResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingChannelSummaryGraphResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingChannelSummaryTableMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingChannelSummaryTableResponseModel> {
		return new Promise<Models.DigitalAdvertisingChannelSummaryTableResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingChannelSummaryTableResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
