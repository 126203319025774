import { KpiSheetColumn } from '../../../../_shared/components/kpi-sheet/kpi-sheet-models';
import {
    IChatOverviewConfig,
    IChatOverviewBillboardConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig,
    INameValuePair,
    IDefaultTableColumnConfig,
    IPreviousDateRangeDisplay} from '../../../../_shared/models/models-index';

export class ChatOverviewConfig implements IChatOverviewConfig {
    public reportTitle: string;
    public providerBreakoutAccess?: string[] = [];
    public billboards: IChatOverviewBillboardConfig[] = [];
    public kpiSheetOptions: any;
    public metricDisplayModes: INameValuePair[] = [];
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
    public hasChatHelpText: boolean;
    public previousDateColumnDisplayOverrides?: IPreviousDateRangeDisplay = null;
}
