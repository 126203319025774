import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SupportModalComponent } from './navbar/support.component';
import { ConfigurationService, LocaleService, LayoutTranslationService } from '../_shared/services/services-index';
import { LanguageSelectorModalComponent } from './navbar/language-selector-modal.component';
import { environment } from '../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
    selector: 'layout-navbar',
    templateUrl: './navbar.component.html'
})

export class LayoutNavbarComponent {
    defaultRoute: string;
    showLanguageOptions: boolean;
    locale$ = this.localeService.locale$.pipe(tap(loc => loc));
    subscriptions: Subscription[] = [];
    constructor(
        public store$: Store,
        public dialog: MatDialog,
        public configService: ConfigurationService,
        public localeService: LocaleService,
        public translationService: LayoutTranslationService,

    ) {
        this.showLanguageOptions = !!environment.availableLocales && environment.availableLocales.length > 1;
        this.defaultRoute = this.configService.routing.defaultRoute;
    }

    openSupportModal(): void {
        const dialogRef = this.dialog.open(SupportModalComponent, {
            width: '750px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => { });
    }

    openLanguageSelectorModal() {
        this.dialog.open(LanguageSelectorModalComponent, {
          width: '500px',
          autoFocus: false
        });
    }
}

