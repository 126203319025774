
import {
    IDefaultTableColumnConfig,
    IWebsiteLandingPageConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';

export class WebsiteLandingPageConfig implements IWebsiteLandingPageConfig {
    public graphConfig: IDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
