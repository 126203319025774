<div *ngIf="showSpinner" style="position:fixed;height:100%;width:100%;z-index:100000;opacity:.7;background-color: #fff;">
  <div class="sk-wave">
    <div class="sk-rect sk-rect1"></div>
    <div class="sk-rect sk-rect2"></div>
    <div class="sk-rect sk-rect3"></div>
    <div class="sk-rect sk-rect4"></div>
    <div class="sk-rect sk-rect5"></div>
    <div class="sk-rect sk-rect6"></div>
  </div>
</div>
