import { KpiSheetColumn } from '../../../../_shared/components/kpi-sheet/kpi-sheet-models';
import {
    IReputationManagementOverviewConfig,
    IReputationManagementOverviewBillboardConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';

export class ReputationManagementOverviewConfig implements IReputationManagementOverviewConfig {
    public reportTitle: string;
    public useDefaultComparisonColumns: boolean = true;
    public billboards: IReputationManagementOverviewBillboardConfig[] = [];
    public kpiSheetOptions: any;
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public trendGraphConfig: IDualAxisLineGraphConfig = null;
    public dealerTableColumns: IDefaultTableColumnConfig[] = [];
    public showTrendArrow: boolean = true;
}
