export class LeadsTranslationConfig {
    public reportTitles = {
        'en': {

        },
        'fr-CA': {
            leadsoverview: 'Aperçu des clients potentials',
            summay: 'Rapport sommaire',
            leadsummary: 'Rapport sommaire',
            leadssummary: 'Rapport sommaire',
            sourceperformance: 'Rendement de la source',
            sourceperformanceSummary: 'Rendement de la source',
            leadsourceperformance: 'Rendement de la source',
            leadssourceperformance: 'Rendement de la source',
            vehicleperformance: 'Performance par véhicule ',
            transactionss: 'Transactions',
            loyaltyLead: 'Rapport de Loyauté',

            transactions: 'Rapport de transaction',
            leadtransactions: 'Rapport de transaction',
        }
    };
    public labels = {
        'en': {

        },
        'fr-CA': {
            'benchmark': 'Analyse Comparative',
            'sourcetype': 'Type de source',
            'sourcetypes': 'Types de source',
            '3rdparty': 'Tiers',
            'allmodels': 'Tous les modèles',
            'brand': 'Marque',
            'channel': 'Chaîne',
            'cpo': 'CPO',
            'dealergenerated': 'Créé par le concessionnaire',
            'dealerwebsite': 'Site Web du concessionnaire',
            'event': 'Event',
            'kpitrend': 'Tendance',
            'leadtype': 'Type de client potentiel',
            'loyalty': 'Loyauté',
            'model': 'Modèle',
            'newused': 'Neuf et d\'occasion',
            'new': 'Nouveau',
            'reponded1hr': 'de réponse aux client potentiels < 1 hr',
            'thirdparty': 'Tiers',
            'uniqueleads': 'Clients potentiels uniques',
            'used': 'D\'occasion',
            // billboard,
            '%responded30min': '% de réponse aux client potentiels < 30 Min',
            'averageresponsetime': 'Délai de réponse moyen',
            'closerate%': '% Taux de ventes conclues',
            'closerate': 'Taux de ventes conclues',
            'leadvolume': 'Total des clients potentiels',
            'leads': 'Clients potentiels',
            'responded1hr': 'de réponse < 1 hr',
            'responded30min': '% de réponse aux client potentiels < 30 Min',
            'responserate': '% de réponse ',
        }
    };
    public metrics = {
        'en': {
            appointmentRate: '',
            appointmentRateNonPma: '',
            appointmentRatePma: '',
            archivedConversionRate: '',
            archivedLeadSales: '',
            archivedLeadVolume: '',
            attributedCloseRate: '',
            averageDailyVolume: '',
            averageResponseTime: '',
            averageResponseTime48Hour: '',
            brandNabrLeads: '',
            brandSiteLeads: '',
            closeRate: 'Close Rate',
            closeRate30: '',
            closeRate60: '',
            closeRate90: '',
            closeRate120: '',
            closeRate150: '',
            closeRate180: '',
            closeRateNonPma: '',
            closeRatePma: '',
            conversionRate: '',
            conversionRateNonPma: '',
            conversionRatePma: '',
            dealerGeneratedLeads: '',
            dealerWebsiteLeads: '',
            digitalRetailLeads: '',
            eventLeads: '',
            gainedSales: '',
            leadSales: '',
            leadSalesNonPma: '',
            leadSalesPma: '',
            leadVolume: '',
            leadVolumeNonPma: '',
            leadVolumePma: '',
            lostSales: '',
            marketingLeads: '',
            mbUsaDotComLeads: '',
            nabrLeadVolume: '',
            otherBrandLeads: '',
            percentOfLeads: '',
            percentOfSales: '',
            responseRate: '',
            responseRate15: '',
            responseRate120: '',
            responseRate180: '',
            responseRate30: '',
            responseRate40: '',
            responseRate60: '',
            responseRate60NonPma: '',
            responseRate60Pma: '',
            responseRateNonPma: '',
            responseRatePma: '',
            thirdPartyLeads: '',
            uniqueLeads: '',

        },
        'fr-CA': {
            allmodels: 'Tous les modèles',
            appointmentRate: 'Taux de rendez-vous',
            appointmentRatePreviousMTD: 'Mois préc. Taux de rendez-vous',
            appointmentRatePreviousMonth: 'Mois préc. Taux de rendez-vous',
            appointmentRatePreviousYear: 'Année préc. Taux de rendez-vous',
            appointmentRateMOM: 'Taux de rendez-vous MSM',
            appointmentRateYOY: 'Taux de rendez-vous ASA',
            averageResponseTime: 'Délai de réponse moyen global',
            averageResponseTimePreviousMTD: 'Mois préc. Délai de réponse moyen global',
            averageResponseTimePreviousMonth: 'Mois préc. Délai de réponse moyen global',
            averageResponseTimePreviousYear: 'Année préc. Délai de réponse moyen global',
            averageResponseTimeMOM: 'Délai de réponse moyen global MSM',
            averageResponseTimeYOY: 'Délai de réponse moyen global ASA',
            averageResponseTime48Hour: 'Délai de réponse moyen (48 h)',
            averageResponseTime48HourPreviousMTD: 'Mois préc. Délai de réponse moyen (48 h)',
            averageResponseTime48HourPreviousMonth: 'Mois préc. Délai de réponse moyen (48 h)',
            averageResponseTime48HourPreviousYear: 'Année préc. Délai de réponse moyen (48 h)',
            averageResponseTime48HourMOM: 'Délai de réponse moyen (48 h) MSM',
            averageResponseTime48HourYOY: 'Délai de réponse moyen (48 h) ASA',
            brandNabrLeads: '',

            brandSiteLeads: 'Clients potentiels du site de la marque',
            householdCloseRate: 'Conversion des ménages',
            householdCloseRatePreviousMTD: 'Mois préc. Conversion des ménages',
            householdCloseRatePreviousMonth: 'Mois préc. Conversion des ménages',
            householdCloseRatePreviousYear: 'Année préc. Conversion des ménages',
            householdCloseRateMOM: 'Conversion des ménages MSM',
            householdCloseRateYOY: 'Conversion des ménages ASA',

            bestMatchCloseRate: 'Taux de ventes conclues',
            bestMatchCloseRatePreviousMTD: 'Mois préc. Taux de ventes conclues',
            bestMatchCloseRatePreviousMonth: 'Mois préc. Taux de ventes conclues',
            bestMatchCloseRatePreviousYear: 'Année préc. Taux de ventes conclues',
            bestMatchCloseRateMOM: 'Taux de ventes conclues MSM',
            bestMatchCloseRateYOY: 'Taux de ventes conclues ASA',

            bestMatchCloseRate30: 'Taux de ventes conclues en 30 jours',
            bestMatchCloseRate30PreviousMTD: 'Mois préc. Taux de ventes conclues en 30 jours',
            bestMatchCloseRate30PreviousMonth: 'Mois préc. Taux de ventes conclues en 30 jours',
            bestMatchCloseRate30PreviousYear: 'Année préc. Taux de ventes conclues en 30 jours',
            bestMatchCloseRate30MOM: 'Taux de ventes conclues en 30 jours MSM',
            bestMatchCloseRate30YOY: 'Taux de ventes conclues en 30 jours ASA',

            bestMatchCloseRate60: 'Taux de ventes conclues en 60 jours',
            bestMatchCloseRate60PreviousMTD: 'Mois préc. Taux de ventes conclues en 60 jours',
            bestMatchCloseRate60PreviousMonth: 'Mois préc. Taux de ventes conclues en 60 jours',
            bestMatchCloseRate60PreviousYear: 'Année préc. Taux de ventes conclues en 60 jours',
            bestMatchCloseRate60MOM: 'Taux de ventes conclues en 60 jours MSM',
            bestMatchCloseRate60YOY: 'Taux de ventes conclues en 60 jours ASA',

            bestMatchCloseRate90: 'Taux de ventes conclues en 90 jours',
            bestMatchCloseRate90PreviousMTD: 'Taux de ventes conclues en 90 jours',
            bestMatchCloseRate90PreviousMonth: 'Mois préc. Taux de ventes conclues en 90 jours',
            bestMatchCloseRate90PreviousYear: 'Année préc. Taux de ventes conclues en 90 jours',
            bestMatchCloseRate90MOM: 'Taux de ventes conclues en 90 jours',
            bestMatchCloseRate90YOY: 'Taux de ventes conclues en 90 jours ASA',

            bestMatchCloseRate120: 'Taux de ventes conclues en 120 jours',
            bestMatchCloseRate120PreviousMTD: 'Taux de ventes conclues en 120 jours',
            bestMatchCloseRate120PreviousMonth: 'Mois préc. Taux de ventes conclues en 120 jours',
            bestMatchCloseRate120PreviousYear: 'Année préc. Taux de ventes conclues en 120 jours',
            bestMatchCloseRate120MOM: 'Taux de ventes conclues en 120 jours',
            bestMatchCloseRate120YOY: 'Taux de ventes conclues en 120 jours ASA',

            bestMatchCloseRate150: 'Taux de ventes conclues en 150 jours',
            bestMatchCloseRate150PreviousMTD: 'Taux de ventes conclues en 150 jours',
            bestMatchCloseRate150PreviousMonth: 'Mois préc. Taux de ventes conclues en 150 jours',
            bestMatchCloseRate150PreviousYear: 'Année préc. Taux de ventes conclues en 150 jours',
            bestMatchCloseRate150MOM: 'Taux de ventes conclues en 150 jours',
            bestMatchCloseRate150YOY: 'Taux de ventes conclues en 150 jours ASA',

            bestMatchCloseRate180: 'Taux de ventes conclues en 180 jours',
            bestMatchCloseRate180PreviousMTD: 'Taux de ventes conclues en 180 jours',
            bestMatchCloseRate180PreviousMonth: 'Mois préc. Taux de ventes conclues en 180 jours',
            bestMatchCloseRate180PreviousYear: 'Année préc. Taux de ventes conclues en 180 jours',
            bestMatchCloseRate180MOM: 'Taux de ventes conclues en 180 jours',
            bestMatchCloseRate180YOY: 'Taux de ventes conclues en 180 jours ASA',

            closeRateNonPma: '',
            closeRatePma: '',
            conversionRate: '',
            conversionRateNonPma: '',
            conversionRatePma: '',
            dealerGeneratedLeads: '',

            dealerWebsiteLeads: 'Clients potentiels du site du concessionnaire',
            dealerWebsiteLeadsPreviousMTD: 'Année préc. Clients potentiels du site du concessionnaire',
            dealerWebsiteLeadsPreviousMonth: 'Mois préc. Clients potentiels du site du concessionnaire',
            dealerWebsiteLeadsPreviousYear: 'Année préc.  Clients potentiels du site du concessionnaire',
            dealerWebsiteLeadsMOM: 'Clients potentiels du site du concessionnaire MSM',
            dealerWebsiteLeadsYOY: 'Clients potentiels du site du concessionnaire ASA',

            digitalRetailLeads: '',

            eventLeads: 'Clients potentiels - Activités',
            eventLeadsPreviousMTD: 'Clients potentiels - Activités',
            eventLeadsPreviousMonth: 'Mois préc. Clients potentiels - Activités',
            eventLeadsPreviousYear: 'Année préc. Clients potentiels - Activités',
            eventLeadsMOM: 'Clients potentiels - Activités MSM',
            eventLeadsYOY: 'Clients potentiels - Activités ASA',

            gainedSales: 'Ventes obtenues',
            gainedSalesPreviousMTD: 'Mois préc. Ventes obtenues',
            gainedSalesPreviousMonth: 'Mois préc. Ventes obtenues',
            gainedSalesPreviousYear: 'Année préc. Ventes obtenues',
            gainedSalesMOM: 'Ventes obtenues MSM',
            gainedSalesYOY: 'Ventes obtenues ASA',

            lostSales: 'Ventes perdues',
            lostSalesPreviousMTD: 'Mois préc. Ventes perdues',
            lostSalesPreviousMonth: 'Mois préc. Ventes perdues',
            lostSalesPreviousYear: 'Année préc. Ventes perdues',
            lostSalesMOM: 'Ventes obtenues MSM',
            lostSalesYOY: 'Ventes obtenues ASA',

            lostVehicleSales: 'Ventes perdues',
            lostVehicleSalesPreviousMTD: 'Mois préc. Ventes perdues',
            lostVehicleSalesPreviousMonth: 'Mois préc. Ventes perdues',
            lostVehicleSalesPreviousYear: 'Année préc. Ventes perdues',
            lostVehicleSalesMOM: 'Ventes obtenues MSM',
            lostVehicleSalesYOY: 'Ventes obtenues ASA',

            bestMatchSales: 'Ventes aux clients potentiels',
            bestMatchSalesPreviousMTD: 'Mois préc. Ventes aux clients potentiels',
            bestMatchSalesPreviousMonth: 'Mois préc. Ventes aux clients potentiels',
            bestMatchSalesPreviousYear: 'Année prec Ventes aux clients potentiels',
            bestMatchSalesMOM: 'Ventes aux clients potentiels MSM',
            bestMatchSalesYOY: 'Ventes aux clients potentiels ASA',

            leadSalesNonPma: '',
            leadSalesPma: '',
            leadVolume: 'Total des clients potentiels',
            leadVolumePreviousMTD: 'Mois préc. Total des clients potentiels',
            leadVolumePreviousMonth: 'Mois préc. Total des clients potentiels',
            leadVolumePreviousYear: 'Année préc. Total des clients potentiels',
            leadVolumeMOM: 'Total des clients potentiels MSM',
            leadVolumeYOY: 'Total des clients potentiels ASA',

            leadVolumeNonPma: '',
            leadVolumePma: '',
            marketingLeads: '',
            mbUsaDotComLeads: '',
            nabrLeadVolume: '',
            otherBrandLeads: '',

            percentOfLeadVolume: ' % de clients potentiels',
            percentOfLeadVolumePreviousMTD: 'Mois préc. % de clients potentiels',
            percentOfLeadVolumePreviousMonth: 'Mois préc. % de clients potentiels',
            percentOfLeadVolumePreviousYear: 'Année préc % de clients potentiels',
            percentOfLeadVolumeMOM: ' % de clients potentiels MSM',
            percentOfLeadVolumeYOY: ' % de clients potentiels ASA',

            percentOfBestMatchSales: '% de ventes',
            percentOfBestMatchSalesPreviousMTD: 'Mois préc. % de ventes',
            percentOfBestMatchSalesPreviousMonth: 'Mois préc. % de ventes',
            percentOfBestMatchSalesPreviousYear: 'Année préc. % de ventes',
            percentOfBestMatchSalesMOM: '% de ventes MSM',
            percentOfBestMatchSalesYOY: '% de ventes ASA',

            percentOfHouseholdSales: '% de ventes',
            percentOfHouseholdSalesPreviousMTD: 'Mois préc. % de ventes',
            percentOfHouseholdSalesPreviousMonth: 'Mois préc. % de ventes',
            percentOfHouseholdSalesPreviousYear: 'Année préc Mois préc.  de ventes',
            percentOfHouseholdSalesMOM: '% de ventes MSM',
            percentOfHouseholdSalesYOY: '% de ventes ASA',

            responseRate: '% de réponse aux client potentiels',
            responseRatePreviousMTD: 'Mois préc. % de réponse aux client potentiels',
            responseRatePreviousMonth: 'Mois préc. % de réponse aux client potentiels',
            responseRatePreviousYear: 'Année préc. % de réponse aux client potentiels',
            responseRateMOM: '% de réponse aux client potentiels MSM',
            responseRateYOY: '% de réponse aux client potentiels ASA',

            responseRate15: '% de réponse aux client potentiels < 15 min',
            responseRate15PreviousMTD: 'Mois préc. % de réponse aux client potentiels < 15 min',
            responseRate15PreviousMonth: 'Mois préc. % de réponse aux client potentiels < 15 min',
            responseRate15PreviousYear: 'Année préc. % de réponse aux client potentiels < 15 min',
            responseRate15MOM: '% de réponse aux client potentiels < 15 min MSM',
            responseRate15YOY: '% de réponse aux client potentiels < 15 min ASA',

            responseRate30: '% de réponse aux client potentiels < 30 min',
            responseRate30PreviousMTD: 'Mois préc. % de réponse aux client potentiels < 30 min',
            responseRate30PreviousMonth: 'Mois préc. % de réponse aux client potentiels < 30 min',
            responseRate30PreviousYear: 'Année préc. % de réponse aux client potentiels < 30 min',
            responseRate30MOM: '% de réponse aux client potentiels < 30 min MSM',
            responseRate30YOY: '% de réponse aux client potentiels < 30 min ASA',

            responseRate40: '% de réponse aux client potentiels < 40 min',
            responseRate40PreviousMTD: 'Mois préc. % de réponse aux client potentiels < 40 min',
            responseRate40PreviousMonth: 'Mois préc. % de réponse aux client potentiels < 40 min',
            responseRate40PreviousYear: 'Année préc. % de réponse aux client potentiels < 40 min',
            responseRate40MOM: '% de réponse aux client potentiels < 40 min MSM',
            responseRate40YOY: '% de réponse aux client potentiels < 40 min ASA',

            responseRate60: '% de réponse aux client potentiels < 1 hr',
            responseRate60PreviousMTD: 'Mois préc. % de réponse aux client potentiels < 1 hr',
            responseRate60PreviousMonth: 'Mois préc. % de réponse aux client potentiels < 1 hr',
            responseRate60PreviousYear: 'Année préc. % de réponse aux client potentiels < 1 hr',
            responseRate60MOM: '% de réponse aux client potentiels < 1 hr MSM',
            responseRate60YOY: '% de réponse aux client potentiels < 1 hr ASA',

            responseRate120: '% de réponse aux client potentiels < 2 hr',
            responseRate120PreviousMTD: 'Mois préc. % de réponse aux client potentiels < 2 hr',
            responseRate120PreviousMonth: 'Mois préc. % de réponse aux client potentiels < 2 hr',
            responseRate120PreviousYear: 'Année préc. % de réponse aux client potentiels < 2 hr',
            responseRate120MOM: '% de réponse aux client potentiels < 2 hr MSM',
            responseRate120YOY: '% de réponse aux client potentiels < 2 hr ASA',

            responseRate180: '% de réponse aux client potentiels < 3 hr',
            responseRate180PreviousMTD: 'Mois préc. % de réponse aux client potentiels < 3 hr',
            responseRate180PreviousMonth: 'Mois préc. % de réponse aux client potentiels < 3 hr',
            responseRate180PreviousYear: 'Année préc. % de réponse aux client potentiels < 3 hr',
            responseRate180MOM: '% de réponse aux client potentiels < 3 hr MSM',
            responseRate180YOY: '% de réponse aux client potentiels < 3 hr ASA',

            responseRate60NonPma: '',
            responseRate60Pma: '',
            responseRateNonPma: '',
            responseRatePma: '',

            thirdPartyLeads: 'Client potentiel d\'un tiers',
            thirdPartyLeadsPreviousMTD: 'Mois préc. Client potentiel d\'un tiers',
            thirdPartyLeadsPreviousMonth: 'Mois préc. Client potentiel d\'un tiers',
            thirdPartyLeadsPreviousYear: 'Année préc. Client potentiel d\'un tiers',
            thirdPartyLeadsMOM: 'Client potentiel d\'un tiers MSM',
            thirdPartyLeadsYOY: 'Client potentiel d\'un tiers ASA',
            tierTwoLeads: 'TRANSLATE ME Tier II Leads',
            tierTwoLeadsPreviousMTD: 'TRANSLATE ME Tier II Leads',
            tierTwoLeadsPreviousMonth: 'TRANSLATE ME Tier II Leads',
            tierTwoLeadsPreviousYear: 'TRANSLATE ME Tier II Leads',
            tierTwoLeadsMOM: 'TRANSLATE ME Tier II Leads',
            tierTwoLeadsYOY: 'TRANSLATE ME Tier II Leads',
            uniqueLeads: 'Clients potentiels uniques',
            uniqueLeadsPreviousMTD: 'Mois préc. Clients potentiels uniques',
            uniqueLeadsPreviousMonth: 'Mois préc. Clients potentiels uniques',
            uniqueLeadsPreviousYear: 'Année préc. Clients potentiels uniques',
            uniqueLeadsMOM: 'Clients potentiels uniques MSM',
            uniqueLeadsYOY: 'Clients potentiels uniques YOY',

            // translations
            created: 'Date client potential',
            leadDate: 'Date client potential',
            dealerCode: 'Code du concessionnaire',
            dealerName: 'Nom du concessionnaire',
            sourceName: 'Client potentiel - source',
            leadSource: 'Client potentiel - source',
            customerName: 'Nom du client',
            city: 'Ville',
            province: 'Province',
            state: 'Province',
            model: 'Modèle',
            liveDate: 'Date - Actif',
            responded: 'Date de réponse',
            responseDate: 'Date de réponse',
            responseTime: 'Délai de réponse',
            timeToResponde: 'Délai de réponse',
            saleDealerCode: 'Code du concessionnaire ventes',
            sale: 'Vente',
            saleModel: 'Vente Modèle',

        }

    };
    public chartDimensions = {
        'fr-CA': {
            allmodels: 'Tous les modèles',
            allsourcetypes: 'Toutes les sources',
        }
    }
}
