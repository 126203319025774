import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'leads',
      category: 'leads',
      default: true,
      defaultRoute: '/leads/overview',
      displayName: 'Leads',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: undefined,
      children: [
        {
          parent: '/leads',
          route: 'overview',
          displayName: 'Overview',
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'sourcePerformance',
          displayName: 'Source',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'vehiclePerformance',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'transactions',
          displayName: 'Transactions',
          iconClass: 'fa fa-exchange',
          roles: undefined
        },
      ]
    },
    {
      id: 'sales',
      category: 'sales',
      defaultRoute: '/sales/overview',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: undefined,
      children: [
        {
          parent: '/sales',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/sales',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/sales',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/sales',
          route: 'source',
          displayName: 'Source',
          iconClass: 'fa fa-car',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
      id: 'website',
      category: 'website',
      defaultRoute: '/website/overview',
      displayName: 'Website',
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'scorecard',
          displayName: 'Scorecard',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'referrer',
          displayName: 'Referrer',
          iconClass: 'fa fa-share',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'provider',
          displayName: 'Provider',
          iconClass: 'fa fa-cogs',
          roles: ['System Administrator', 'Corporate']
        },
        {
          parent: '/website',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          roles: undefined
        },
      ]
    },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: true,
      defaultRoute: '/provider-tools/digital-retailing',
      displayName: 'Provider Tools',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: undefined,
      children: [
        // Digital Retailing
        {
          parent: '/provider-tools',
          route: 'digital-retailing',
          displayName: 'Digital Retailing',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', 'System Analyst'],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst']
        },
        {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, 
        {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, 
        {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, 
        {
          parent: '/management',
          route: 'filter-configuration',
          displayName: 'Filters',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'filter-configuration/create',
              parent: 'filter-configuration',
              route: 'create',
              displayName: 'Create Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'filter-configuration/update',
              parent: 'filter-configuration',
              route: 'update',
              displayName: 'Edit Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', 'System Analyst']
        }
      ]
    },

  ];
}
