import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';

@Injectable()
export class SalesContributionService {

	constructor(private http: HttpClient) { }

	getSalesContributionGraphMetrics(reportRequest: any): Promise<Models.SalesContributionGraphMetricResponseModel> {
		return new Promise<Models.SalesContributionGraphMetricResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.SalesContributionGraphMetricResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getSalesContributionTableMetrics(reportRequest: any): Promise<Models.SalesContributionTableMetricResponseModel> {
		return new Promise<Models.SalesContributionTableMetricResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.SalesContributionTableMetricResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
