import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BillboardService {
	private dataUpdated = new Subject<any>();
	dataUpdated$ = this.dataUpdated.asObservable();

	constructor() {

	}

	updateData(data: any): void {
		this.dataUpdated.next(data);
	}

}
