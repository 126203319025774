import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService, RoleConfigService } from '../services/services-index';
import { IAuthenticationInfo } from '../models/models-index';
import { RolePermissions } from '../models/security';

@Directive({
  selector: '[appShowIfHasPermission]'
})
export class RestrictElementDirective {
  private currentUserRole: IAuthenticationInfo;
  private permissions: RolePermissions[];

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly authenticationService: AuthenticationService,
    private readonly roleConfig: RoleConfigService,
  ) {
    this.currentUserRole = this.authenticationService.getUser();
    try {
      this.permissions = roleConfig.getRoleById(+this.currentUserRole.roleIds[0])?.permissions;
    } catch (error) {
      console.log(error)
    }
  }

  @Input() set appShowIfHasPermission(permission: RolePermissions) {
    if (!permission) {
      throw new Error("Permission value is missing");
    }

    if (!this.permissions || this.permissions.includes(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();
  }
}
