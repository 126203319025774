import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


export interface IConfirmModalParams {
    headerIconClass?: string;
    headerText?: string;
    bodyText?: string;
    okText?: string;
    cancelText?: string;
    allowHtml?: boolean;
    infoOnly?: boolean;
};

@Component({
    selector: 'generic-confirm-modal',
    templateUrl: './genericConfirmModal.component.html'
})

export class GenericConfirmModalComponent implements OnInit {
    @Input() modalParams?: IConfirmModalParams;

    constructor(public activeModal: NgbActiveModal) {
        
    }

    ngOnInit() {
        this.modalParams = Object.assign({}, {
            headerIconClass: 'fa-warning',
            headerText: 'Confirm',
            bodyText: 'Are you sure?',
            okText: 'OK',
            cancelText: 'Cancel',
            allowHtml: false,
            infoOnly: false
        }, this.modalParams);
    }    

    cancel(): void {
        this.activeModal.close(false);
    }

    ok(): void {
        this.activeModal.close(true);
    }
}
