import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_shared/services/authentication/authentication.service';
import { States } from '../../_store/app.reducer';
import { Store } from '@ngrx/store';
import { logout } from '../../_store/app.actions';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../_shared/services/services-index';

@Component({
    selector: 'navbar-logout',
    templateUrl: './navbar-logout.component.html'
})
export class NavbarLogoutComponent {
    @Input() locale: string;
    constructor(
        private store$: Store<States.AppState>,
        private authService: AuthenticationService,
        private configService: ConfigurationService,
        private router: Router) { }

    logout() {
        this.store$.dispatch(logout());
        this.authService.resetUser();

        if (!!(this.configService.routing.routingConfig.routes.find(r => r.id === 'logout'))) {
          this.router.navigate(['/logout'])
        }
        else {
          this.router.navigate(['/login']);
        }
    }
}
