import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';


@Injectable()
export class SummaryReportService {

	constructor(private http: HttpClient) { }

	getDealerSalesMetrics(reportRequest: any): Promise<Models.DealerSalesResponseModel> {
		return new Promise<Models.DealerSalesResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DealerSalesResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getLeadsBySourceTypeMetrics(reportRequest: any): Promise<Models.LeadsBySourceTypeResponseModel> {
		return new Promise<Models.LeadsBySourceTypeResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.LeadsBySourceTypeResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getSalesActivityMetrics(reportRequest: any): Promise<Models.SalesActivityResponseModel> {
		return new Promise<Models.SalesActivityResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.SalesActivityResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getUniqueLeadsMetrics(reportRequest: any): Promise<Models.UniqueLeadsResponseModel> {
		return new Promise<Models.UniqueLeadsResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.UniqueLeadsResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

}
