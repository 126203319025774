import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'abbreviatedmetric', pure: true })
export class AbbreviatedMetricPipe implements PipeTransform {
  transform(val: any, locale?: string): string {
    if (val === null || val === undefined || Number.isNaN(Number.parseInt(val)) || val === Infinity || typeof val !== 'number') {
      return 'N/A';
    } else {
      if (val >= 1000000) {
        return (val / 1000000).toLocaleString(locale, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'M';
      } else if (val >= 100000) {
        return (val / 1000).toLocaleString(locale, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'k';
      }
      return val.toLocaleString(locale);
    }
  }
}
