import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../services-index';

@Injectable()
export class DigitalAdvertisingOverviewService {

	constructor(private http: HttpClient,
		private appInsights: AppInsightsService) { }

	getDigitalAdvertisingOverviewBillboardMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingOverviewBillboardResponseModel> {
		return new Promise<Models.DigitalAdvertisingOverviewBillboardResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingOverviewBillboardResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingOverviewChannelMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingOverviewChannelSummaryResponseModel> {
		return new Promise<Models.DigitalAdvertisingOverviewChannelSummaryResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingOverviewChannelSummaryResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingOverviewTrafficTrendMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingOverviewTrafficTrendResponseModel> {
		return new Promise<Models.DigitalAdvertisingOverviewTrafficTrendResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingOverviewTrafficTrendResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingOverviewTrafficTypeMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingOverviewTrafficTypeResponseModel> {
		return new Promise<Models.DigitalAdvertisingOverviewTrafficTypeResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingOverviewTrafficTypeResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingOverviewProviderSummaryMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingOverviewProviderSummaryResponseModel> {
		return new Promise<Models.DigitalAdvertisingOverviewProviderSummaryResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingOverviewProviderSummaryResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingOverviewDealerSummaryMetrics(reportRequest: any): Observable<Models.ReportResponseModel> {
		return this.http.post<Models.ReportResponseModel>(
			environment.baseApiUri + '/report',
			JSON.stringify(reportRequest),
			{ headers: { 'Content-Type': 'application/json' } }).pipe(
				catchError(err => {
					appInsights.trackException(err);
					return this.handleError(err);
				})
			);
	}


	private handleError(err: any) {
		let errorMessage: string;
		if (err.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			errorMessage = `An error occurred: ${err.error.message}`;
		} else if (err.body) {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
		} else {
			errorMessage = JSON.stringify(err);
		}
		this.appInsights.trackException(err);
		console.error(err);
		return throwError(() => errorMessage);
	}
}
