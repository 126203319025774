<mat-card class="element-card">
  <mat-card-title class="element-title">
    {{elementLabel}}
  </mat-card-title>
  <mat-card-content>
    <div style="display:flex;" fxLayout="column" fxLayoutAlign="space-around center" style="height: 120px; overflow: hidden;">
      <div style="width: 100%;">
        <div [chart]="currentChart"></div>
      </div>
    </div>
  </mat-card-content>
</mat-card>