import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { ToasterConfig } from '@dkachko/angular2-toaster';
import { FilterConfigService } from './filter/filter-config.service';
import { OrgConfigService } from './org/org-config.service';
import { ReportConfigService } from './reports/report-config.service';
import { RoleConfigService } from './role/role-config.service';
import { RoutingConfigService } from './routing/routing-config.service';
import * as Models from '../../../_shared/models/models-index';
import { Actions, ofType } from '@ngrx/effects';
import { AppActions } from '../../../_store/action-types';

import { ReportsService } from '../api/reports.service';
import { map, tap, switchMap } from 'rxjs/operators';
import { ResourceStringService } from '../api/resource-string.service';
import { Observable } from 'rxjs';

export interface IDefaultAppSettings {
    clientName: string;
    appName: string;
    closeRateStartDay: number;
    defaultDecimalPlaces: number;
    defaultBillboardDecimalPlaces: number;
    page: number;
    pageSize: number;
    showInactiveDealers: boolean;
}

export interface IHostInformation {
    hostName: string;
}

export interface IKeyCodes {
    backspace: number;
    tab: number;
    enter: number;
    esc: number;
    space: number;
    pageup: number;
    pagedown: number;
    end: number;
    home: number;
    left: number;
    up: number;
    right: number;
    down: number;
    insert: number;
    del: number;
}

@Injectable()
export class ConfigurationService {

    constructor(
        public filter: FilterConfigService,
        private actions$: Actions,
        public org: OrgConfigService,
        public reports: ReportConfigService,
        public role: RoleConfigService,
        public routing: RoutingConfigService,
        private reportsService: ReportsService,
        private resourceStringService: ResourceStringService
        ) { }

    defaultAppSettings: IDefaultAppSettings = {
        clientName: environment.client,
        appName: environment.appName,
        closeRateStartDay: 5,
        defaultBillboardDecimalPlaces: 1,
        defaultDecimalPlaces: 2,
        page: 1,
        pageSize: 30,
        showInactiveDealers: false
    };

    hostInformation: IHostInformation = {
        hostName: environment.hostName
    };

    keyCodes: IKeyCodes = {
        backspace: 8,
        tab: 9,
        enter: 13,
        esc: 27,
        space: 32,
        pageup: 33,
        pagedown: 34,
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        insert: 45,
        del: 46
    };

    toasterConfig: ToasterConfig = new ToasterConfig({
        tapToDismiss: true,
        animation: 'fade',
        newestOnTop: true,
        toastContainerId: 2,
        timeout: 5000,
        showCloseButton: false
    });

    getFilterConfigs() {
      return this.filter.filterConfig.FILTER_CONFIG;
    }

    getConfig(): Observable<Models.IAppConfig> {
        return this.reportsService.getAppConfig()
          .pipe(
                map(appConfig => ({...appConfig})),
                switchMap(appConfig => {
                    return this.resourceStringService.getAllFrontEnd()
                    .pipe(
                        //tap(response => console.log("resource-strings-service", response)),
                        map(response => response.resourceStrings),
                        map(resourceStrings => {
                            // convert resource strings into a type key'ed by culture
                            const resourceStringsByCulture = resourceStrings.reduce((acc, curr) => {
                                const cultureCode = curr.cultureCode ?? "en-US";
                                if (!acc[cultureCode]) {
                                    acc[cultureCode] = {}; // Initialize with an empty array if not exist
                                }

                                acc[cultureCode][curr.key] = curr.value; // Add the current item to the array for the culture code

                                //acc[cultureCode].push({ curr.key); // Add the current item to the array for the culture code
                                return acc;
                            }, {});

                            return {
                                ...appConfig,
                                resourceStrings: resourceStringsByCulture
                            };
                        })
                    );
                })
            );
    }
}
