

import {
    IWebsiteOverviewBillboardConfig,
    IWebsiteOverviewConfig,
    IDefaultTableColumnConfig,
    IWebsiteOverviewKpiConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteOverviewConfig implements IWebsiteOverviewConfig {
    public reportTitle = 'Website Overview';
    public benchmarkTitle = 'Retailer Performance';
    public useNewMetricCutoffDate = true;
    public useDefaultComparisonColumns = false;
    public newMetricCutoffDate = new Date(2019, 0, 1);
    public showPieChart: boolean = true;
    public chartSeriesColors = ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
    public deviceTypeColors = ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
    public referrerTypeColors = ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'];
    public referrerQualityColors = ['#18b52d', '#224197', '#f9bb11', '#c51230'];
    public referrerQualityCardHeader: ICardHeaderConfig = {
      title: 'Referrer Quality',
      iconClass: 'fa-star',
      helpTextKey: constants.helpTextKeys.websiteOverviewReferrerQualityHelp,
      helpTextTitle: 'Referrer Quality',
    };
    public referrerTypeCardHeader: ICardHeaderConfig = {
      title: 'Referrer Type',
      iconClass: 'fa-align-left',
      helpTextKey: constants.helpTextKeys.websiteOverviewReferrerTypeHelp,
      helpTextTitle: 'Referrer Type',
    };
    public trafficByDeviceTypeHeader: ICardHeaderConfig = {
      title: 'Traffic By Device',
      iconClass: 'fa-mobile',
      helpTextKey: constants.helpTextKeys.websiteOverviewTrafficByDeviceTypeHelp,
      helpTextTitle: 'Traffic By Device',
    };
    public visitorTrendCardHeader: ICardHeaderConfig = {
      title: 'Visitor Trend',
      iconClass: 'fa-bar-chart',
      helpTextKey: constants.helpTextKeys.websiteOverviewVisitorTrendHelp,
      helpTextTitle: 'Visitor Trend',
    };
    public benchmarkCardHeader: ICardHeaderConfig = {
      title: 'Retailer Performance',
      iconClass: 'fa-suitcase',
      helpTextKey: constants.helpTextKeys.websiteOverviewBenchmarkHelp,
      helpTextTitle: 'Benchmark',
      exportName: 'Website Overview - Retailer Performance',
      metricDisplayModes: ['MOM', 'YOY']
    };
    public billboards: IWebsiteOverviewBillboardConfig[] = [
        {
            title: 'Visits',
            subtitle: '',
            iconClass: 'fa-users fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalVisits',
            metricMOMPropertyName: 'totalVisitsMOM',
            metricYOYPropertyName: 'totalVisitsYOY',
            metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
            metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
            metricPreviousYearPropertyName: null,
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'totalVisitsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewVisitsBillboardHelp,
            helpTextTitle: 'Visits',
            showTrendArrow: true
        },
        {
            title: 'Unique Visitors',
            subtitle: '',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'uniqueVisits',
            metricMOMPropertyName: 'uniqueVisitsMOM',
            metricYOYPropertyName: 'uniqueVisitsYOY',
            metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
            metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
            metricPreviousYearPropertyName: null,
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewUniqueVisitsBillboardHelp,
            helpTextTitle: 'Unique Visitor',
            showTrendArrow: true
        },
        {
            title: 'Engagement',
            subtitle: '',
            iconClass: 'fa-user-plus fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'engagements',
            metricMOMPropertyName: 'engagementsMOM',
            metricYOYPropertyName: 'engagementsYOY',
            metricPreviousMonthPropertyName: 'engagementsPreviousMonth',
            metricPreviousMTDPropertyName: 'engagementsPreviousMTD',
            metricPreviousYearPropertyName: null,
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'engagementsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewEngagementsBillboardHelp,
            helpTextTitle: 'Engagement',
            showTrendArrow: true
        },
        {
            title: 'Actions',
            subtitle: '',
            iconClass: 'fa-phone fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'actions',
            metricMOMPropertyName: 'actionsMOM',
            metricYOYPropertyName: 'actionsYOY',
            metricPreviousMonthPropertyName: 'engagementsPreviousMonth',
            metricPreviousMTDPropertyName: 'actionsPreviousMTD',
            metricPreviousYearPropertyName: null,
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'actionsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewActionsBillboardHelp,
            helpTextTitle: 'Actions',
            showTrendArrow: true
        },
    ];

    public kpiSheetOptions = {
      overview: [{
        name: 'name',
        label: '',
        isHeader: true
      }, {
        name: 'visits',
        label: 'Visits',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'vdps',
        label: 'VDPs',
        format: constants.formatKeys.localeString
      }, {
        name: 'engagementRate',
        label: 'Engagement Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'bounceRate',
        label: 'Bounce Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'salesServiceOtherBounces',
        label: 'Sales | Service | Other | Bounces',
      }]
    };

    public kpiCardConfig: IWebsiteOverviewKpiConfig = {
      chartTitle: 'Total Visits vs. Engaged Visits',
      preCutoffChartTitle: 'Total Visits vs. Unique Visits',
      xAxisPropertyName: 'date',
      metrics: {
        'MetricOne': {
            yAxisTitle: 'Visits',
            displayName: 'Total Visits',
            pointLabelFormat: null,
            propertyName: 'totalVisits',
            metricFormatKey: null,
            formatter: null
        },
        'MetricTwo': {
            yAxisTitle: 'Visits',
            displayName: 'Engaged Visits',
            pointLabelFormat: null,
            propertyName: 'visitsWithEngagements',
            metricFormatKey: null,
            formatter: null
        },
        'PreCutoffMetricTwo': {
            yAxisTitle: 'Visits',
            displayName: 'Unique Visits',
            pointLabelFormat: null,
            propertyName: 'uniqueVisits',
            metricFormatKey: null,
            formatter: null
        },
        'PreCutoffMetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Total Visits',
          pointLabelFormat: null,
          propertyName: 'totalVisits',
          metricFormatKey: null,
          formatter: null
        }
      }
    };

    public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
        {
          show: true,
          header: '',
          columnDef: 'entity',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
        },
        {
          show: false,
          print: true,
          header: 'Retailer Name',
          columnDef: 'displayName',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        // Visits
        {
          show: true,
          header: 'Visits',
          columnDef: 'totalVisits',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Visits MOM',
          columnDef: 'totalVisitsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Visits',
          columnDef: 'totalVisitsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Visits YOY',
          columnDef: 'totalVisitsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Visits',
          columnDef: 'totalVisitsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        // Page Views
        {
          show: true,
          header: 'Page Views',
          columnDef: 'pageViews',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Page Views MOM',
          columnDef: 'pageViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Page Views',
          columnDef: 'pageViewsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Page Views YOY',
          columnDef: 'pageViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Page Views',
          columnDef: 'pageViewsPreviousYear', isMom: false, isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        // New VDPs
        {
          show: true,
          header: 'New VDPs',
          columnDef: 'vdpNewViews',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
          show: false,
          header: 'New VDPs MOM',
          columnDef: 'vdpNewViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month New VDPs',
          columnDef: 'vdpNewViewsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'New VDPs YOY',
          columnDef: 'vdpNewViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year New VDPs',
          columnDef: 'vdpNewViewsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        // Used VDPs
        {
          show: true,
          header: 'Used VDPs',
          columnDef: 'vdpUsedViews',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Used VDPs MOM',
          columnDef: 'vdpUsedViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Used VDPs',
          columnDef: 'vdpUsedViewsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Used VDPs YOY',
          columnDef: 'vdpUsedViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Used VDPs',
          columnDef: 'vdpUsedViewsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        // Used VDPs
        {
          show: true,
          header: 'CPO VDPs',
          columnDef: 'vdpCPOViews',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'CPO VDPs MOM',
          columnDef: 'vdpCPOViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month CPO VDPs',
          columnDef: 'vdpCPOViewsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'CPO VDPs YOY',
          columnDef: 'vdpCPOViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year CPO VDPs',
          columnDef: 'vdpCPOViewsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        // Service Views
        {
          show: true,
          header: 'Service Views',
          columnDef: 'servicePageViews',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Service Views MOM',
          columnDef: 'servicePageViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Service Views',
          columnDef: 'servicePageViewsPreviousMTD',
          isMom: true, isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Service Views YOY',
          columnDef: 'servicePageViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Service Views',
          columnDef: 'servicePageViewsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        // Engagement Rate
        {
          show: true,
          header: 'Engagement Rate',
          columnDef: 'engagementRate',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Engagement Rate MOM',
          columnDef: 'engagementRateMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Engagement Rate',
          columnDef: 'engagementRatePreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Engagement Rate YOY',
          columnDef: 'engagementRateYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Engagement Rate',
          columnDef: 'engagementRatePreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Avg Time On Site
        {
          show: true,
          header: 'Avg. Time On Site',
          columnDef: 'averageTimeOnSite',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
        },
        {
          show: false,
          header: 'Avg. Time On Site MOM',
          columnDef: 'averageTimeOnSiteMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Avg. Time On Site',
          columnDef: 'averageTimeOnSitePreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
        },
        {
          show: false,
          header: 'Avg. Time On Site YOY',
          columnDef: 'averageTimeOnSiteYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Avg. Time On Site',
          columnDef: 'averageTimeOnSitePreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
        },
        // Bounce Rate
        {
          show: true,
          header: 'Bounce Rate',
          columnDef: 'bounceRate',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Bounce Rate MOM',
          columnDef: 'bounceRateMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Bounce Rate',
          columnDef: 'bounceRatePreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Bounce Rate YOY',
          columnDef: 'bounceRateYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Bounce Rate',
          columnDef: 'bounceRatePreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        }
    ];
}
