import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'formatmetric' })
export class FormatMetricPipe implements PipeTransform {
  transform(val: any, formatKey?: string): string {
    if (!formatKey) {
      if (val === null || val === undefined || Number.isNaN(Number.parseInt(val)) || val === Infinity || typeof val !== 'number') {
        return 'N/A';
      } else {
        if (val >= 1000000) {
          return (val / 1000000).toFixed(1).toString() + 'M';
        } else if (val >= 100000) {
          return (val / 1000).toFixed(0).toString() + 'k';
        }
        return val.toLocaleString();
      }
    } else {
      if (formatKey === 'percentage') {
        if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
          return 'N/A';
        } else {
          return (Math.round(val * 10000) / 100).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2
          }) + '%';
        }
      } else if (formatKey === 'hourminutestring') {
        if (val === (null || 0)) {
          return 'N/A';
        }


        const minutes = Math.floor(val / 60);
        const seconds = Math.floor(val) - (minutes * 60);

        if (minutes === 0) {
          return seconds.toString() + 's';
        } else if (seconds === 0) {
          return minutes.toString() + 'm';
        } else {
          return minutes.toString() + 'm ' + seconds.toString() + 's';
        }
      } else if (formatKey === 'timestring') {
        if (val === (null || 0)) {
          return 'N/A';
        }

        if (val >= 60) {
          const hours = Math.floor(val / 60);
          const minutes = Math.floor(val % 60);

          if (hours === 0) {
            return minutes.toString() + 'm';
          } else if (minutes === 0) {
            return hours.toString() + 'h';
          } else {
            return hours.toString() + 'h ' + minutes.toString() + 'm';
          }
        } else if (val < 60){
          const minutes = Math.floor(val);
          const seconds = Math.floor(60 * ((val) - (minutes)))

          if (minutes === 0) {
            return seconds.toString() + 's';
          } else if (seconds === 0) {
            return minutes.toString() + 'm';
          } else {
            return minutes.toString() + 'm ' + seconds.toString() + 's';
          }
        }
      } else if (formatKey === 'oneDecimal') {
        if (val === null || val === undefined || Number.isNaN(Number.parseInt(val)) || val === Infinity || typeof val !== 'number') {
          return 'N/A';
        } else {
          return val.toFixed(1);
        }
      } else {
        return val.toLocaleString();
      }
    }
  }
}
