
export class LayoutTranslationConfig {  
  public layoutTranslations = {
    'en': {
      help: 'Help',
      mom: 'MOM',
      mtd: 'MTD',
      yoy: 'YOY',
      language: 'Language',
      logout: 'Log Out',
      support: 'Support',
      close: 'Close',
      english: 'English',
      french: 'French',
      apply: 'Apply',
      website: 'Website',
      digitalretailing: 'Digital Retailing',
      // report navigation
      overview: 'Overview',
      scorecard: 'Scorecard',
      summary: 'Summary',
      referrer: 'Referrer',
      vehicle: 'Vehicle',
      supplier: 'Supplier',      
      management: 'Management',
      content: 'Content',
      users: 'Users',
      // report titles
      websiteoverview: 'Website Overview',
      websitesummary: 'Website Summary',
      referrersummary: 'Referrer Report',
      referrerreport: 'Referrer Report',
      vehiclesummary: 'Vehicle Summary',
      providertools: 'Provider Tools',
      websiteproviderperformance: 'Website Provider Performance',
      websitesupplierperformance: 'Website Supplier Performance',
      leadtransactions: 'Lead Transactions',
      leadsoverview: 'Leads Overview',
      providersummary: 'Website Supplier Performance',
      providerscorecard: 'Website Supplier Scorecard',
      vehicleperformance: 'Vehicle Performance',
      yourlanguageselectionwillbeappliedthroughouttheapplication: 'Votre sélection de langue sera appliquée tout au long de l\'application.'
    },
    'fr-CA' : {
      help: 'Aide',
      mom: 'MSM',
      mtd: 'MÀJ',
      yoy: 'ASA',
      language: 'La Langue',
      logout: 'Se Déconnecter',
      support: 'Soutien',
      close: 'Fermer',
      english: 'Anglaise',
      french: 'Français',
      apply: 'Appliquer',
      website: 'Web',
      digitalretailing: 'Vente au Détail Numérique',
      leads: 'Clients potentiels',
      sales: 'Ventes',
      // report navigation
      loyalty: 'Loyauté',
      overview: 'Aperçu',
      scorecard: 'Tableau de bord',
      summary: 'Sommaire',
      referrer: 'Référent',
      provider: 'Fournisseur',
      vehicle: 'Véhicule',
      source: 'Source',      
      supplier: 'Fournisseur',
      supplierscorecard: 'Tableau de bord du fournisseur',
      transactions: 'Rapport de transaction',
      management: 'Utilisateurs',
      content: 'Content',
      users: 'Utilisateurs',
      // report titles
      leadssummary: 'Rapport sommaire',
      leadsourceperformance: 'Rendement de la source',
      leadtransactions: 'Lead Transactions',
      sourceperformance: 'Rendement de la source',
      leadsoverview: 'Aperçu des clients potentials',
      websiteoverview: 'Aperçu du site Web',
      websitescorecard: 'Tableau de bord',
      websitesummary: 'Sommaire de site Web',
      referrersummary: 'Référent',
      referrerreport: 'Référent',
      vehiclesummary: 'Résumé du véhicule',
      salesoverview: 'Aperçu des ventes',
      websiteproviderperformance: 'Performance du fournisseur de site Web',
      salessourceperformance: 'Performance de la source de vente',
      salessummary: 'Résumé des ventes',
      suppliersummary: 'Performance du fournisseur de site Web',
      providertools: 'Outils du fournisseur',
      usermanagement: 'Gestion des utilisateurs',
      vehicleperformance: 'Performance du véhicule',
      yourlanguageselectionwillbeappliedthroughouttheapplication: 'Votre sélection de langue sera appliquée tout au long de l\'application.',
      providerkpiexcel: 'KPI.s  Fournisseur  sur Excel',
      digitalretailingoverview: 'Sommaire de la Vente au détail Numerique',
      reportviews: 'Report Views',
      queries: 'Queries',
      datasets: 'Data Sets',
      filters: 'Filtres',
      sessionstartrate: 'Taux de Demarrage de Session',
    }
  };

  public navLabelTranslations = {
    'en': {
      leads: 'Leads',
      website: 'Website',
      overview: 'Overview',
      scorecard: 'Scorecard',
      summary: 'Summary',
      referrer: 'Referrer',
      vehicle: 'Vehicle',
      supplier: 'Supplier',
      supplierscorecard: 'Supplier Scorecard',
      management: 'Management',
      content: 'Content',
      users: 'Users',
    },
    'fr-CA': {
      leads: 'Clients potentiels',
      website: 'Web',
      sales: 'Ventes',
      overview: 'Aperçu',
      scorecard: 'Tableau de bord',
      summary: 'Sommaire',
      source: 'Source',
      referrer: 'Référent',
      vehicle: 'Véhicule',
      provider: 'Fournisseur',
      supplier: 'Fournisseur',
      supplierscorecard: 'Tableau de bord du fournisseur',
      management: 'Utilisateurs',
      content: 'Content',
      users: 'Utilisateurs',
    }
  };
}
