<div class="sd-infinite-scroll-xy first-column-pinned" [ngClass]="[expanded ? 'fill-canvas' : '']">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.rowKey" [sticky]="!!column.isPrefix" [stickyEnd]="!!column.isSuffix">
      <div *ngIf="!!column.isPrefix">
        <th mat-header-cell *matHeaderCellDef> {{column.name}} </th>
        <ng-container>
        <td mat-cell *matCellDef="let row" class="sticky-column data-table-header overflow-column-200">
          <i *ngIf="row.expandable" (click)="toggleChildRows(row)" [ngClass]="[row.showChildren ? 'fa-caret-down' : 'fa-caret-right', 'fa']" style="cursor: pointer;">&nbsp;</i>
          &nbsp;
          <ng-container *ngIf="!row.expandable">
          <span title="{{ row.displayName || row[column.rowKey]}}">{{ row.displayName || row[column.rowKey] }}</span>          
          </ng-container>
          <a *ngIf="row.expandable" (click)="column.toggleChildRows(row)">
            {{ row[column.rowKey] }}
          </a>
        </td>
      </ng-container>
      </div>
      <div *ngIf="!column.isPrefix">
        <th mat-header-cell *matHeaderCellDef class="first-data-column data-table-header" [ngClass]="[!column.show ? '' : '', column.firstStickyEnd ? 'first-sticky-end-column' : '']">{{column.name}} </th>
        <td td mat-cell *matCellDef="let row" class="first-data-column" [ngClass]="[!column.show ? '' : '', column.firstStickyEnd ? 'first-sticky-end-column' : '']">
          {{ row[column.rowKey] | formatter: row.formatKey || column.displayFormat }}
        </td>
      </div>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="data-table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.highlight]="row.highlight" [ngClass]="[!row.show ? '' : '', (row.entityType + '-row')]"></tr>
  </table>
</div>
