import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'numbertostring' })
export class NumberToStringPipe implements PipeTransform {
  transform(val: any): string {
    if (val === null || val === undefined || Number.isNaN(Number.parseInt(val)) || val === Infinity || typeof val !== 'number') {
      return 'N/A';
    } else {
      return val.toLocaleString();
    }
  }
}
