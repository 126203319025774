import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';

@Injectable()
export class DigitalAdvertisingProviderSummaryService {

	constructor(private http: HttpClient) { }

	getDigitalAdvertisingProviderSummaryGraphMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingProviderSummaryGraphResponseModel> {
		return new Promise<Models.DigitalAdvertisingProviderSummaryGraphResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingProviderSummaryGraphResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingProviderSummaryTableMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingProviderSummaryTableResponseModel> {
		return new Promise<Models.DigitalAdvertisingProviderSummaryTableResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingProviderSummaryTableResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
