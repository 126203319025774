import { Injectable } from '@angular/core';
import { ManagementTranslationConfig } from '../../../_config/translation/management/management.translation.config';

@Injectable()
export class ManagementTranslationService {
  config = new ManagementTranslationConfig;
  constructor() { }
  // getLabelTranslation(value: string, locale: string) {
  //   if (!value || locale === 'en' || !locale) {
  //     return value;
  //   }

  //   const val = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
  //   return this.sharedTranslations.labels[locale][val] ?? value;
  // }
}

