import { Component } from '@angular/core';
import { AppInsightsService } from '../services/appInsights/appInsights.service';
import { AppInjector } from '../../app.injector';
import { PercentagePipe } from '../pipes/percentage.pipe';
import { FormatMetricPipe } from '../pipes/formatMetric.pipe';
import { formatPercent } from '@angular/common';

@Component({
  template: ''
})
export class BaseComponent {

  private aiService: AppInsightsService;

  percentagePipe = (val) => PercentagePipe.prototype.transform(val);
  formatMetricPipe = (val) => FormatMetricPipe.prototype.transform(val);
  metricOneDecimal = (val) => FormatMetricPipe.prototype.transform(val, 'oneDecimal');
  percentPipeOneDecimal = (val) => formatPercent(val || 0, 'en-US', '.1');

  constructor() {
    this.aiService = AppInjector.get(AppInsightsService);
    this.logNavigation();
  }

  private logNavigation() {
    const pageTitle = document.title;
    this.aiService.trackPageView({ pageName: pageTitle });
  }

  getTrendArrowIconClass(trend: number): string {
    if (trend === 0) {
      return 'fa fa-arrows-h';
    } else if (trend < 0) {
      return 'fa fa-level-down';
    } else {
      return 'fa fa-level-up';
    }
  }
}
