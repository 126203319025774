export class SalesTranslationConfig {
    
    public saleReportTitles = {
        'en': {

        },
        'fr-CA': {
            salesoverview: 'Aperçu des ventes',
            summay: 'Résumé des ventes',
            salessummary: 'Résumé des ventes',
            sourceperformance: 'Performance de la source de vente',
            sourceperformancesummary: 'Performance de la source de vente',
            leadsourceperformance: 'Rendement de la source',
            vehicleperformance: 'Performance par véhicule',
            transactionss: 'Transactions',
            vehiclesalessummary: 'Rapport de Loyauté',
            vehiclesales: 'Rapport de Loyauté',
        }    
    };

    
    public saleLabels = {
        'en': {

        },
        'fr-CA': {
            // sales overview velocity card
            'velocity': 'Vitesse des ventes',
            'newsales': 'Ventes nouveau',
            'usedsales': 'Ventes d\'occasion',
            'dayofsalesmonth': 'Nombre de jours dans le mois de vente',
            // billboard
            'totalsales': 'Ventes totales',
            'salesfromleads': 'Ventes aux clients potentiels',
            'averagetimetosale': 'Délai moyen de vente',
            // lead contribution card
            'leadcontribution': 'Clients Potentials',
            'leadcontributionrate': 'Clients Potentials',
            '%leadcontribution': '% Clients Potentials',
            'salescontribution': 'Cotisation des ventes',
            'dealergenerated': 'Créé par le concessionnaire',            
            'leadcontrrate': 'Clients Potentials',
            'benchmark': 'Analyse Comparative',
        }
    };
    public saleMetrics = {
        'en': {

        },
        'fr-CA': {

            closeRate: 'Taux de ventes conclues',
            closeRateMTD: 'Mois préc. Taux de ventes conclues',
            closeRatePreviousMonth: 'Mois préc. Taux de ventes conclues',
            closeRatePreviousYear: 'Année Taux de ventes conclues',
            closeRateMOM: 'Taux de ventes conclues MSM',
            closeRateYOY: 'Taux de ventes conclues ASA',

            bestMatchCloseRate: 'Taux de ventes conclues',
            bestMatchCloseRateMTD: 'Mois préc. Taux de ventes conclues',
            bestMatchCloseRatePreviousMonth: 'Mois préc. Taux de ventes conclues',
            bestMatchCloseRatePreviousYear: 'Année Taux de ventes conclues',
            bestMatchCloseRateMOM: 'Taux de ventes conclues MSM',
            bestMatchCloseRateYOY: 'Taux de ventes conclues ASA',

            householdCloseRate: 'Conversion des ménages',
            householdCloseRateMTD: 'Mois préc. Conversion des ménages',
            householdCloseRatePreviousMonth: 'Mois préc. Conversion des ménages',
            householdCloseRatePreviousYear: 'Année Conversion des ménages',
            householdCloseRateMOM: 'Conversion des ménages MSM',
            householdCloseRateYOY: 'Conversion des ménages ASA',

            totalSales: 'Ventes total',
            totalSalesPreviousMTD: 'Mois préc. Ventes total',
            totalSalesPreviousMonth: 'Mois préc. Ventes total',
            totalSalesPreviousYear: 'Année prec Ventes total',
            totalSalesMOM: 'Ventes total MSM',
            totalSalesYOY: 'Ventes total ASA',

            totalLeads: 'Taux de ventes conclues',
            totalLeadsPreviousMTD: 'Mois préc. Taux de ventes conclues',
            totalLeadsPreviousMonth: 'Mois préc. Taux de ventes conclues',
            totalLeadsPreviousYear: 'Année prec Taux de ventes conclues',
            totalLeadsMOM: 'Taux de ventes conclues MSM',
            totalLeadsYOY: 'Taux de ventes conclues ASA',

            leadSales: 'Ventes aux clients potentiels',
            leadSalesPreviousMTD: 'Mois préc. Ventes aux clients potentiels',
            leadSalesPreviousMonth: 'Mois préc. Ventes aux clients potentiels',
            leadSalesPreviousYear: 'Année prec Ventes aux clients potentiels',
            leadSalesMOM: 'Ventes aux clients potentiels MSM',
            leadSalesYOY: 'Ventes aux clients potentiels ASA',            

            leadContribution: 'Clients Potentials',
            leadContributionPreviousMTD: 'Mois préc. Clients Potentials',
            leadContributionPreviousMonth: 'Mois préc. Clients Potentials',
            leadContributionPreviousYear: 'Année prec Clients Potentials',
            leadContributionMOM: 'Clients Potentials MSM',
            leadContributionYOY: 'Clients Potentials ASA',   

            averageTimeToSale: 'Délai de vente moyen',
            averageTimeToSalePreviousMTD: 'Mois préc. Délai de vente moyen',
            averageTimeToSalePreviousMonth: 'Mois préc. Délai de vente moyen',
            averageTimeToSalePreviousYear: 'Année prec Délai de vente moyen',
            averageTimeToSaleMOM: 'Délai de vente moyen MSM',
            averageTimeToSaleYOY: 'Délai de vente moyen ASA',            
            dealergenerated: 'Créé par le concessionnaire',

        }
    };
    public saleChartDimensions = {
        'fr-CA': {
            allmodels: 'Tous les modèles',
            allsourcetypes: 'Toutes les sources',
        }
    }
}
