import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComparerService {

  constructor() { }

  compare(a, b, isAsc: boolean) {
    let aValue = typeof a === "string" ? this.convertToNumber(a) : a;
    let bValue = typeof b === "string" ? this.convertToNumber(b) : b;
    
    if (typeof aValue === "number" && typeof bValue === "number") {
      return (aValue - bValue) * (isAsc ? 1 : -1);
    } else {
      return String(aValue).localeCompare(String(bValue)) * (isAsc ? 1 : -1);
    }
  }
  
  convertToNumber(str) {
    let newStr = str.replace(/,/g, '');
  
    if(newStr.includes('%')) {
      return parseFloat(newStr.replace('%', '')) / 100;
    }
    else if(newStr.includes('$')) {
      return parseFloat(newStr.replace('$', ''));
    }
    else if (!isNaN(Number(newStr))) {
      return Number(newStr);
    }
    else {
      return str;
    }
  }  
}
