import {
  IDefaultTableColumnConfig,
  IDigitalRetailingOverviewBillboardConfig,
  IDigitalRetailingOverviewConfig,
  IDualAxisLineGraphConfig,
  IMultiLevelDualAxisLineGraphConfig,
  INameValuePair,
  KpiSheetOptions
} from '../../../../_shared/models/models-index';
import { KpiSheetColumn } from '../../../../_shared/components/kpi-sheet/kpi-sheet-models';

export class DigitalRetailingOverviewConfig implements IDigitalRetailingOverviewConfig {
  public useV5 = false;
  public useDefaultComparisonColumns: boolean;
  public metricDisplayModes: INameValuePair[] = [];
  public reportTitle: string;
  public showEngagementAnalysis: boolean;
  public chartSeriesColors: string[] = [];
  public dealerTableLabel: string = 'Dealer Summary';
  public billboards: IDigitalRetailingOverviewBillboardConfig[] = [];
  public kpiSheetOptions: Record<KpiSheetOptions, KpiSheetColumn[]> = null;
  public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = null;
  public dealerTableColumns: IDefaultTableColumnConfig[] = [];
}
