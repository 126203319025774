import { Injectable } from '@angular/core';
import { Enums, IEnums } from './../../enums/enums';

interface ILocalCacheService {
    retrieveCache<T>(storageKey: string): T;
    setCache<T>(storageKey: string, cacheData: T): void;
    purgeCache(storageKey: string): void;
    purgeAllCaches(): void;
}

@Injectable()
export class LocalCacheService implements ILocalCacheService {
    private enums: IEnums = Enums;

    private localStorage = new LocalStorageService();

    retrieveCache<T>(storageKey: string): T {
        return <T>this.localStorage.get(storageKey);
    }

    setCache<T>(storageKey: string, cacheData: T): void {
        this.localStorage.set(storageKey, cacheData);
    }

    purgeCache(storageKey: string): void {
        this.localStorage.remove(storageKey);
    }

    purgeAllCaches(): void {
        this.localStorage.remove('__app_storage__');
        this.localStorage.remove(this.enums.cacheKeys.authData);
        this.localStorage.remove(this.enums.cacheKeys.rememberMe);
        this.localStorage.remove(this.enums.cacheKeys.currentFilter);
        this.localStorage.remove(this.enums.cacheKeys.defaultFilter);
        this.localStorage.remove(this.enums.cacheKeys.orgLookups);
        this.localStorage.remove(this.enums.cacheKeys.salesUnit);
    }
}

class LocalStorageService {
    get(key: string) {
        const jsonString = localStorage.getItem(key);
        return JSON.parse(jsonString);
    }

    set(key: string, value: any) {
        const jsonString = JSON.stringify(value);
        localStorage.setItem(key, jsonString);
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}
