import { Component, OnInit, OnDestroy } from '@angular/core';
import { Enums, IEnums } from '../../_shared/enums/enums';
import { ModalService } from '../../_shared/services/modal/modal.service';
import * as APIService from '../../_shared/services/api/api-index';
import * as Models from '../../_shared/models/models-index';
import { Store, select } from '@ngrx/store';
import { States } from '../../_store/app.reducer';
import { selectAuthState, selectLoggedInUser } from '../../_store/app.selectors';
import { map, switchMap, filter } from 'rxjs/operators';

@Component({
    selector: 'navbar-user',
    templateUrl: './user.component.html'
})
export class NavbarUserComponent implements OnInit, OnDestroy {
        enums: IEnums = Enums;

        private authInfo: Models.IAuthenticationInfo;
        private authInfo$ = this.store.pipe(select(selectLoggedInUser));
        userDisplayName$ = this.authInfo$.pipe(filter(user => !!user), map(user => `${user.firstName} ${user.lastName}`));
        private user$ = this.authInfo$.pipe(switchMap(user => this.userService.getUserByUserName(user.userName)));

        constructor(
            private store: Store<States.AppState>,
            private userService: APIService.UsersService,
            private modalService: ModalService) { }

        ngOnInit() {
                this.authInfo$.subscribe(authInfo => this.authInfo = authInfo);
        }

        ngOnDestroy() {

        }


        // editUserSettings(): void {
        // 	this.modalService.openEditMySettings(this.user)
        // 		.then((result) => {
        // 			if (result instanceof Object) {
        // 				const user = <Models.User>result;
        // 				this.authService.updateStoredUserNames(user.firstName, user.lastName);
        // 				this.authInfo = this.authService.getAuthenticationInfo();
        // 			}
        // 		});
        // }

        isInRole(roleName: string): boolean {
            return roleName === '*' || this.authInfo?.role === roleName;
        }

}
