import { Injectable } from '@angular/core';
import { FormattingService } from './formatting.service';

@Injectable()
export class HighchartsFormattingService {

  constructor(private formattingService: FormattingService) { }

  public getPointFormatter(metricName) {

    var formatterFunction = this.formattingService.getFormatter(metricName);

    return function () {
      return this.points.reduce(function (s, point) {
        return s + '<br/><span style="color:' + point.color + ';padding:0">' + point.series.name + '</span>: ' + formatterFunction(point.y);
      }, 
      '<span style="font-size:10px">' + this.x + '</span>');
    }
  }
}
