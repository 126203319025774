import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class FacebookSummaryService {

    constructor(
      private http: HttpClient,
      private utils: UtilsService
      ) { }

    getFacebookSummaryGraphMetrics(reportRequest: any, reportType: string): Observable<Models.FacebookSummaryGraphResponseModel> {
        reportRequest.reportType = reportType;
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.FacebookSummaryGraphResponseModel) => {
                    return <Models.FacebookSummaryGraphResponseModel>response;
                })
            );
    }

    getFacebookSummaryTableMetrics(reportRequest: any): Observable<Models.FacebookSummaryTableResponseModel> {
        return this.http
            .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
            .pipe(
                map((response: Models.FacebookSummaryTableResponseModel) => {
                    return <Models.FacebookSummaryTableResponseModel>response;
                })
            );
    }
}

