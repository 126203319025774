import { Injectable } from '@angular/core';
import { DigAdTranslationConfig } from '../../../_config/translation/digAd/digAd.translation.config';


@Injectable()
export class DigAdTranslationService {
  digAdTranslationConfig = new DigAdTranslationConfig;


  getDigAdMetricNameTranslation(metricId: string, defaultLabel: string, locale: string) {
    if (locale === 'en') {
      return defaultLabel;
    }
    return this.digAdTranslationConfig.digAdMetrics[locale][metricId] ?? '';
  }

  getDigAdReportTitleTranslation(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.digAdTranslationConfig.digAdReportTitles[locale][cleansedValue];
  }

  getDigAdLabelTranslation(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    const label = this.digAdTranslationConfig.digAdLabels[locale][cleansedValue];
    return label;
  }

  getDigAdChartDimension(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.digAdTranslationConfig.digAdChartDimensions[locale][cleansedValue] || value;
  }

  getChannels(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.digAdTranslationConfig.digAdChannels[locale][cleansedValue] || value;
  }

}
