import { trigger, state, style, group, transition, animate, query, animateChild } from "@angular/animations";

export const LAYOUT_ANIMATIONS = [
  // Use this to animate the secondary nav container
  trigger('secondaryNavSlide', [
    state('open', style({
      'width': '15%'
    })),
    state('closed', style({
      'width': '5px'
    })),
    transition('closed => open', group([
      animate('200ms ease-in-out', style({ 'width': '15%' })),
      query('@secondaryNavContentSlide', [animateChild()])
    ])),
    transition('open => closed', group([
      animate('200ms 100ms ease-in-out', style({ 'width': '5px' })),
      query('@secondaryNavContentSlide', [animateChild()])
    ]))
  ]),
  // Use this to animate the router content next to the secondary nav container - JMSNOTE - this is now for the sidenav component
  // come back here - the below is working, but throwing errors - partial key something - got to figure it out but bigger fish
  trigger('contentSlide', [
    // state('open', style({
    //   'width': '85%',
    //   'margin-left': '15%'
    // })),
    // state('closed', style({
    //   'width': 'calc(100% - 5px)',
    //   'margin-left': '5px'
    // })),
    // transition('closed => open',
    //   animate('200ms ease-in-out', style({
    //     'width': 'calc(100% - 50px)',
    //     'margin-left': '5px;'
    //   }))
    // ),
    // transition('open => closed',
    //   animate('200ms 100ms ease-in-out', style({
    //     'width': 'calc(100% - 50px)',
    //     'margin-left': '5px'
    //   }))
    // )
  ]),
  // trigger('contentSlide', [
  //   state('open', style({
  //     'width': '85%',
  //     'margin-left': '15%'
  //   })),
  //   state('closed', style({
  //     'width': 'calc(100% - 5px)',
  //     'margin-left': '5px'
  //   })),
  //   transition('closed => open',
  //     animate('200ms ease-in-out', style({
  //       'width': '85%',
  //       'margin-left': '15%'
  //     }))
  //   ),
  //   transition('open => closed',
  //     animate('200ms 100ms ease-in-out', style({
  //       'width': 'calc(100% - 5px)',
  //       'margin-left': '5px'
  //     }))
  //   )
  // ]),
  // Use this to animate the content *inside* of the secondary nav container
  trigger('secondaryNavContentSlide', [
    state('open', style({
      'opacity': '1',
      'visibility': 'visible'
    })),
    state('closed', style({
      'opacity': '0',
      'visibility': 'hidden'
    })),
    transition('closed => open',
      animate('200ms 100ms ease-in-out', style({
        'opacity': '1',
        'visibility': 'visible'
      }))
    ),
    transition('open => closed',
      animate('200ms ease-in-out', style({
        'opacity': '0',
        'visibility': 'hidden'
      }))
    )
  ]),
]
