import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../_shared/services/services-index';
import { AppState } from '../_store/app-state.model';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {

  content: string[] = [];

  constructor(
    private configService: ConfigurationService) {
  }

  ngOnInit() {
    if (!!this.configService.routing.routingConfig.routes.find(r => r.id === 'logout')?.routeData?.redirectOnLogout) {
      window.location.href = environment.logoutRedirectUrl;
    }
    else
    {
      this.content = this.configService.routing.routingConfig.routes.find(r => r.id === 'logout')?.routeData?.content || ['You have been successfully logged out.'];
    }
  }
}
