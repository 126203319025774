import { Component, OnInit, OnDestroy, TemplateRef, Type, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { Subscription } from 'rxjs';
import { FilterPanelRenderMethods, FilterPanelStates, FilterPanelCloseType } from '../types/constants';
import { SdFilterPanelContext, SdFilterPanelContent, FilterPanelConfig, FilterPanelResult } from '../types/types';
import { SdFilterPanelRef } from '../types/sd-filter-panel-ref';
import { SdFilterPanelValueService } from '../services/sd-filter-panel-value.service';
import { SharedTranslationService, LocaleService } from '../../services/services-index';

@Component({
    templateUrl: './sd-filter-panel-host.component.html',
    styleUrls: ['./sd-filter-panel-host.component.scss'],
    animations: [
        trigger('fadeInItems', [
            state('open', style({ opacity: 1 })),
            transition('void => *', [
                style({ opacity: 0 }),
                animate('100ms 50ms cubic-bezier(0.55, 0, 0.55, 0.2)')
            ])
        ]),
        trigger('transformMenu', [
            state('void', style({
                opacity: 0,
                transform: 'scale(0.4)', transformOrigin: 'top left'
            })),
            transition('void => open', group([
                animate('75ms 25ms linear', style({ transform: 'scale(1)', opacity: 1 })),
            ])),
            transition('* => void', animate('100ms 25ms linear', style({ opacity: 0, transform: 'scale(0)' })))
        ])
    ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SdFilterPanelHostComponent implements OnInit, OnDestroy {
    private _subscription = new Subscription();

    renderMethods = FilterPanelRenderMethods;

    renderMethod: FilterPanelRenderMethods.template
        | FilterPanelRenderMethods.component
        | FilterPanelRenderMethods.text = FilterPanelRenderMethods.component;

    context: SdFilterPanelContext;
    content: SdFilterPanelContent;

    panelOpened = false;
    panelState: string = FilterPanelStates.closed;

    data: FilterPanelConfig;
    title: string;
    applyButtonText = 'APPLY';
    applyButtonDisabled = true;

    constructor(
        private filterPanelRef: SdFilterPanelRef,
        private localeService: LocaleService,
        private translationService: SharedTranslationService,
        private valueService: SdFilterPanelValueService) { }

    ngOnInit() {
        this.content = this.filterPanelRef.content;
        this.data = this.filterPanelRef.config;
        this.title = this.data.panelTitle;
        this.panelState = 'showing';
        if (typeof this.content === 'string') {
            this.renderMethod = FilterPanelRenderMethods.text;
        } else if (this.content instanceof TemplateRef) {
            this.renderMethod = FilterPanelRenderMethods.template;
        }
        this.context = {
            close: this.filterPanelRef.close.bind(this.filterPanelRef)
        };

        this._subscription.add(
            this.valueService.applyDisabled$
                .subscribe((isDisabled) => {
                    this.applyButtonDisabled = isDisabled;
                })
        );

        this._subscription.add(
            this.filterPanelRef.beforeClosedOnBackdropClick$
                .subscribe(() => {
                    this.valueService.deselectAllSelectedItems();
                })
        );

        this._subscription.add(
            this.localeService.locale$.subscribe(loc => {
                this.applyButtonText = this.translationService.getLabelTranslation(this.applyButtonText, loc).toUpperCase();
            })
        );
    }

    ngOnDestroy() {
        this.panelState = null;
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    closePanel() {
        this.valueService.deselectAllSelectedItems();
        const result: FilterPanelResult = {
            resultType: FilterPanelCloseType.cancel,
            data: null,
            type: this.data.type
        };
        this.context.close(result);
    }

    applySelected() {
        const result: FilterPanelResult = {
            resultType: FilterPanelCloseType.applied,
            data: this.valueService.getValues(),
            type: this.data.type
        };
        this.valueService.deselectAllSelectedItems();
        this.context.close(result);
    }
}
