import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Event, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { IDateMode } from '../../enums/enums';

export interface IDateRangePickerResetOptions {
  dateMode: IDateMode;
  restrictDates: boolean;
  numberOfMonthsToShow?: number;
  startDate?: Date;
  endDate?: Date;
}

@Injectable()
export class SdDateRangePickerService {
  private availableOptionsUpdated = new Subject<any>();
  private updateDateRangePicker = new Subject<IDateRangePickerResetOptions>();
  availableOptionsUpdated$ = this.availableOptionsUpdated.asObservable();
  updateDateRangePicker$ = this.updateDateRangePicker.asObservable();

  private selectedDateChanged = new Subject<any>();
  selectedDateChanged$ = this.selectedDateChanged.asObservable();

  constructor(private router: Router) {

  }

  initializeDateRangePicker(dateMode: IDateMode, restrict: boolean = false, numberOfMonths: number = 12, startDate?: Date, endDate?: Date) {
    const bcOptions: IDateRangePickerResetOptions = {
      dateMode: dateMode,
      restrictDates: restrict,
      numberOfMonthsToShow: numberOfMonths,
      startDate: startDate,
      endDate: endDate
    };
    this.updateDateRangePicker.next(bcOptions);
  }

  setAvailableOptions(availableFilterOptions: any) {
    this.availableOptionsUpdated.next(availableFilterOptions);
  }

  setSelectedDate(startDate: Date, endDate: Date) {
    const bcOptions = {
      startDate: startDate,
      endDate: endDate
    };

    this.selectedDateChanged.next(bcOptions);
  }
}
