import {
   IDefaultTableColumnConfig,
   IMultiLevelDualAxisLineGraphConfig,
   IWebsiteReferrerConfig,
   ICardHeaderConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteReferrerConfig implements IWebsiteReferrerConfig {
   reportTitle = 'Referrer Report';
   public useDefaultComparisonColumns: boolean = true;

   public benchmarkCardHeader: ICardHeaderConfig = {
      title: 'Summary Data',
      iconClass: 'fa-file',
      helpTextKey: constants.helpTextKeys.websiteReferrerSummaryHelp,
      helpTextTitle: 'Referrer Summary',
      exportName: 'Referrer Report - Summary Data',
      metricDisplayModes: ['MOM', 'YOY']
   };

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
          // Visits
          {
            id: Enums.websiteMetrics.totalVisits.metricId,
            displayName: 'Website Visits',
            propertyName: Enums.websiteMetrics.totalVisits.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.uniqueVisits.metricId,
            displayName: 'Unique Visitors',
            propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.pageViews.metricId,
            displayName: 'Total Page Views',
            propertyName: Enums.websiteMetrics.pageViews.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.pageViewsPerVisit.metricId,
            displayName: Enums.websiteMetrics.pageViewsPerVisit.name,
            propertyName: Enums.websiteMetrics.pageViewsPerVisit.nameKey,
            metricFormatKey: constants.formatKeys.roundToTenth
          },
          // Avg. Time on Site
          {
            id: Enums.websiteMetrics.avgTimeOnSite.metricId,
            displayName: 'Time on Site',
            propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          // Forms
          {
            id: Enums.websiteMetrics.formSubmissions.metricId,
            displayName: Enums.websiteMetrics.formSubmissions.name,
            propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.formConversionRate.metricId,
            displayName: Enums.websiteMetrics.formConversionRate.name,
            propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          // Phone
          {
            id: Enums.websiteMetrics.phoneCalls.metricId,
            displayName: Enums.websiteMetrics.phoneCalls.name,
            propertyName: Enums.websiteMetrics.phoneCalls.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.phoneConversionRate.metricId,
            displayName: Enums.websiteMetrics.phoneConversionRate.name,
            propertyName: Enums.websiteMetrics.phoneConversionRate.nameKey,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          // Total Contact
          {
            id: Enums.websiteMetrics.totalContacts.metricId,
            displayName: Enums.websiteMetrics.totalContacts.name,
            propertyName: Enums.websiteMetrics.totalContacts.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.totalContactRate.metricId,
            displayName: Enums.websiteMetrics.totalContactRate.name,
            propertyName: Enums.websiteMetrics.totalContactRate.nameKey,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          // Click to Call
          {
            id: Enums.websiteMetrics.clickToCalls.metricId,
            displayName: Enums.websiteMetrics.clickToCalls.name,
            propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          // Bounce Rate
          {
            id: Enums.websiteMetrics.bounceRate.metricId,
            displayName: Enums.websiteMetrics.bounceRate.name,
            propertyName: Enums.websiteMetrics.bounceRate.nameKey,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          // VDPs
          {
            id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
            displayName: Enums.websiteMetrics.newInventoryDetailViews.name,
            propertyName: 'vdpNewViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
            displayName: Enums.websiteMetrics.usedInventoryDetailViews.name,
            propertyName: 'vdpUsedViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
            displayName: Enums.websiteMetrics.cpoInventoryDetailViews.name,
            propertyName: 'vdpCPOViews',
            metricFormatKey: constants.formatKeys.localeString
          }
        ],
        childMetrics: [
            {
               id: Enums.channels.allChannels.channelId,
               displayName: Enums.channels.allChannels.channelName,
               propertyName: Enums.channels.allChannels.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.brand.channelId,
               displayName: Enums.channels.brand.channelName,
               propertyName: Enums.channels.brand.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.organicEmail.channelId,
               displayName: Enums.channels.organicEmail.channelName,
               propertyName: Enums.channels.organicEmail.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.organicSearch.channelId,
               displayName: Enums.channels.organicSearch.channelName,
               propertyName: Enums.channels.organicSearch.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.organicSocial.channelId,
               displayName: Enums.channels.organicSocial.channelName,
               propertyName: Enums.channels.organicSocial.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidDisplay.channelId,
               displayName: Enums.channels.paidDisplay.channelName,
               propertyName: Enums.channels.paidDisplay.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidEmail.channelId,
               displayName: Enums.channels.paidEmail.channelName,
               propertyName: Enums.channels.paidEmail.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidSearch.channelId,
               displayName: Enums.channels.paidSearch.channelName,
               propertyName: Enums.channels.paidSearch.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidSocial.channelId,
               displayName: Enums.channels.paidSocial.channelName,
               propertyName: Enums.channels.paidSocial.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidVideo.channelId,
               displayName: Enums.channels.paidVideo.channelName,
               propertyName: Enums.channels.paidVideo.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.thirdPartySites.channelId,
               displayName: Enums.channels.thirdPartySites.channelName,
               propertyName: Enums.channels.thirdPartySites.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.typedBookmarked.channelId,
               displayName: Enums.channels.typedBookmarked.channelName,
               propertyName: Enums.channels.typedBookmarked.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.other.channelId,
               displayName: Enums.channels.other.channelName,
               propertyName: Enums.channels.other.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: 13,
               displayName: 'none',
               propertyName: 'None',
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
        ],

        defaultChildrenIds: [ Enums.channels.allChannels.channelId, Enums.channels.allChannels.channelId ],
        defaultParentIds: [ Enums.websiteMetrics.totalVisits.metricId, Enums.websiteMetrics.uniqueVisits.metricId ],
        metricOneColorHexCode: '#05141F',
        metricTwoColorHexCode: '#9EA1A2'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Referring Channel',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Visits
        {
          show: true,
          header: 'Website Visits',
          columnDef: 'totalVisits',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Unique Visits
        {
          show: true,
          header: 'Unique Visitors',
          columnDef: 'uniqueVisits',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Total Page Views
        {
          show: true,
          header: 'Total Page Views',
          columnDef: 'pageViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Page views per visit
        {
          show: true,
          header: 'Page Views Per Visit',
          columnDef: 'pageViewsPerVisit',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.roundToHundreth
        },
    
        // Avg Time on Site
        {
          show: true,
          header: 'Time On Site',
          columnDef: 'averageTimeOnSite',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
        },
    
        // Form Submissions
        {
          show: true,
          header: 'Form Submissions',
          columnDef: 'formSubmissions',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Form Conversion Rate
        {
          show: true,
          header: 'Form Conversion Rate',
          columnDef: 'formConversionRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    
        // Phone Calls
        {
          show: true,
          header: 'Phone Calls',
          columnDef: 'phoneCalls',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Phone Conversion Rate
        {
          show: true,
          header: 'Phone Conversion Rate',
          columnDef: 'phoneConversionRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    
        // Total Contacts
        {
          show: true,
          header: 'Total Contacts',
          columnDef: 'totalContacts',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Total Contact Rate
        {
          show: true,
          header: 'Total Contact Rate',
          columnDef: 'totalContactRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    
        // Click to Call
        {
          show: true,
          header: 'Click to Call',
          columnDef: 'clickToCalls',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Bounce Rate
        {
          show: true,
          header: 'Bounce Rate',
          columnDef: 'bounceRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    
        // New VDP
        {
          show: true,
          header: 'New Inventory Detail Views',
          columnDef: 'vdpNewViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // Used VDPs
        {
          show: true,
          header: 'Used Inventory Detail Views',
          columnDef: 'vdpUsedViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
    
        // CPO VDPs
        {
          show: true,
          header: 'CPO Inventory Detail Views',
          columnDef: 'vdpCPOViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
      ];
}
