import { Injectable } from '@angular/core';
import { FilterConfig } from '../../../../_config/filter/filter.config';
import { IFilterConfig } from '../../../models/models-index';

@Injectable()
export class FilterConfigService {

    filterConfig: IFilterConfig = new FilterConfig;

    constructor() { }

}
