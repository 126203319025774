export class ManagementTranslationConfig {
  public userManagement = {
    'en': {
      actions: 'Actions',
      caseSensitive: 'Case Sensitive',
      details: 'Details',
      edit: 'Edit',
      email: 'Email',
      firstName: 'First Name',
      lastName: 'Last Name',
      roleEntity: 'Role Entity',
      password: 'Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      currentPassword: 'Current Password',
      required: 'Required',
      save: 'Save',
      cancel: 'Cancel',
      userId: 'ID',
      search: 'Search',
      lastLogin: 'Last Login',
      lastLoginAttempt: 'Last Login Attempt',
      name: 'Name',
      newUser: 'New User',
      reportName: 'User Management',
      role: 'Role',
      selectARole: 'Select a Role',      
      selectALanguage: 'Select a Locale',
      status: 'Status',
      active: 'Active',
      inActive: 'Inactive',
      users: 'Users',
      userName: 'Username',
      passwordLengthWarning: 'Password must be at least 8 characters',
      passwordMatchWarning: 'Must match the previous entry',
      roleMissingErrorDealer: 'Role entity must match an existing DealerCode corresponding to a dealer in the database.',
      roleMissingErrorOrg1: 'Role entity must match an existing OrgCode1 corresponding to a district in the database.',
      roleMissingErrorOrg2: 'Role entity must match an existing OrgCode2 corresponding to a area in the database.',
      roleMissingErrorOrg3: 'Role entity must match an existing OrgCode3 corresponding to a region in the database.',
      roleMissingErrorOrg4: 'Role entity must match an existing OrgCode4 corresponding to a area in the database.',
      roleMissingErrorOrg5: 'Role entity must match an existing OrgCode5 corresponding to a region in the database.',
      userOrgMissingError: 'Brand must be selected for this role. ',
    },
    'fr-CA': {
      actions: 'Actions',
      caseSensitive: 'Sensible aux majuscules et minuscules',
      details: 'Détails',
      edit: 'Éditer',
      email: 'Courriel',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      roleEntity: 'Entité de rôle',
      password: 'Mot de passe',
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmez le mot de passe',
      currentPassword: 'Mot de passe',
      required: 'Obligatoire',
      save: 'Sauvegarder',
      cancel: 'Annuler',
      userId: 'Identifiant',
      search: 'Cherche',
      lastLogin: 'Dernière connexion',
      lastLoginAttempt: 'Dernière tentative de connexion',
      name: 'Nom',
      newUser: 'Nouvel utilisateur',
      reportName: 'Gestion des utilisateurs',
      role: 'Rôle',
      selectARole: 'Sélectionnez un Rôle',
      selectALanguage: 'Sélectionnez une langue',
      status: 'Statu',
      active: 'Actif',
      inActive: 'Inactif',
      users: 'Utilisateurs',
      userName: 'Nom d\'utilisateur',
      passwordLengthWarning: 'Mot de passe doit être d\'au moins 8 caractères.',
      passwordMatchWarning: 'Doit correspondre à l\'entrée précédente',
      roleMissingErrorDealer: 'L\'entité de rôle doit correspondre à un DealerCode existant correspondant à un revendeur dans la base de données.',
      roleMissingErrorOrg1: 'L\'entité de rôle doit correspondre à un OrgCode1 existant correspondant à une district de la base de données.',
      roleMissingErrorOrg2: 'L\'entité de rôle doit correspondre à un OrgCode2 existant correspondant à une area de la base de données.',
      roleMissingErrorOrg3: 'L\'entité de rôle doit correspondre à un OrgCode3 existant correspondant à une région de la base de données.',
      roleMissingErrorOrg4: 'L\'entité de rôle doit correspondre à un OrgCode4 existant correspondant à une area de la base de données.',
      roleMissingErrorOrg5: 'L\'entité de rôle doit correspondre à un OrgCode5 existant correspondant à une région de la base de données.',
      userOrgMissingError: 'Brand must be selected for this role. ',
    }
  };
}
