import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { IFilterModel } from '../../models/models-index';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class LeadTransactionService {

  private listeners = new Subject<void>();

  private filterModel: IFilterModel = {
    startDate: null,
    endDate: null,
    email: null,
    phone: null,
    lastName: null,
    dealerCode: null,
    orgCode1: null,
    orgCode3: null,
    orgCode2: null,
    orgCode4: null,
    orgCode5: null,
    sourceTypeId: null,
    orgLookupTypeId: 1,
  };

  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  constructor(private http: HttpClient) {
    // this.resetFilterModel();
  }

  getLeadTransactions(reportRequest: any, reportType: string): Observable<Models.LeadTransactionTableMetricReportResponseModel> {
    reportRequest.reportType = reportType;
    return this.http
        .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
        .pipe(
            map((response: Models.LeadTransactionTableMetricReportResponseModel) => {
                return <Models.LeadTransactionTableMetricReportResponseModel>response;
            }),
            catchError(this.handleError)
        );
  }

  getLeadTransactionByLeadId(reportRequest: any, reportType: string): Observable<Models.LeadTransactionTableMetricReportResponseModel> {
    reportRequest.reportType = reportType;
    return this.http
        .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
        .pipe(
            map((response: Models.LeadTransactionTableMetricReportResponseModel) => {
                return <Models.LeadTransactionTableMetricReportResponseModel>response;
            }),
            catchError(this.handleError)
        );
  }

  getLeadTransactionConciergeByLeadId(reportRequest: any, reportType: string): Observable<Models.LeadTransactionConciergeReportResponseModel> {
    reportRequest.reportType = reportType;
    return this.http
      .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        map((response: Models.LeadTransactionConciergeReportResponseModel) => {
          return <Models.LeadTransactionConciergeReportResponseModel>response;
        }),
        catchError(this.handleError)
      );
  }

  getTransactionDispositions(reportRequest: any, reportType: string): Observable<Models.LeadTransactionDispositionReportResponseModel> {
    reportRequest.reportType = reportType;
    return this.http
        .post(environment.baseApiUri + '/report', JSON.stringify(reportRequest),  { headers: { 'Content-Type': 'application/json' } })
        .pipe(
            map((response: Models.LeadTransactionDispositionReportResponseModel) => {
                return <Models.LeadTransactionDispositionReportResponseModel>response;
            }),
            catchError(this.handleError)
        );
  }

  setFilterModel(model: IFilterModel): void {
    this.filterModel = model;
    this.listeners.next();
  }

  getFilterModel(): IFilterModel {
    return this.filterModel;
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    return of([]);
}

}
