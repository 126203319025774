import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

    orgLookupTypes: Record<string, IOrgLookupType> = {
        'nissan': { name: 'Nissan', orgEntityType: 'national',  orgLookupTypeId: 1, default: true },
        'infiniti': { name: 'Infiniti', orgEntityType: 'national', orgLookupTypeId: 2, default: false },        
    };

    public nationalOrgLookupTypeIds: number[] = [1,2];
    public lmaOrgLookupTypeIds: number[] = [3];
    public virtual20OrgLookupTypeIds: number[] = [4];
    public dealerGroupOrgLookupTypeIds: number[] = [5];

    public showUserBrand: string[] = [       
        'System Administrator',
        'Corporate',
        'Area Manager',
        'District Manager',
        'Dealer',
    ];
   
    orgEntityType: Map<string, string> = new Map([
        ['orgcode2', 'orgcode2'],
        ['orgcode1', 'orgcode1'],
        ['dealer', 'dealer'],
    ]);

    entityTypeName: Map<string, string> = new Map([
        ['orgcode2', 'Region'],
        ['orgcode1', 'District'],
        ['dealer', 'Dealer'],
    ]);

    availableLocales?: string[] = ['fr-CA', 'en'];
}
