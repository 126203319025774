import {
  ITradeInProviderScorecardConfig,
  ITradeInProviderScorecardBillboardConfig
} from '../../../../_shared/models/models-index';


export class TradeInProviderScorecardConfig implements ITradeInProviderScorecardConfig {
  public reportTitle: string;
  public scorecardMetrics: ITradeInProviderScorecardBillboardConfig[];
}
