import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';

@Injectable()
export class DigitalAdvertisingSummaryService {

	constructor(private http: HttpClient) { }

	getDigitalAdvertisingSummaryGraphMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingSummaryGraphResponseModel> {
		return new Promise<Models.DigitalAdvertisingSummaryGraphResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingSummaryGraphResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingSummaryTableMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingSummaryTableResponseModel> {
		return new Promise<Models.DigitalAdvertisingSummaryTableResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingSummaryTableResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getDigitalAdvertisingSummaryTrendTableMetrics(reportRequest: any): Promise<Models.DigitalAdvertisingSummaryTrendTableResponseModel> {
		return new Promise<Models.DigitalAdvertisingSummaryTrendTableResponseModel>((resolve, reject) => {
			this.http.post(environment.baseApiUri + '/report', JSON.stringify(reportRequest), { headers: { 'Content-Type': 'application/json' } })
				.toPromise()
				.then((response: Models.DigitalAdvertisingSummaryTrendTableResponseModel) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
