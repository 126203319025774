<h2 mat-dialog-title>{{data.title | uppercase}}</h2>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="container" style="float:left;">
    <div class="row">
      <div class="col-12" style="padding-top: 10px; padding-bottom: 10px;">
        <div [innerHTML]="data.html">
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
  <button *ngIf="data.isConfirm" mat-button [color]="data.confirmColor" (click)="confirm()">Confirm</button>
  <button mat-raised-button (click)="closeModal()">Close</button>
</div>
