import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'month' })
export class DisplayMonthPipe implements PipeTransform {
  shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  transform(val: any): string {
    if (val === null || val === undefined || val === Infinity) {
      return 'N/A';
    } else {

      var d = new Date(val);

      return (this.shortMonthNames[d.getMonth()]);
    }
  }
}
