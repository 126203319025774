import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IFilterModel, IOrgLookup } from '../models/models-index';
import { IEnums, Enums, IDateMode } from '../enums/enums';
import { Subscription } from 'rxjs';
import { FilterService, ConfigurationService, LocaleService, SharedTranslationService } from '../services/services-index';
import { Store } from '@ngrx/store';
import { AppState } from '../../_store/app-state.model';
import { AppSelectors } from '../../_store/selector-types';
import { FilterSelectors } from '../filter/store/selector-types';
import { Filter } from '../filter/filter.model';
import { FilterDateService } from '../filter/filter-date.service';
import { FilterBarService } from '../filter/filter-bar.service';
import { switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'sd-filter-breadcrumbs',
  templateUrl: './filterBreadcrumbs.component.html'
})

export class FilterBreadcrumbsComponent implements OnInit, OnDestroy {

  @Input()
  filterModel: IFilterModel;
  subscriptions: Subscription[] = [];
  enums: IEnums = Enums;
  defaultBreadcrumbs = ['org', 'date'];
  reportFilters: Filter[] = [];
  lockedFilters: Filter[] = [];
  orgLookups: IOrgLookup[] = [];
  lmaLookups: IOrgLookup[] = [];
  virtual20Lookups: IOrgLookup[] = [];
  dealerGroupLookups: IOrgLookup[] = [];
  currentDateMode: IDateMode;
  startDate: Date;
  endDate: Date;
  salesMonthsEnabled: boolean = false;
  locale: string;

  constructor(
    private store$: Store<AppState>,
    private configService: ConfigurationService,
    private filterService: FilterService,
    private filterBarService: FilterBarService,
    private filterDateService: FilterDateService,
    private localeService: LocaleService,
    private sharedTranslationService: SharedTranslationService,
    private datePipe: DatePipe) { }

  labelTranslator = (val) => this.sharedTranslationService.getLabelTranslation(val, this.locale);

    ngOnInit() {
      this.subscriptions.push(
        this.filterService.filter$.subscribe(filter => {
          this.filterModel = filter;
        }),
        this.store$.select(AppSelectors.selectOrgLookups).subscribe(orgLookups => {
          this.orgLookups = orgLookups;
        }),
        this.store$.select(AppSelectors.selectDealerGroupLookups).subscribe(orgLookups => {
          this.dealerGroupLookups = orgLookups;
        }),
        this.store$.select(AppSelectors.selectLmaLookups).subscribe(lmaLookups => {
          this.lmaLookups = lmaLookups;
        }),
        this.filterService.filterBreadcrumbsRequested$.subscribe(() => {
          this.filterService.returnBreadcrumbString(this.getFilterBreadcrumbString());
        }),
        this.filterDateService.salesMonthsEnabled$.subscribe(salesMonthEnabled => {
          this.salesMonthsEnabled = salesMonthEnabled;
        }),
        this.localeService.locale$.subscribe(loc => this.locale = loc),
        this.filterBarService.reportName$.pipe(
          switchMap(reportName => this.filterBarService.getReportFilters(reportName))
        ).subscribe(reportFilters => this.reportFilters = reportFilters),

        this.store$.select(FilterSelectors.selectLockedFilters).subscribe(lockedFilters => {
          this.lockedFilters = lockedFilters;
        })
      );
    }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private getFilterBreadcrumbString(): string[] {
    const breadcrumbs: string[] = [];

    // all printable reports have a date and entity filter
    breadcrumbs.push(this.labelTranslator('Filters') + ':');

    breadcrumbs.push(this.displayFilterDates());
    breadcrumbs.push(`${this.labelTranslator('Entity')}: ${this.getCurrentEntityBreadcrumbString()}`);

    // get report specific filters
    const breadcrumbFilters = this.reportFilters.filter(f => !this.defaultBreadcrumbs.includes(f.type));

    const filterTypeLookup = this.configService.filter.filterConfig.filterTypeLookup;
    
    breadcrumbFilters.forEach(filter => {
      let breadcrumbCategory = '';
      const filterValuesMaxIndex = filter.selected.length - 1;
      breadcrumbCategory += `${this.labelTranslator(filterTypeLookup[filter['type']].displayName)}: `;
      filter.selected.forEach(filterValues => {
        breadcrumbCategory += this.labelTranslator(`${filterValues['display'].trim()}${filter.selected.indexOf(filterValues) < filterValuesMaxIndex ? ', ' : ''}`);
      });
      breadcrumbs.push(breadcrumbCategory);
    });
    
    return breadcrumbs;
  }

  private displayFilterDates(): string {
    // const dateFilter = this.lockedFilters.find(f => f.type === 'date')?.selected[0] ?? this.reportFilters.find(f => f.type === 'date').selected[0];
    const dateFilter = this.reportFilters.find(f => f.type === 'date').selected[0];
    const monthDateModeLabel = this.salesMonthsEnabled ? 'Sales' : 'Calendar';

    this.currentDateMode = this.filterDateService.lookupDateMode(dateFilter['dateModeId']);
    this.startDate = new Date(dateFilter['startDate']);
    this.endDate = new Date(dateFilter['endDate']);


    if (this.currentDateMode.dateModeId === this.enums.dateModes.custom.dateModeId) {
      return `Dates: ${this.formatCurrentDateRangeForDisplay()}`;
    } else {
      return `Dates: ${this.labelTranslator(monthDateModeLabel)} ${dateFilter['displayName']}`;
    }
  }

  // might be better to put this in a service
  private getCurrentEntityBreadcrumbString(): string {
    const orgFilter = this.reportFilters.find(f => f.type === 'org');

    if (!orgFilter) {
      return '';
    }
    // JMSTODO: This all needs to be better
    const selectedOrg = orgFilter.selected[0];
    const selectedEntityType = this.labelTranslator(selectedOrg['entityType']);
    const selectedEntity = selectedOrg['value'];
    const selectedEntityDisplay = this.labelTranslator(selectedOrg['display']);
    const orgLookupTypeId = selectedOrg['levelTypeId'];

    let entityBreadcrumbs = '';

    const topLevelName = this.configService.org.getOrgLookupTypeName(orgLookupTypeId);
    const topLevelDisplay = this.labelTranslator(topLevelName);

    // get hierarchy top level name
    if (this.configService.org.IsNationalOrgLookupType(orgLookupTypeId)) {
      entityBreadcrumbs += topLevelDisplay;
    } else if (this.configService.org.IsLmaOrgLookupType(orgLookupTypeId)) {
      entityBreadcrumbs += topLevelDisplay;
    } else if (this.configService.org.IsDealerGroupOrgLookupType(orgLookupTypeId)) {
      entityBreadcrumbs += topLevelDisplay;
    }

    // generate hierarchy breadcrumb
    if (this.configService.org.IsDealerGroupOrgLookupType(orgLookupTypeId)) {
      const orgLookup = this.dealerGroupLookups.find(ol => ol.orgCode1 === selectedEntity);

      entityBreadcrumbs += !!orgLookup.orgCode5 ? ` > ${orgLookup.orgCode5Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode4 ? ` > ${orgLookup.orgCode4Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode3 ? ` > ${orgLookup.orgCode3Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode2 ? ` > ${this.labelTranslator(orgLookup.orgCode2Name)}` : '';
      entityBreadcrumbs += ` > ${selectedEntityDisplay}`;
    } else if (selectedEntityType === this.enums.hierarchicalEntityTypes.orgcode5.type) {
      entityBreadcrumbs += ` > ${selectedEntityDisplay}`;
    } else if (selectedEntityType === this.enums.hierarchicalEntityTypes.orgcode4.type) {
      const orgLookup = this.orgLookups.find(ol => ol.orgCode2 === selectedEntity);

      entityBreadcrumbs += !!orgLookup.orgCode5 ? ` > ${orgLookup.orgCode5Name}` : '';
      entityBreadcrumbs += ` > ${selectedEntityDisplay}`;
    } else if (selectedEntityType === this.enums.hierarchicalEntityTypes.orgcode3.type) {
      const orgLookup = this.orgLookups.find(ol => ol.orgCode2 === selectedEntity);

      entityBreadcrumbs += !!orgLookup.orgCode5 ? ` > ${orgLookup.orgCode5Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode4 ? ` > ${orgLookup.orgCode4Name}` : '';
      entityBreadcrumbs += ` > ${selectedEntityDisplay}`;
    } else if (selectedEntityType === this.enums.hierarchicalEntityTypes.orgcode2.type) {
      const orgLookup = this.orgLookups.find(ol => ol.orgCode2 === selectedEntity);

      entityBreadcrumbs += !!orgLookup.orgCode5 ? ` > ${orgLookup.orgCode5Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode4 ? ` > ${orgLookup.orgCode4Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode3 ? ` > ${orgLookup.orgCode3Name}` : '';
      entityBreadcrumbs += ` > ${selectedEntityDisplay}`;
    } else if (selectedEntityType === this.enums.hierarchicalEntityTypes.orgcode1.type) {
      const orgLookup = this.orgLookups.find(ol => ol.orgCode1 === selectedEntity);

      entityBreadcrumbs += !!orgLookup.orgCode5 ? ` > ${orgLookup.orgCode5Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode4 ? ` > ${orgLookup.orgCode4Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode3 ? ` > ${orgLookup.orgCode3Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode2 ? ` > ${this.labelTranslator(orgLookup.orgCode2Name)}` : '';
      entityBreadcrumbs += ` > ${selectedEntityDisplay}`;
    } else if (selectedEntityType === this.enums.hierarchicalEntityTypes.lma.type) {
      // lma is top level
      entityBreadcrumbs += ` > ${selectedEntity}`;
    } else if (selectedEntityType === this.enums.hierarchicalEntityTypes.dealer.type) {
      const orgLookup = this.orgLookups.find(ol => ol.dealerCode === selectedEntity && ol.orgLookupTypeId === orgLookupTypeId);

      entityBreadcrumbs += !!orgLookup.orgCode5 ? ` > ${orgLookup.orgCode5Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode4 ? ` > ${orgLookup.orgCode4Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode3 ? ` > ${orgLookup.orgCode3Name}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode2 ? ` > ${this.labelTranslator(orgLookup.orgCode2Name)}` : '';
      entityBreadcrumbs += !!orgLookup.orgCode1 ? ` > ${orgLookup.orgCode1Name}` : '';
      entityBreadcrumbs += ` > ${selectedEntityDisplay}`;
    }

    return entityBreadcrumbs;
  }

  private formatCurrentDateRangeForDisplay(): string {
    let displayDateName = '';

    if (this.currentDateMode.dateModeId === this.enums.dateModes.quarterly.dateModeId) {
      const quarter = Math.floor(this.startDate.getMonth() / 3) + 1;
      displayDateName = `Q${quarter} ${this.startDate.getFullYear()}`;
    } else if (this.currentDateMode.dateModeId === this.enums.dateModes.custom.dateModeId) {
      const start = this.datePipe.transform(this.startDate, 'shortDate', undefined, this.locale);
      const end = this.datePipe.transform(this.endDate, 'shortDate', undefined, this.locale);

      displayDateName = start + ' - ' + end;
    } else if (this.currentDateMode.dateModeId === this.enums.dateModes.specificMonthOption.dateModeId) {
      const label = this.getMonthLabelFromDate(this.startDate);

      displayDateName = label;
    } else {
      const currentMode = this.labelTranslator(this.currentDateMode.displayName);
      const start = this.datePipe.transform(this.startDate, 'shortDate', undefined, this.locale);
      const end = this.datePipe.transform(this.endDate, 'shortDate', undefined, this.locale);

      displayDateName = `${currentMode}: ${start} - ${end}`;
    }
    return displayDateName;
  }

  getMonthLabelFromDate(date: Date) {
    const month = this.datePipe.transform(date, 'MMMM', undefined, this.locale);
    const year = this.datePipe.transform(date, 'yyyy', undefined, this.locale);

    return `${month} ${year}`;
  }
}
