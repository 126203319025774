<mat-card class="element-card">
  <mat-card-content>
    <div style="display:flex;" fxLayout="column" fxLayoutAlign="space-around center"
      style="height: 150px; overflow: hidden;">
      <div style="width: 100%; margin-top: 81px;">
        <div style="z-index: 0" [chart]="currentChart"></div>
      </div>
      <div fxLayoutAlign="center center">
        <div fxLayout="column"
          style="width: 128px; margin-top: -132px; margin-bottom: 155px; z-index: 2; font-size: 0.65rem;"
          fxLayoutAlign="center flex-start">
          <div fxLayout="row" style="width: 128px;"
            fxLayoutAlign="flex-start center" *ngFor="let item of legendData">
            <span fxLayoutAlign="flex-start center" style="white-space: nowrap;">
              <div class="legend-box" [ngStyle]="{'background-color': item.color}">
              </div>
              <div class="legend-text">
                {{item.label}}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>