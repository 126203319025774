import { ILeadTransactionsConfig, IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadTransactionsConfig implements ILeadTransactionsConfig {

    public reportTitle: 'Transactions';
    public maskSameDealerSales: boolean = false;
    public excludeFromLeadDetailsTemplate: string[] = ['customerComments'];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        // Lead Date
        {
            show: true,
            header: 'Lead Date',
            columnDef: 'created',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        // Dealer Code
        {
            show: true,
            header: 'Dealer Code',
            columnDef: 'dealerCode',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Dealer Name
        {
            show: true,
            header: 'Dealer Name',
            columnDef: 'dealerName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Lead Source
        {
            show: true,
            header: 'Lead Source',
            columnDef: 'sourceName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Customer Name
        {
            show: true,
            header: 'Customer Name',
            columnDef: 'customerName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        // City
        {
            show: true,
            header: 'City',
            columnDef: 'city',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // State
        {
            show: true,
            header: 'Province',
            columnDef: 'state',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Model
        {
            show: true,
            header: 'Model',
            columnDef: 'vehicleModel',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Live Date
        {
            show: true,
            header: 'Live Date',
            columnDef: 'liveDate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        // Responded
        {
            show: true,
            header: 'Responded',
            columnDef: 'responded',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        // Time to Respond
        {
            show: true,
            header: 'Time to Respond',
            columnDef: 'timeToRespond',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.hoursTimeStringFromMinutes,
            clickable: false
        },
        // Sale Dealer (S, O, Blank)
        {
            show: true,
            header: 'Sale Dealer',
            columnDef: 'saleDealerCode',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Sale Model
        {
            show: true,
            header: 'Sale Model',
            columnDef: 'saleModel',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Sale Type
        {
            show: true,
            header: 'Sale Type',
            columnDef: 'saleType',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
    ];
}
