
import {
    IDefaultTableColumnConfig,
    IWebsiteAfterSalesConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';

export class WebsiteAfterSalesConfig implements IWebsiteAfterSalesConfig {

    public graphConfig: IDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
