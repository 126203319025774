import {trigger, state, stagger, animate, style, query, transition, keyframes} from '@angular/animations';
export const rotate180 = trigger('rotate180', [
    state('0', style({ 'margin-top': '12px', 'margin-right': '0px', transform: 'rotate(-180deg)' })),
    state('1', style({ 'margin-top': '-4px', 'margin-right': '15px', transform: 'rotate(0deg)' })),
    transition('1 => 0', animate('300ms')),
    transition('0 => 1', animate('300ms'))
]);

export const rotate90 = trigger('rotate90', [
    state('0', style({ transform: 'rotate(90deg)', 'margin-right': '5px' })),
    state('1', style({ transform: 'rotate(0deg)' })),
    transition('1 => 0', animate('300ms')),
    transition('0 => 1', animate('300ms'))
]);