import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'trendArrow' })
export class TrendPipe implements PipeTransform {
  transform(val: any): string {
    var trendClass = '';
    if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
      return '';
    }

    //This is for metrics where an increase is good
    trendClass += (val === 1 ? 'fa-level-up trend-up' : (val === -1 ? 'fa-level-down trend-down' : 'fa-arrows-h trend-neutral'));
    return trendClass;
  }
}
