import { Injectable, Injector, ElementRef } from "@angular/core";
import { Overlay, OverlayConfig, ConnectionPositionPair, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { SdFilterPanelParams, SdFilterPanelContent } from './types';
import { BottomPosition, RightPosition, LeftPosition, SdFilterPanelInputType } from './constants';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { SdFilterPanelHostComponent } from '../components/sd-filter-panel-host.component';
import { SdFilterPanelRef } from './sd-filter-panel-ref';
import { SdFilterPanelListComponent } from '../components/sd-filter-panel-list.component';
import { SdFilterPanelCollectionComponent } from '../components/sd-filter-panel-collection.component';

@Injectable()
export class SdFilterPanel {

    constructor(private overlay: Overlay, private injector: Injector) { }

    open({
        originElement,
        content,
        inputDataType,
        config
    }: SdFilterPanelParams): SdFilterPanelRef {
        const overlayRef = this.overlay.create(this.getOverlayConfig(originElement));

        if (!content && inputDataType) {
            content = this.setDefaultContent(inputDataType);
        } else if (!content && !inputDataType) {
          content = 'Please provide content. Either a component, TemplateRef or string';
        }

        const filterPanelRef = new SdFilterPanelRef(overlayRef, config, content, inputDataType);
        const injector = this.createInjector(filterPanelRef, this.injector);

        overlayRef.attach(new ComponentPortal(SdFilterPanelHostComponent, null, injector));

        return filterPanelRef;
    }


    getOverlayConfig(originElement: ElementRef): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: this.getOverlayPosition(originElement),
            scrollStrategy: this.overlay.scrollStrategies.noop()
        });
    }


    getOverlayPosition(originElement: ElementRef): FlexibleConnectedPositionStrategy {
        const positionStrategy: FlexibleConnectedPositionStrategy =
            this.overlay.position()
                .flexibleConnectedTo(originElement)
                .withPositions(this.getPositions())
                .withPush(true);

        return positionStrategy;
    }


    getPositions(): ConnectionPositionPair[] {
        return [
          RightPosition,
          BottomPosition,
          LeftPosition
        ];
    }

    createInjector(filterPanelRef: SdFilterPanelRef, injector: Injector): Injector {
        const tokens = new WeakMap([[SdFilterPanelRef, filterPanelRef]]);

        return new PortalInjector(injector, tokens);
    }

    private setDefaultContent(inputDataType: string): any {
        switch (inputDataType) {
            case SdFilterPanelInputType.list:
                return SdFilterPanelListComponent;
            case SdFilterPanelInputType.collection:
                return SdFilterPanelCollectionComponent;
        }
    }
}
